import React, {useContext, useEffect, useState} from "react";

import "./styles.css";
import {
    NETWORK_DOWN_TOOLTIP_MSG,
    NETWORK_STATUS_TYPE,
    WEBSOCKET_CONNECTION_DOWN_TOOLTIP_MSG
} from "../../utils/constants";
import {YuJaGlobalState} from "../../utils/LoadingContext";
import {ReactComponent as NORMAL_INDICATOR} from "../../images/network_indicator_normal.svg";
import {ReactComponent as NORMAL_INDICATOR_MOBILE} from "../../images/network_indicator_normal_mobile.svg";
import {ReactComponent as NONE_INDICATOR} from "../../images/network_indicator_none.svg";
import {ReactComponent as NONE_INDICATOR_MOBILE} from "../../images/network_indicator_none_mobile.svg";
import styles from "./styles.module.css";
import {YuJaTooltip} from "../../components/standardization/YuJaTooltip";
import {ReactComponent as REFRESH} from "../../images/network_refresh_icon.svg";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function NetworkStatus({websocketState, tooltipPlacement="top", refreshAlignFirst=false}) {
    const {isOnline} = useContext(YuJaGlobalState);
    const {width} = useWindowDimensions();
    const [isTouchAble, setIsTouchAble] = useState(window.navigator.maxTouchPoints > 0);

    useEffect(() => {
        setIsTouchAble(window.navigator.maxTouchPoints > 0);
    }, [window.navigator.maxTouchPoints]);

    return (
        <>
            {isOnline && websocketState === WebSocket.OPEN  && width >= 1200 &&
                <div className={`${styles.onlineIcon} ${isTouchAble ? styles.mobileIcon : ""}`}>
                    <NORMAL_INDICATOR style={{width: 28, height: 28}}/> {isTouchAble || width < 1200 ? "" : NETWORK_STATUS_TYPE.ONLINE}
                </div>
            }

            {isOnline && websocketState === WebSocket.OPEN  && width < 1200 &&
                <NORMAL_INDICATOR_MOBILE style={{width: 24, height: 24}}/>
            }

            {(!isOnline || websocketState === WebSocket.CLOSED) &&
                <div style={{display: "flex", gap: width > 1200 ? 10 : 5, alignItems: "center", flexDirection: refreshAlignFirst ? 'row-reverse' : 'row'}}>
                    {isTouchAble &&
                        <>
                            <YuJaTooltip
                                tooltipStyle={{maxWidth: '90%'}}
                                text={!isOnline ? NETWORK_DOWN_TOOLTIP_MSG : WEBSOCKET_CONNECTION_DOWN_TOOLTIP_MSG}
                                placement={tooltipPlacement}
                                visibleInTouchableDevice={true}
                            >
                                {width >= 1200 &&
                                <div className={`${styles.offlineIcon} ${styles.mobileIcon}`}>
                                    <NONE_INDICATOR style={width > 1200 ? {width: 31.5, height: 31.5} : {width: 16.5, height: 16.5}}/>
                                </div>
                                }
                                {
                                    width < 1200 &&  <NONE_INDICATOR_MOBILE style={{width: 30, height: 30}}/>
                                }
                            </YuJaTooltip>
                            <REFRESH style={{...(width > 1200 ? {} : {width: 30, height: 30}), ...{cursor: 'pointer'}}} onClick={() => window.location.reload()}/>
                        </>
                    }

                    {!isTouchAble &&
                        <>
                             {width >= 1200 &&
                                <div className={styles.offlineIcon}>
                                    <NONE_INDICATOR style={width > 1200 ? {width: 31.5, height: 31.5} : {width: 16.5, height: 16.5}}/> {!width < 1200 ? NETWORK_STATUS_TYPE.OFFLINE : ""}
                                </div>
                            }
                            {width < 1200 &&  <NONE_INDICATOR_MOBILE style={{width: 30, height: 30}}/>}
                            <YuJaTooltip
                                tooltipStyle={{maxWidth: '90%'}}
                                text={!isOnline ? NETWORK_DOWN_TOOLTIP_MSG : WEBSOCKET_CONNECTION_DOWN_TOOLTIP_MSG}
                                placement={tooltipPlacement}
                                visibleInTouchableDevice={true}
                            >
                                <REFRESH style={{...(width > 1200 ? {width: 31.5, height: 31.5} : {width: 16.5, height: 16.5}), ...{cursor: 'pointer'}}} onClick={() => window.location.reload()}/>
                            </YuJaTooltip>
                        </>
                    }

                </div>
            }
        </>
    );
}
