import React, { useEffect, useState, useRef } from "react";
import { ReactComponent as FcMenu } from "../images/HamburgerIcon.svg";

export default function ResponsiveSidebar({
  children,
  menuClassName,
  className,
  setCollapsed,
  collapsed = false
}) {

  const [sideNavBarShow, setSideNavBarShow] = useState(false);
  const [menuButtonShow, setMenuButtonShow] = useState(true);
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setCollapsed(true);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    if (collapsed) {
      setMenuButtonShow(true);
      setSideNavBarShow(false);
    } else {
      setMenuButtonShow(false);
      setSideNavBarShow(true);
    }
  }, [collapsed]);

  return (
      <>
        <div className={className} ref={ref} style={{width: sideNavBarShow ? 240 : 0}}>
          {children}
        </div>

          <div className={menuClassName} style={{opacity: menuButtonShow ? 1 : 0}}>
            <FcMenu
                onClick={() => {
                  setCollapsed(!collapsed);
                }}
                style={{cursor: "pointer"}}
                size={30}
                tabIndex={0}
            />

          </div>

      </>
  );
}
