import styles from "./SharePollPage.module.css";
import {
    ATTEMPTS_BOX,
    ATTENDANCE_OPTIONS_MODAL_DESCRIPTION,
    AUDIENCE_RESTRICTION,
    AUDIENCE_RESTRICTION_COURSE,
    AUDIENCE_RESTRICTION_EVERYONE,
    AUDIENCE_RESTRICTION_LOGGED_IN,
    AUDIENCE_RESTRICTION_NAME,
    AUDIENCE_RESTRICTION_TITLE,
    CORRECT_ANSWER_TITLE,
    COURSE_PLACEHOLDER,
    CUSTOM_MAX_ATTEMPTS_TEXT,
    GRADED_OPTIONS_MODAL_DESCRIPTION,
    INTEGRATION_TYPES,
    LMS_ATTEMPT_TITLE,
    MAX_ATTEMPTS_TITLE, POLL_OPTIONS_MODAL_DISABLED_SAVE_TOOLTIP_TEXT, POLL_SHARE_MODE,
    POLL_TYPE,
    QUESTION_BUILDER_DISCARD_BUTTON,
    RADIAL_LABEL,
    RECORD_ATTEMPT,
    RECORD_ATTEMPT_TITLE, SAVE_SETTINGS_TEXT,
    SHOW_CORRECT_ANSWER_CUSTOM_TEXT,
    SYNC_ATTEMPT,
    UNLIMITED_MAX_ATTEMPTS_TEXT,
    USER_SETTINGS
} from "../../utils/constants";
import React, {useEffect, useState} from "react";
import {YuJaRadioBox} from "../../components/standardization/YuJaRadiobox";
import {YuJaDropdown} from "../../components/standardization/YuJaDropdown";
import {YuJaCheckbox} from "../../components/standardization/YuJaCheckbox";
import {YuJaTextBox} from "../../components/standardization/YuJaTextBox";
import {notifyError, removePrefixNew} from "../../utils/helpers";
import {useLocalStorageNew} from "../../hooks/useLocalStorageNew";
import Select from "react-select";
import YuJaButton from "../../components/standardization/YuJaButton";
import DiscardChangesConfirmationModal from "../../components/modals/DiscardChangesConfirmationModal";
import {YuJaTooltip} from "../../components/standardization/YuJaTooltip";
import loadingGif from "../../images/LoadingAnimation.gif";
import {YuJaToggle} from "../../components/standardization/YuJaToggle";

export default function ActivitySettings({
                                             pollType,
                                             originalPollOptions,
                                             pollOptions,
                                             setPollOptions,
                                             userSettings,
                                             courseData,
                                             loadingCourses,
                                             pollShareMode,
                                             handleSubmit
}){
    const [, getSession] = useLocalStorageNew("session", {});

    const [customRecordAttempt, setCustomRecordAttempt] = useState(null);
    const [customLMSAttempt, setCustomLMSAttempt] = useState(null);
    const [customMaxAttempts, setCustomMaxAttempts] = useState(null);
    const [customShowCorrectAnswer, setCustomShowCorrectAnswer] = useState(null);
    const [customAudienceRestriction, setCustomAudienceRestriction] = useState(null);
    const [courseOptions, setCourseOptions] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [isLMSUser, ] = useState(() => {
        const session = getSession();
        const {idpType="", intType=""} = session;
        return idpType && intType;
    });
    const [discardModalShow, setDiscardModalShow] = useState(false);

    const [isProgressing, setIsProgressing] = useState(false);


    useEffect(() => {
        if (!pollOptions || !userSettings) {
            return;
        }

        setCustomRecordAttempt(pollOptions.recordAttempt ? pollOptions.recordAttempt : userSettings[USER_SETTINGS.POLL_RECORD_ATTEMPTS]);
        setCustomLMSAttempt(pollOptions.lmsAttempt ? pollOptions.lmsAttempt : userSettings[USER_SETTINGS.POLL_LMS_SYNC]);
        setCustomMaxAttempts(pollOptions.maxAttempts ? pollOptions.maxAttempts : userSettings[USER_SETTINGS.POLL_MAX_ATTEMPTS]);
        setCustomShowCorrectAnswer(pollOptions.hasOwnProperty("showAnswerOnViewer") ? pollOptions.showAnswerOnViewer : userSettings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS]);
        setCustomAudienceRestriction(pollOptions?.audienceRestriction ? pollOptions.audienceRestriction : // has audience restriction
            pollOptions?.index2Pk ? AUDIENCE_RESTRICTION.REGISTERED : // has course restriction
                (pollOptions.hasOwnProperty("anonymousJoin") ? pollOptions.anonymousJoin : userSettings[USER_SETTINGS.POLL_REQUIRED_NAME]) ?
                    AUDIENCE_RESTRICTION.NAME_REQUIRED : // has name restriction
                    AUDIENCE_RESTRICTION.UNRESTRICTED // no restriction
        );

    }, [pollOptions, userSettings]);

    useEffect(() => {
        let courseId = pollOptions.hasOwnProperty("courseId") ? pollOptions.courseId :
            (originalPollOptions.hasOwnProperty("index2Pk") ? removePrefixNew(pollOptions.index2Pk) : null);

        const course = !!courseId ? courseData.find(course => course.value === courseId) : null;
        setSelectedCourse(course);
        // console.log(pollOptions?.lmsHomeUrl, [course], courseData.filter(c => !c.isLmsCourse));
        const session = getSession();
        const { intType=""} = session;
        if (intType === INTEGRATION_TYPES.BLACKBOARD) {
            setCourseOptions(courseData);
        } else {
            if (pollOptions?.lmsHomeUrl) {
                setCourseOptions([course]);
            } else {
                setCourseOptions(courseData.filter(c => !c.isLmsCourse));
            }
        }
    }, [pollOptions, courseData]);

    const handleRecordAttemptOptionClick = (value) => {
        let copy = JSON.parse(JSON.stringify(pollOptions));
        copy.recordAttempt = value;
        if (value === RECORD_ATTEMPT.RECENT.value) {
            copy.lmsAttempt = SYNC_ATTEMPT.RECENT.value;
        } else if (value === RECORD_ATTEMPT.ALL.value) {
            copy.lmsAttempt = pollOptions.lmsAttempt ? pollOptions.lmsAttempt : userSettings[USER_SETTINGS.POLL_LMS_SYNC];
        }
        setPollOptions(copy);
    };

    const handleLMSAttemptOptionClick = (value) => {
        let copy = JSON.parse(JSON.stringify(pollOptions));
        if (customRecordAttempt === RECORD_ATTEMPT.RECENT.value) {
            return;
        }
        setCustomLMSAttempt(value);
        copy.lmsAttempt = value;
        setPollOptions(copy);
    };

    const onChangeCustomMaxAttempts = (e) => {
        setCustomMaxAttempts(e.target.value)

    };

    const onClickAudienceRestriction = (value) => {
        let copy = JSON.parse(JSON.stringify(pollOptions));
        copy.audienceRestriction = value;

        if (value !== AUDIENCE_RESTRICTION.REGISTERED) {
            delete copy.courseId;
            delete copy.courseCode;
        }
        setPollOptions(copy);
    }

    const onChangeCourseSelect = (course) => {
        let copy = JSON.parse(JSON.stringify(pollOptions));
        setSelectedCourse(course);
        copy.courseId = course.value;
        copy.courseCode = course.code;
        setPollOptions(copy);
    }

    const handleDiscard = () => {
        setPollOptions(JSON.parse(JSON.stringify(originalPollOptions)));
        setDiscardModalShow(false);
    }

    const checkSameSettings = () => {
        if (pollOptions.recordAttempt !== originalPollOptions.recordAttempt) {
            return false;
        }

        if (pollOptions.lmsAttempt !== originalPollOptions.lmsAttempt) {
            return false;
        }

        if (pollOptions.maxAttempts !== originalPollOptions.maxAttempts) {
            return false;
        }

        if (pollOptions.showAnswerOnViewer !== originalPollOptions.showAnswerOnViewer) {
            return false;
        }

        if (pollOptions.audienceRestriction !== originalPollOptions.audienceRestriction) {
            return false;
        }

        let courseId = pollOptions.hasOwnProperty("courseId") ? pollOptions.courseId : null;
        let originalCourseId = originalPollOptions.hasOwnProperty("index2Pk") ? removePrefixNew(originalPollOptions.index2Pk) : null;
        if (courseId !== originalCourseId) {
            return false;
        }

        return true;
    }


    return (
        <>
            <DiscardChangesConfirmationModal
                show={discardModalShow}
                setShow={setDiscardModalShow}
                handleSubmit={handleDiscard}
            />


            <div className={styles.questionBuilderDefaultDesc} style={{fontSize: 15}}>
                {pollType === POLL_TYPE.MERGED_POLL ? GRADED_OPTIONS_MODAL_DESCRIPTION : ATTENDANCE_OPTIONS_MODAL_DESCRIPTION}
            </div>

            <div>
                <div className={styles.questionBuilderDefaultLabel} style={{marginBottom: 10}}>
                    {AUDIENCE_RESTRICTION_TITLE}
                </div>

                <div style={{display: "flex", flexDirection: "column", gap: 8}}>
                    <YuJaRadioBox
                        size={18}
                        labelStyle={{fontSize: 15, fontWeight: "400"}}
                        label={AUDIENCE_RESTRICTION_EVERYONE}
                        checked={customAudienceRestriction === AUDIENCE_RESTRICTION.UNRESTRICTED}
                        onClick={() => onClickAudienceRestriction(AUDIENCE_RESTRICTION.UNRESTRICTED)}
                        disabled={originalPollOptions?.lmsHomeUrl}
                    />
                    <YuJaRadioBox
                        size={18}
                        labelStyle={{fontSize: 15, fontWeight: "400"}}
                        label={AUDIENCE_RESTRICTION_NAME}
                        checked={customAudienceRestriction === AUDIENCE_RESTRICTION.NAME_REQUIRED}
                        onClick={() => onClickAudienceRestriction(AUDIENCE_RESTRICTION.NAME_REQUIRED)}
                        disabled={originalPollOptions?.lmsHomeUrl}
                    />
                    <YuJaRadioBox
                        size={18}
                        labelStyle={{fontSize: 15, fontWeight: "400"}}
                        label={AUDIENCE_RESTRICTION_LOGGED_IN}
                        checked={customAudienceRestriction === AUDIENCE_RESTRICTION.LOGGED_IN}
                        onClick={() => onClickAudienceRestriction(AUDIENCE_RESTRICTION.LOGGED_IN)}
                        disabled={originalPollOptions?.lmsHomeUrl}
                    />
                    {(!isLMSUser || courseOptions.length > 0 || !!pollOptions?.lmsHomeUrl) &&
                        <>
                            <YuJaRadioBox
                                size={18}
                                labelStyle={{fontSize: 15, fontWeight: "400"}}
                                label={AUDIENCE_RESTRICTION_COURSE}
                                checked={customAudienceRestriction === AUDIENCE_RESTRICTION.REGISTERED}
                                onClick={() => onClickAudienceRestriction(AUDIENCE_RESTRICTION.REGISTERED)}
                                disabled={originalPollOptions?.lmsHomeUrl}
                            />
                            {customAudienceRestriction === AUDIENCE_RESTRICTION.REGISTERED &&
                                <Select
                                    options={courseOptions}
                                    value={selectedCourse}
                                    onChange={onChangeCourseSelect}
                                    isLoading={loadingCourses}
                                    isMulti={false}
                                    isInvalid={false}
                                    placeholder={COURSE_PLACEHOLDER}
                                    isDisabled={originalPollOptions?.lmsHomeUrl}
                                    className={styles.courseSelect}
                                />
                            }
                        </>

                    }
                </div>
            </div>

            {pollType === POLL_TYPE.MERGED_POLL &&
                <>
                    <div className={styles.dividerLine}/>
                    <div>
                        <div className={styles.questionBuilderDefaultLabel} style={{marginBottom: 10}}>
                            {RECORD_ATTEMPT_TITLE}
                        </div>
                        <div style={{display: "flex", flexDirection: "column", gap: 8}}>
                            {Object.entries(RECORD_ATTEMPT).map((optionData, index) => {
                                const {value, label} = optionData[1];
                                return (
                                    <YuJaRadioBox
                                        key={index}
                                        size={18}
                                        aria-label={label + RADIAL_LABEL + (value === customRecordAttempt ? "selected" : "unselected")}
                                        label={label}
                                        checked={value === customRecordAttempt}
                                        onClick={() => handleRecordAttemptOptionClick(value)}
                                        labelStyle={{fontSize: 15, fontWeight: "400"}}
                                    />
                                );
                            })}
                        </div>
                    </div>

                    <div className={styles.dividerLine}/>
                    <div>
                        <div className={styles.questionBuilderDefaultLabel} style={{marginBottom: 10}}>
                            {LMS_ATTEMPT_TITLE}
                        </div>

                        <YuJaDropdown
                            data={Object.values(SYNC_ATTEMPT)}
                            value={customLMSAttempt}
                            onChange={option => handleLMSAttemptOptionClick(option.value)}
                            disabled={customRecordAttempt === RECORD_ATTEMPT.RECENT.value}
                            style={{padding: 8, fontSize: 15, fontWeight: "400"}}
                        />
                    </div>

                    <div className={styles.dividerLine}/>

                    <div>
                        <div className={styles.questionBuilderDefaultLabel} style={{marginBottom: 10}}>
                            {MAX_ATTEMPTS_TITLE}
                        </div>
                        <div>
                            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                <span className={styles.questionBuilderDefaultCaption} style={{margin: 0, textAlign: "start"}} >
                                    {UNLIMITED_MAX_ATTEMPTS_TEXT}
                                </span>
                                <YuJaToggle
                                    toggled={customMaxAttempts === -1}
                                    onClick={(e) => {
                                        let copy = JSON.parse(JSON.stringify(pollOptions));
                                        if (e.target.checked) {
                                            copy.maxAttempts = -1
                                        } else {
                                            copy.maxAttempts = 1
                                        }
                                        setPollOptions(copy);
                                    }}
                                />
                            </div>


                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                opacity: customMaxAttempts === -1 ? 0.5 : 1,
                                marginTop: 5,
                                justifyContent: "space-between", gap: 10
                            }}>
                                <div className={styles.questionBuilderDefaultCaption} style={{margin: 0, textAlign: "start", width: 200}}>
                                    {CUSTOM_MAX_ATTEMPTS_TEXT}
                                </div>

                                <YuJaTextBox
                                    placeholder={"1"}
                                    value={customMaxAttempts === -1 ? 1 : customMaxAttempts}
                                    onChange={onChangeCustomMaxAttempts}
                                    onBlur={(e) => {
                                        let copy = JSON.parse(JSON.stringify(pollOptions));
                                        if (e.target.value === "") {
                                            copy.maxAttempts = -1;
                                        } else {
                                            copy.maxAttempts = parseInt(e.target.value.replace(/[^0-9]/g, ''));
                                        }
                                        setPollOptions(copy);
                                    }}
                                    useValue={true}
                                    type={"number"}
                                    disabled={customMaxAttempts === -1}
                                    inputAlign={"center"}
                                    containerStyle={{width: 50, fontSize: 15, padding: "3px 5px"}}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={styles.dividerLine}/>

                    <div>
                        <div className={styles.questionBuilderDefaultLabel} style={{marginBottom: 10}}>
                            {CORRECT_ANSWER_TITLE}
                        </div>

                        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", gap: 10}}>
                            <span className={styles.questionBuilderDefaultCaption} style={{margin: 0, textAlign: "start"}} >
                                {SHOW_CORRECT_ANSWER_CUSTOM_TEXT}
                            </span>
                            <YuJaToggle
                                toggled={customShowCorrectAnswer}
                                onClick={(e) => {
                                    let copy = JSON.parse(JSON.stringify(pollOptions));
                                    copy.showAnswerOnViewer = e.target.checked;
                                    setPollOptions(copy);
                                }}
                            />
                        </div>
                    </div>
                </>
            }
            <div style={{flex: 1}}/>
            <div className={styles.panelCardsFooter}>
                <YuJaButton
                    disabled={checkSameSettings()}
                    wrapperStyle={{fontSize: 15}}
                    onClick={() => setDiscardModalShow(true)}
                    type="delete"
                >
                    {QUESTION_BUILDER_DISCARD_BUTTON}
                </YuJaButton>
                <div style={{flex: 1}}/>
                <YuJaTooltip
                    text={
                        // pollShareMode && pollShareMode === POLL_SHARE_MODE.UNKNOWN ? POLL_OPTIONS_MODAL_SAVE_TOOLTIP_TEXT :
                        POLL_OPTIONS_MODAL_DISABLED_SAVE_TOOLTIP_TEXT
                    }
                    placement="top"
                    tooltipStyle={{maxWidth: 400}}
                    prohibit={!pollShareMode || pollShareMode !== POLL_SHARE_MODE.UNKNOWN}
                >
                    <span>
                        <YuJaButton
                            wrapperStyle={{fontSize: 15}}
                            style={{width: 130}}
                            disabled={isProgressing || !pollShareMode || pollShareMode !== POLL_SHARE_MODE.UNKNOWN}
                            onClick={() => {
                                if (customAudienceRestriction === AUDIENCE_RESTRICTION.REGISTERED && !selectedCourse) {
                                    notifyError("Please select a course");
                                    return;
                                }
                                const optionsObj = {
                                    audienceRestriction: customAudienceRestriction,
                                    courseId: selectedCourse?.value ? selectedCourse.value : "",
                                    courseCode: selectedCourse?.code ? selectedCourse.code : ""
                                };

                                if (pollType === POLL_TYPE.MERGED_POLL) {
                                    optionsObj.recordAttempt = customRecordAttempt;
                                    optionsObj.lmsAttempt = customLMSAttempt;
                                    optionsObj.maxAttempts = customMaxAttempts;
                                    optionsObj.showAnswerOnViewer = customShowCorrectAnswer;
                                    // customOptions.anonymousJoin = customRequireName;
                                }
                                setIsProgressing(true);
                                handleSubmit(optionsObj, () => setIsProgressing(false));
                            }}
                        >
                            {isProgressing ? <img src={loadingGif} alt={"loading..."} style={{height: 20}}/> : SAVE_SETTINGS_TEXT}
                        </YuJaButton>
                    </span>
                </YuJaTooltip>
            </div>
        </>

    );
}