import {useEffect, useState} from "react";
import {
    ASCII_CODE_A,
    BROWSER_TYPES,
    FIXED_ANSWER,
    LMS_GRADEBOOK_SYNC, PLATFORM_TYPES,
    POLL_SHARE_MODE,
    POLL_TYPE,
    QUESTION_TYPES
} from "../utils/constants";
import {checkUnansweredNew} from "../utils/helpers";

export const useClassResultsAnalysisManager = (pollData, responses) => {

    const [poll, setPoll] = useState({});
    const [questionArr, setQuestionArr] = useState([]);
    const [userResponseArr, setUserResponseArr] = useState([]);
    const [questionResponseArr, setQuestionResponseArr] = useState([]);
    const [responsesAnalysisData, setResponsesAnalysisData] = useState([]);
    const [participantsAnalysisData, setParticipantsAnalysisData] = useState([]);
    const [participantsAllAnalysisData, setParticipantsAllAnalysisData] = useState({});
    const [browserAnalysisData, setBrowserAnalysisData] = useState({});
    const [responseCountData, setResponseCountData] = useState([]);
    const [reactionCountData, setReactionCountData] = useState([]);

    const QUESTION_RESPONSE_STATUS = {
        CORRECT: "CORRECT",
        INCORRECT: "INCORRECT",
        UNANSWERED: "UNANSWERED",
        UNGRADED_SA: "UNGRADED_SA",
    }
    const REACTION_ENUM = {
        THUMB_UP: {name: "thumbUp", value: "1"},
        THUMB_DOWN: {name: "thumbDown", value: "2"},
    }

    /*
        1. separate data
    */
    useEffect(() => {
        const pollDataCopy = JSON.parse(JSON.stringify(pollData));
        const responsesCopy = JSON.parse(JSON.stringify(responses));

        const {poll={}, questions=[]} = pollDataCopy;
        setPoll(poll);
        setQuestionArr(questions.sort((a, b) => a.serialNo - b.serialNo));

        //fetch and set actual attemptNo according to the lms attempt policy
        const userResponseArrCopy = responsesCopy.filter(r => !!r.userType).map(setActualAttemptNo).map(setActualReaction);
        setUserResponseArr(userResponseArrCopy);

        const userResponseMap = {};
        userResponseArrCopy.forEach((r) => {
            userResponseMap[getUserId(r)] = r;
        });

        //fetch and set actual attempt map according to the actual attemptNo
        setQuestionResponseArr(responsesCopy.filter(r => !r.userType).map((r) => {
            const {attempts={}} = r;
            const userId = getUserId(r);
            const actualAttemptNo = userResponseMap[userId] && userResponseMap[userId].actualAttemptNo ? userResponseMap[userId].actualAttemptNo : 1;
            r.actualAttemptNo = actualAttemptNo;
            r.actualAttempt = attempts[actualAttemptNo];
            return r;
        }));
    }, [pollData, responses]);


    /*
        2. calculate chart data
    */
    useEffect(() => {
        const {pollType, pollShareMode} = poll;
        const responsesAnalysisDataTemp = [];
        const participantsAnalysisDataTemp = [];
        for (const {serialNo} of questionArr) {
            responsesAnalysisDataTemp.push(getResponsesAnalysis(serialNo));
            //only share-each need to calculate participants of each question
            if (pollType !== POLL_TYPE.ATTENDANCE) {
                participantsAnalysisDataTemp.push(getParticipantsAnalysis(serialNo));
            }
        }
        setResponsesAnalysisData(responsesAnalysisDataTemp);
        setParticipantsAnalysisData(participantsAnalysisDataTemp);

        //Attendance doesn't require participants analytic data ---- whether absent or present
        if (pollType !== POLL_TYPE.ATTENDANCE
            && [POLL_SHARE_MODE.SHARE_ALL, POLL_SHARE_MODE.SCHEDULE].includes(pollShareMode)
        ) {
            setParticipantsAllAnalysisData(getParticipantsAllAnalysis());
        }

        setBrowserAnalysisData(getBrowserAnalysisData());

        setResponseCountData(getResponseCountData());
        const temp = getReactionCount();
        setReactionCountData(temp);

    }, [poll, questionArr, userResponseArr, questionResponseArr]);

    /*
       set actual attempt no and reaction for user response
   */
    const setActualAttemptNo = (userResponse={}) => {
        let attemptNo = 1;
        const {lmsAttempt=LMS_GRADEBOOK_SYNC.RECENT.value, pollShareMode, pollType} = poll;

        //Attendance
        if (pollType === POLL_TYPE.ATTENDANCE) {
            userResponse.actualAttemptNo = attemptNo;
            return userResponse;
        }

        //share-each
        if (![POLL_SHARE_MODE.SHARE_ALL, POLL_SHARE_MODE.SCHEDULE].includes(pollShareMode)) {
            userResponse.actualAttemptNo = attemptNo;
            return userResponse;
        }

        //share-all/scheduled

        //share-all Survey take the RECENT policy coz survey doesn't have score
        const {attempts, attemptsCount} = userResponse;
        if (!attempts || !lmsAttempt) {
            userResponse.actualAttemptNo = attemptNo;
            return userResponse;
        }

        if (lmsAttempt === LMS_GRADEBOOK_SYNC.RECENT.value) {
            attemptNo = attemptsCount;
        } else if (lmsAttempt === LMS_GRADEBOOK_SYNC.HIGHEST.value) {
            let maxScore = -Infinity;
            for (const no in attempts) {
                const score = parseFloat(attempts[no].score);
                if (score > maxScore) {
                    maxScore = score;
                    attemptNo = no;
                }
            }
        }

        userResponse.actualAttemptNo = attemptNo;
        return userResponse;
    };


    /*
      set actual attempt no and reaction for user response
      data format of share all reaction map:
        {
            "attemptNo": {
                "serialNo": [
                    "1"
                ], ...
            }, ...
        }

        set actual attempt no and reaction for user response
      data format of share each reaction map:
        {
            "serialNo": {
                "shareCount": [
                    "1"
                ], ...
            }, ...
        }
    */
    const setActualReaction = (userResponse={}) => {
        const {pollShareMode, pollType} = poll;

        //Attendance doesn't have reaction feature
        if (pollType === POLL_TYPE.ATTENDANCE) {
            return userResponse;
        }

        const {reactionMap={}, actualAttemptNo} = userResponse;

        //share-each ------ get recent reaction
        if (![POLL_SHARE_MODE.SHARE_ALL, POLL_SHARE_MODE.SCHEDULE].includes(pollShareMode)) {
            if (!!reactionMap && Object.entries(reactionMap).length > 0) {
                const serialNoArr = Object.keys(reactionMap);
                let actualReaction = [];
                for (const serialNo of serialNoArr) {
                    const reactionRoundArr = Object.values(reactionMap[serialNo]);
                    actualReaction[serialNo] = reactionRoundArr[reactionRoundArr.length - 1];
                }
                userResponse.actualReaction = actualReaction;
            }
            return userResponse;
        }

        //share-all/scheduled
        //share-all Survey take the RECENT policy
        if (!!reactionMap && Object.entries(reactionMap).length > 0) {
            if (reactionMap.hasOwnProperty(actualAttemptNo)) {
                userResponse.actualReaction = reactionMap[actualAttemptNo];
            }
        }
        return userResponse;
    };


    const getUserId = (response) => {
        const {pollSortKey} = response;
        const regex = /#([^#]+)#([^#]+)#([^#]+)$/;
        const match = pollSortKey.match(regex);

        if (match) {
            return match[3];
        } else {
            return null;
        }
    };


    const checkResponseStatus = (curQuestion, questionResponse) => {
        const {pollType} = poll;
        const {actualAttempt={}} = questionResponse;
        const {isGraded, isCorrect, selectedOption} = actualAttempt;

        if (curQuestion.questionType === QUESTION_TYPES.SA.name) {
            const {locationData} = actualAttempt;
            if (pollType === POLL_TYPE.ATTENDANCE) {
                if (!!isCorrect && (!locationData || !!locationData.inGeofence)) {
                    return QUESTION_RESPONSE_STATUS.CORRECT;
                } else {
                    return QUESTION_RESPONSE_STATUS.INCORRECT;
                }
            }

            if (!Object.entries(actualAttempt).length) {
                return QUESTION_RESPONSE_STATUS.UNANSWERED;
            }

            if (!isGraded) {
                if (checkUnansweredNew(!!curQuestion.weightage, curQuestion.questionType, selectedOption)) {
                    return QUESTION_RESPONSE_STATUS.UNANSWERED;
                } else {
                    return QUESTION_RESPONSE_STATUS.UNGRADED_SA;
                }

            } else {
                if (!!isCorrect) {
                    return QUESTION_RESPONSE_STATUS.CORRECT;
                } else {
                    return QUESTION_RESPONSE_STATUS.INCORRECT;
                }
            }
        } else {
            if (!Object.entries(actualAttempt).length) {
                return QUESTION_RESPONSE_STATUS.UNANSWERED;
            }

            if (checkUnansweredNew(!!curQuestion.weightage, curQuestion.questionType, selectedOption)) {
                return QUESTION_RESPONSE_STATUS.UNANSWERED;
            } else if (!!isCorrect) {
                return QUESTION_RESPONSE_STATUS.CORRECT;
            } else {
                return QUESTION_RESPONSE_STATUS.INCORRECT;
            }
        }
    }

    /*
       calculate response data
       ungraded doesn't require this calculation
       return eg: {total: x, correct: x, incorrect: x, unanswered: x, ungraded: x}
   */
    const getResponsesAnalysis = (curSerialNo) => {
        const result = {};
        const curQuestion = questionArr.find(q => q.serialNo === curSerialNo);
        let correctCount = 0;
        let incorrectCount = 0;
        let unansweredCount = 0;
        let ungradedCount = 0;
        for (const questionResponse of questionResponseArr.filter(r => r.serialNo === curSerialNo)) {
            const status = checkResponseStatus(curQuestion, questionResponse);
            if (status === QUESTION_RESPONSE_STATUS.CORRECT) {
                correctCount += 1;
            } else if (status === QUESTION_RESPONSE_STATUS.INCORRECT) {
                incorrectCount += 1;
            } else if (status === QUESTION_RESPONSE_STATUS.UNGRADED_SA) {
                ungradedCount += 1;
            } else {
                unansweredCount += 1;
            }
        }
        result.total = correctCount + incorrectCount + ungradedCount + unansweredCount;
        result.correct = correctCount;
        result.incorrect= incorrectCount;
        result.unanswered = unansweredCount;
        result.ungraded = ungradedCount;
        return result;
    };


    /*
       calculate participants data for share-each
       return eg: {total: x, attempted: x, unattempted: x, engagement: "60%"}
   */
    const getParticipantsAnalysis = (curSerialNo) => {
        const result = {};

        // const curQuestion = questionArr.find(q => q.serialNo === curSerialNo);
        let total = 0;
        let incompleteCount = 0;
        let finishedCount = 0;
        for (const questionResponse of questionResponseArr.filter(r => r.serialNo === curSerialNo)) {
            // const status = checkResponseStatus(curQuestion, questionResponse);
            const {actualAttempt={}} = questionResponse;
            if (!!actualAttempt.isPlaceholder) {
                incompleteCount += 1;
            } else {
                finishedCount += 1;
            }
        }
        total = finishedCount + incompleteCount;
        result.total = total;
        result.attempted = finishedCount;
        result.unattempted = incompleteCount;
        result.enagement = total === 0 ? '0.0%' : `${(finishedCount * 100 / total).toFixed(1)}%`;
        return result;
    }


    /*
        calculate participants data for share-all and schedule
        return eg: {total: x, attempted: x, unattempted: x, engagement: "60%"}
    */
    const getParticipantsAllAnalysis = () => {
        const result = {};

        const groupedByUserId = questionResponseArr.reduce((result, r) => {
            (result[getUserId(r)] || (result[getUserId(r)] = [])).push(r);
            return result;
        }, {});

        let incompleteCount = 0;
        let finishedCount = 0;
        // const engagementScoreArr = [];
        //loop viewer
        for (const userId in groupedByUserId) {
            // let attemptedCount = 0;
            // let unattemptedCount = 0;
            // let isCompleted = true;
            const userResponse = userResponseArr.find(r => getUserId(r) === userId);
            if (!userResponse) {
                continue;
            }


            const {actualAttemptNo=1, attempts={}} = userResponse;
            const attemptObj = attempts.hasOwnProperty(actualAttemptNo.toString())
                ? attempts[actualAttemptNo.toString()]
                : {};
            const {curQuestionNo=1} = attemptObj;

            if (curQuestionNo > questionArr.length) {
                finishedCount += 1;
            } else {
                incompleteCount += 1;
            }
            // engagementScoreArr.push((attemptedCount * 100 / (questionArr.length)).toFixed(1));
        }


        result.total = finishedCount + incompleteCount;
        result.attempted = finishedCount;
        result.unattempted = incompleteCount;

        // const sumScore = engagementScoreArr.reduce((acc, num) => acc + parseFloat(num), 0);
        // result.enagement = engagementScoreArr.length === 0 ? '0.0%' : `${(sumScore / engagementScoreArr.length).toFixed(1)}%`;
        result.enagement = result.total === 0 ? '0.0%' : `${(finishedCount * 100 / result.total).toFixed(1)}%`;
        return result;
    }


    const getBrowserAnalysisData = () => {
        let chromeCount = 0;
        let firefoxCount = 0;
        let edgeCount = 0;
        let safariCount = 0;
        let mobileAppCount = 0;
        let otherMobileCount = 0;
        let appleCount = 0;
        let androidCount = 0;
        let windowsCount = 0;
        let otherPlatformCount = 0;

        for (const userResponse of userResponseArr) {
            let {browserType = "", platform = ""} = userResponse;

            if (browserType.includes(BROWSER_TYPES.Chrome)) {
                chromeCount += 1;
            } else if (browserType.includes(BROWSER_TYPES.Firefox)) {
                firefoxCount += 1;
            } else if (browserType.includes(BROWSER_TYPES.Edge)) {
                edgeCount += 1;
            } else if (browserType.includes(BROWSER_TYPES.Safari)) {
                safariCount += 1;
            } else if (browserType.includes(BROWSER_TYPES.MobileApp)) {
                mobileAppCount += 1;
            } else {
                otherMobileCount += 1;
            }

            if (platform.toUpperCase().includes(PLATFORM_TYPES.iPhone.toUpperCase())
                || platform.includes(PLATFORM_TYPES.Mac)
                || platform.includes(PLATFORM_TYPES.iPad)
            ) {
                appleCount += 1;
            } else if (platform.includes(PLATFORM_TYPES.Windows)) {
                windowsCount += 1;
            } else if (platform.toUpperCase().includes(PLATFORM_TYPES.Android.toUpperCase())) {
                androidCount += 1;
            } else {
                otherPlatformCount += 1;
            }
        }

        return {
            "chrome": chromeCount,
            "firefox": firefoxCount,
            "edge": edgeCount,
            "safari": safariCount,
            "mobileApp": mobileAppCount,
            "otherBrowser": otherMobileCount,
            "apple": appleCount,
            "android": androidCount,
            "windows": windowsCount,
            "otherPlatform": otherPlatformCount,
            "total": userResponseArr.length
        };
    }


    const getResponseCountData = () => {
        let result = [];
        for (const question of questionArr) {
            result.push(convertQuestionResult(question));
        }
        return result;
    }


    const convertQuestionResult = (question) => {
        let questionResult = {};
        const {questionType, serialNo, optionsMap, correctAnswers=[], weightage=0} = question;
        if (questionType === QUESTION_TYPES.MCSS.name) {//eg: [{key: "a", indexDisplay: "A", ans: "xxx", people: 0}, ...]
            questionResult = Object.entries(optionsMap)
                .map(([key, value], index) => ({
                    key,
                    indexDisplay: String.fromCharCode(ASCII_CODE_A + index),
                    ans: value,
                    people: 0,
                    isCorrect: correctAnswers.includes(key)
                }));

            for (const questionResponse of questionResponseArr.filter(r => r.serialNo === serialNo)) {
                const {actualAttempt} = questionResponse
                if (checkResponseStatus(question, questionResponse) === QUESTION_RESPONSE_STATUS.UNANSWERED) {
                    continue;
                }

                const {selectedOption}  = actualAttempt;
                if (Array.isArray(selectedOption)) {
                    questionResult.forEach(r => {
                        if (selectedOption.includes(r.key)) {
                            r.people += 1;
                        }
                    });
                } else {
                    questionResult.find(r => r.key === selectedOption).people += 1;
                }
            }
        } else if (questionType === QUESTION_TYPES.TF.name) {//eg: [{key: "Ture",  ans: "Ture", people: 0}, {key: "False",  ans: "False", people: 0}]
            questionResult = optionsMap.map(value => ({
                key: value,
                ans: value,
                people: 0,
                isCorrect: correctAnswers === value
            }));

            for (const questionResponse of questionResponseArr.filter(r => r.serialNo === serialNo)) {
                const {actualAttempt} = questionResponse
                if (checkResponseStatus(question, questionResponse) === QUESTION_RESPONSE_STATUS.UNANSWERED) {
                    continue;
                }

                const {selectedOption}  = actualAttempt;
                questionResult.find(r => r.key === selectedOption).people += 1;
            }
        } else if (questionType === QUESTION_TYPES.CI.name) {//eg: [{key: "0", indexDisplay: "A", ans: "xxx", people: 0}, ...]
            questionResult = optionsMap.map(({text}, index) => ({
                key: `${index}`,
                indexDisplay: String.fromCharCode(ASCII_CODE_A + index),
                ans: text,
                people: 0,
                isCorrect: correctAnswers.includes(index)
            }));

            for (const questionResponse of questionResponseArr.filter(r => r.serialNo === serialNo)) {
                const {actualAttempt} = questionResponse
                if (checkResponseStatus(question, questionResponse) === QUESTION_RESPONSE_STATUS.UNANSWERED) {
                    continue;
                }

                const {selectedOption={}} = actualAttempt;
                const {selected=""} = selectedOption;
                if (selected === FIXED_ANSWER.OTHER) {
                    if (!questionResult.find(r => r.key === "-1")) {
                        questionResult.push({key: "-1", indexDisplay: "", ans: "Other", people: 0});
                    }
                    questionResult.find(r => r.key === "-1").people += 1;
                } else {
                    questionResult.find(r => r.key === selected).people += 1;
                }
            }

        } else if (questionType === QUESTION_TYPES.SA.name) {//eg: ["xxx", "yyyy",...]
            questionResult = [];

            for (
                const questionResponse of
                questionResponseArr
                    .filter(r => r.serialNo === serialNo)
                    .sort((a, b) => Date.parse(b.updatedTimeSK) - Date.parse(a.updatedTimeSK))
            ) {
                const {actualAttempt} = questionResponse
                if (checkResponseStatus(question, questionResponse) === QUESTION_RESPONSE_STATUS.UNANSWERED) {
                    continue;
                }
                const {selectedOption = {}} = actualAttempt;
                if (!!selectedOption["0"]) {
                    questionResult.push(selectedOption["0"]);
                }
            }
        } else if (questionType === QUESTION_TYPES.WC.name) {
            questionResult = [];

            for (
                const questionResponse of
                questionResponseArr
                    .filter(r => r.serialNo === serialNo)
                    .sort((a, b) => Date.parse(b.updatedTimeSK) - Date.parse(a.updatedTimeSK))
            ) {
                const {actualAttempt} = questionResponse
                if (checkResponseStatus(question, questionResponse) === QUESTION_RESPONSE_STATUS.UNANSWERED) {
                    continue;
                }
                const {selectedOption = {}} = actualAttempt;
                Object.keys(selectedOption).forEach(key => {
                    if (!!selectedOption[key]) {
                        questionResult.push(selectedOption[key]);
                    }
                });
            }
        } else if (questionType  === QUESTION_TYPES.OE.name) {//eg: ["xxx", "yyyy",...]
            questionResult = [];
            const filteredAndSortedResponses = questionResponseArr
                .filter(r => r.serialNo === serialNo)
                .sort((a, b) => Date.parse(b.updatedTimeSK) - Date.parse(a.updatedTimeSK));

            for (const questionResponse of filteredAndSortedResponses) {
                const {actualAttempt, openEndedCount } = questionResponse
                if (checkResponseStatus(question, questionResponse) === QUESTION_RESPONSE_STATUS.UNANSWERED) {
                    continue;
                }
                const {selectedOption = {}} = actualAttempt;           
                if (!!selectedOption["0"] && selectedOption["0"] !== "unanswered") {
                    questionResult.push({selectedOption : selectedOption["0"], openEndedCount : !openEndedCount ? {1: 0, 2 : 0} : openEndedCount});
                }
            }
        }  else if (questionType === QUESTION_TYPES.FITB.name) {//eg: [["xxx", "yyyy", "zzz"],...]
            questionResult = [];
            for (
                const questionResponse of
                questionResponseArr
                    .filter(r => r.serialNo === serialNo)
                    .sort((a, b) => Date.parse(b.updatedTimeSK) - Date.parse(a.updatedTimeSK))
            ) {
                const {actualAttempt} = questionResponse
                if (checkResponseStatus(question, questionResponse) === QUESTION_RESPONSE_STATUS.UNANSWERED) {
                    continue;
                }
                const {selectedOption={}} = actualAttempt;
                if (!!selectedOption && !!Object.entries(selectedOption).length) {
                    questionResult.push(Object.values(selectedOption));
                }
            }
        } else if (questionType === QUESTION_TYPES.RK.name) {//eg: {option1: 3, option2: 2, option3: 1}
            questionResult = {};
            for (const questionResponse of questionResponseArr.filter(r => r.serialNo === serialNo)) {
                const {actualAttempt} = questionResponse
                if (checkResponseStatus(question, questionResponse) === QUESTION_RESPONSE_STATUS.UNANSWERED) {
                    continue;
                }

                const {selectedOption=[]}  = actualAttempt;

                for (let i = 0; i < selectedOption.length; i++) {
                    const score = selectedOption.length - i;
                    const key = selectedOption[i];
                    if (!questionResult.hasOwnProperty(key)) {
                        questionResult[key] = score;
                    } else {
                        questionResult[key] += score;
                    }

                }
            }

            return questionResult;
        } else {
            return {};
        }

        return questionResult;

    }



    const getReactionCount = () => {
        let result = [];
        let countMap = {};
        for (const userResponse of userResponseArr) {
            const {actualReaction={}, } = userResponse;
            for (const key in actualReaction) {
                const questionReaction = actualReaction[key];
                if (!countMap.hasOwnProperty(key)) {
                    countMap[key] = {[REACTION_ENUM.THUMB_UP.name]: 0, [REACTION_ENUM.THUMB_DOWN.name]: 0};
                }
                if (questionReaction.includes(REACTION_ENUM.THUMB_UP.value)) {
                    countMap[key][REACTION_ENUM.THUMB_UP.name] += 1;
                } else if(questionReaction.includes(REACTION_ENUM.THUMB_DOWN.value)) {
                    countMap[key][REACTION_ENUM.THUMB_DOWN.name] += 1;
                }

            }
        }

        for (const question of questionArr) {
            const {serialNo} = question;
            let questionResult = countMap.hasOwnProperty(serialNo)
                ? countMap[serialNo]
                : {[REACTION_ENUM.THUMB_UP.name]: 0, [REACTION_ENUM.THUMB_DOWN.name]: 0};
            result.push(questionResult);
        }

        return result;
    }

    return {
        responsesAnalysisData,
        participantsAnalysisData,
        participantsAllAnalysisData,
        browserAnalysisData,
        responseCountData,
        reactionCountData,
    };
}


export const getPrefillQuestionResult = (question) => {
    let questionResult = {};
    const {questionType, optionsMap, correctAnswers=[]} = question;
    if (questionType === QUESTION_TYPES.MCSS.name) {//eg: [{key: "a", indexDisplay: "A", ans: "xxx", people: 0}, ...]
        questionResult = Object.entries(optionsMap)
            .map(([key, value], index) => ({
                key,
                indexDisplay: String.fromCharCode(ASCII_CODE_A + index),
                ans: value,
                people: 0,
                isCorrect: correctAnswers.includes(key)
            }));


    } else if (questionType === QUESTION_TYPES.TF.name) {//eg: [{key: "Ture",  ans: "Ture", people: 0}, {key: "False",  ans: "False", people: 0}]
        questionResult = optionsMap.map(value => ({
            key: value,
            ans: value,
            people: 0,
            isCorrect: correctAnswers === value
        }));
    } else if (questionType === QUESTION_TYPES.CI.name) {//eg: [{key: "0", indexDisplay: "A", ans: "xxx", people: 0}, ...]
        questionResult = optionsMap.map(({text}, index) => ({
            key: `${index}`,
            indexDisplay: String.fromCharCode(ASCII_CODE_A + index),
            ans: text,
            people: 0,
            isCorrect: correctAnswers.includes(index)
        }));
    } else {
        return {};
    }

    return questionResult;

}