import { useEffect, useRef, useState } from "react";
import { UNLIMITED_TIME_INPUT_DESCRIPTION } from "../utils/constants";
import { notifyError } from "../utils/helpers";
import {
    CUSTOM_TIME_LIMIT_MAX_ERROR,
    CUSTOM_TIME_LIMIT_MIN_ERROR,
} from "../utils/toast-message-constants";
import styles from "./TimeLimitInputBox.module.css";
import { YuJaTooltip } from "./standardization/YuJaTooltip";


export default function TimeLimitInputBox({
                                              containerStyle={},
                                              onBlur,
                                              tooltipText=UNLIMITED_TIME_INPUT_DESCRIPTION,
                                              tooltipStyle={},
                                              ...props
                                          }) {
    const [temporaryVal, setTemporaryVal] = useState("");

    const toHHMMSS = (secs) => {
        const secNum = parseInt(secs.toString(), 10);
        const hours = Math.floor(secNum / 3600);
        const minutes = Math.floor(secNum / 60) % 60;
        const seconds = secNum % 60;

        return [hours, minutes, seconds]
            .map((val) => (val < 10 ? `0${val}` : val))
            .filter((val, index) => val !== "00" || index > 0)
            .join(":")
    };


    const getSecondsFromHHMMSS = (value) => {
        let str = value;
        let str1 = str.substring(str.lastIndexOf(":") + 1, str.length)
        str = str.substring(0, str.lastIndexOf(":"));
        let str2 = str.substring(str.lastIndexOf(":") + 1, str.length)
        str = str.substring(0, str.lastIndexOf(":"));
        let str3 = str.substring(str.lastIndexOf(":") + 1, str.length)

        const val1 = Number(str1);
        const val2 = Number(str2);
        const val3 = Number(str3);

        const seconds = val1 + val2 * 60 + val3 * 60 * 60;

        if(seconds >= 3600) {
            notifyError(CUSTOM_TIME_LIMIT_MAX_ERROR);
            return 0;
        }

        return seconds;
    };


    const initializeInput = (value) => {
        if (isNaN(value)) {
            value = props.value;
        }
        if (!!value && !!parseInt(value)) {
            const text = toHHMMSS(parseInt(value));
            setTemporaryVal(text);
        } else {
            setTemporaryVal("");
        }
    }

    useEffect(() => {
        initializeInput();
    }, [props.value]);

    const actualInputRef = useRef(null);

    const onClick = (e) => {
        e.stopPropagation();
        actualInputRef.current.focus();
    }

    const onChangeFunc = (e) => {
        e.stopPropagation();
        setTemporaryVal(e.target.value);
    }

    const onBlurFunc = () => {
        const seconds = Math.max(0, getSecondsFromHHMMSS(temporaryVal));
        if (!!onBlur) {
            if (isNaN(seconds)) { // untimed
                initializeInput();
            } else if (seconds !== 0 && seconds < 10) { // 1 - 9 seconds
                notifyError(CUSTOM_TIME_LIMIT_MIN_ERROR);
                initializeInput();
            } else { // timed and valid
                onBlur(seconds);
                initializeInput(seconds);
            }
        }
    }

    return (
        <>
            {!!tooltipText &&
                <YuJaTooltip
                    placement={"top"}
                    text={tooltipText}
                    tooltipStyle={tooltipStyle}
                >
                    <div
                        className={`${styles.box} ${props.disabled ? styles.disabled : ""}`}
                        style={{...containerStyle}}
                        onClick={onClick}
                        tabIndex={-1}
                    >
                        <input
                            {...props}
                            ref={actualInputRef}
                            value={temporaryVal}
                            onChange={onChangeFunc}
                            onBlur={onBlurFunc}
                            disabled={props.disabled}
                            placeholder={"00:00"}
                            aria-label={"textbox"}

                        />
                    </div>
                </YuJaTooltip>
            }
            {!tooltipText &&
                <div
                    className={`${styles.box} ${props.disabled ? styles.disabled : ""}`}
                    style={{...containerStyle}}
                    onClick={onClick}
                    tabIndex={-1}
                >
                    <input
                        {...props}
                        ref={actualInputRef}
                        value={temporaryVal}
                        onChange={onChangeFunc}
                        onBlur={onBlurFunc}
                        disabled={props.disabled}
                        placeholder={"00:00"}
                        aria-label={"textbox"}

                    />
                </div>
            }

        </>

    );
}