import { EditorState, Modifier, SelectionState } from "draft-js";
import {
  BLANK_REGEX_NEW,
  DEFAULT_ENTRIES_PER_PARTICIPANT, DEFAULT_POINTS,
  DEFAULT_TIME_LIMIT,
  QUESTION_TYPES,
  USER_SETTINGS
} from "./constants";
import { initialQuestionData, questionNameToCode } from "./questionUtils";
import {stripHTMLTags} from "./helpers";

export const updateAnswers = (input, itemKey, indexToChange, questions) => {
    // Make a shallow copy of the items
    let copyOfQuestions = [...questions];
    // Make a shallow copy of the item you want to mutate
    let newOptionsMap = { ...copyOfQuestions[indexToChange].optionsMap };
    // Replace the property you're intested in
    newOptionsMap[itemKey] = input;
    // Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    copyOfQuestions[indexToChange].optionsMap = newOptionsMap;
    // Return our new copy
    return copyOfQuestions;
  };
  
  export const updateRankingAnswers = (input, itemKey, indexToChange, questions) => {
    // Make a shallow copy of the items
    let copyOfQuestions = [...questions];
    // Make a shallow copy of the item you want to mutate
    let newOptionsMap = [ ...copyOfQuestions[indexToChange].optionsMap ];
    // Replace the property you're intested in
    newOptionsMap[itemKey] = input;
    // Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    copyOfQuestions[indexToChange].optionsMap = newOptionsMap;
    // Return our new copy
    return copyOfQuestions;
  };
  

  const getCorrectAnswers = (questionType, input, originalInput) => {
    switch (questionType) {
      case QUESTION_TYPES.MCSS.name:
        if (typeof input !== "string") {
          throw new Error(
            "Input type is wrong, should be string however received: " +
              typeof input
          );
        }
        if (originalInput.includes(input)) {
          return originalInput.filter(answer => answer !== input);
        } else {
          return [...originalInput, input];
        }
      case QUESTION_TYPES.TF.name:
        if (typeof input !== "string") {
          throw new Error(
            "Input type is wrong, should be string however received: " +
              typeof input
          );
        }
        return input;
      case QUESTION_TYPES.FITB.name:
        if (typeof input !== "object") {
          throw new Error(
            "Input type is wrong, should be object however received: " +
              typeof input
          );
        }
        return input;
      case QUESTION_TYPES.SA.name:
        if (!Array.isArray(input)) {
          throw new Error("Input type is wrong, should be array.");
        }
        return input;
      case QUESTION_TYPES.CI.name:
        if (typeof input !== "number") {
          throw new Error(
              "Input type is wrong, should be string however received: " +
              typeof input
          );
        }
        if (originalInput.includes(input)) {
          return originalInput.filter(answer => answer !== input);
        } else {
          return [...originalInput, input];
        }
      default:
        throw new Error(
          "Unhandled question type detected, question type code: " + questionType
        );
    }
  };

export const updateCorrectAnswers = (input, indexToChange, questions) => {
    // Make a shallow copy of the items
    let copyOfQuestions = [...questions];
    // Make a shallow copy of the item you want to mutate
    let item = { ...copyOfQuestions[indexToChange] };
    // Replace the property you're intested in
    let originalInput = item.correctAnswers;
    item.correctAnswers = getCorrectAnswers(item.questionType, input, originalInput);
    // Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    copyOfQuestions[indexToChange] = item;
    // Return our new copy
    return copyOfQuestions;
  };
  
  export const updateTitleInput = (input, indexToChange, questions) => {
    let copyOfQuestions = [...questions];
    let newQuestion = { ...copyOfQuestions[indexToChange] };
    newQuestion["queTitle"] = input;
    copyOfQuestions[indexToChange] = newQuestion;
    return copyOfQuestions;
  };

  export const updateWeightage = (weightage, indexToChange, questions) => {
    let copyOfQuestions = [...questions];
    let newQuestion = { ...copyOfQuestions[indexToChange] };
    newQuestion.weightage = weightage
    copyOfQuestions[indexToChange] = newQuestion;
    return copyOfQuestions;
  };
  
  export const updateEntries = (entries, indexToChange, questions) => {
    let copyOfQuestions = [...questions];
    let newQuestion = { ...copyOfQuestions[indexToChange] };
    newQuestion.entries = entries;
    copyOfQuestions[indexToChange] = newQuestion;
    return copyOfQuestions;
  };

  export const updateTimeLimit = (timeLimit, indexToChange, questions) => {
    let copyOfQuestions = [...questions];
    let newQuestion = { ...copyOfQuestions[indexToChange] };
    newQuestion.timeLimit = timeLimit
    copyOfQuestions[indexToChange] = newQuestion;
    return copyOfQuestions;
  };

  export const updateGetReadyTimeLimit = (grtimeLimit, indexToChange, questions) => {
    let copyOfQuestions = [...questions];
    let newQuestion = { ...copyOfQuestions[indexToChange] };
    newQuestion["getReadyTimeLimit"] = grtimeLimit;
    copyOfQuestions[indexToChange] = newQuestion;
    return copyOfQuestions;
  };
  
  export const updateQuestionType = (qType, indexToChange, questions, oldQType, userSettings={}, questionBuilder=false) => {
    let copyOfQuestions = JSON.parse(JSON.stringify(questions));
    let newQuestion = copyOfQuestions[indexToChange];
    const qTypeCode = questionNameToCode(qType);
    newQuestion.questionType = qTypeCode;
    if (!!copyOfQuestions[indexToChange].queTitle && copyOfQuestions[indexToChange].queTitle !== "" && oldQType !== QUESTION_TYPES.FITB.name ) {
      newQuestion.queTitle = stripHTMLTags(copyOfQuestions[indexToChange].queTitle);
    } else {
      newQuestion.queTitle = "";
    }
    if (qTypeCode === QUESTION_TYPES.FITB.name) {
      newQuestion.hasBlankRestriction = qTypeCode === QUESTION_TYPES.FITB.name;
      if (questionBuilder) {
        newQuestion.queTitle = "";
      }
    }
    const { optionsMap, correctAnswers } = initialQuestionData(qTypeCode, userSettings, questionBuilder);
    copyOfQuestions[indexToChange] = newQuestion;
    let newCorrectAns;
    const blankRegex = BLANK_REGEX_NEW;
    if(copyOfQuestions[indexToChange].queTitle !== "" && copyOfQuestions[indexToChange].queTitle.match(blankRegex) && QUESTION_TYPES.FITB.description === qType) {
        let temp = [];
        temp = updateCorrectAnswers(arrangeBlanks(copyOfQuestions[indexToChange].queTitle.match(blankRegex)), indexToChange, copyOfQuestions);
        newCorrectAns = temp[indexToChange].correctAnswers;
    } else {
      newCorrectAns = correctAnswers;
    }
    newQuestion.optionsMap= optionsMap;
    newQuestion.correctAnswers = newCorrectAns;
    // newQuestion["suffix"] = ""; // Remove image suffix
    if (qTypeCode === QUESTION_TYPES.WC.name) {
      newQuestion.entries = userSettings[USER_SETTINGS.QUESTION_ENTRIES] ? userSettings[USER_SETTINGS.QUESTION_ENTRIES] : DEFAULT_ENTRIES_PER_PARTICIPANT;
    } else {
      delete newQuestion.entries;
    }

    newQuestion.timeLimit = userSettings.hasOwnProperty(USER_SETTINGS.QUESTION_DURATION) ? parseInt(userSettings[USER_SETTINGS.QUESTION_DURATION]) : DEFAULT_TIME_LIMIT;
    if ([QUESTION_TYPES.WC.name, QUESTION_TYPES.RK.name, QUESTION_TYPES.OE.name].includes(qTypeCode)) {
      newQuestion.weightage = 0;
    } else { // if (QUESTION_TYPES.MH.name === qTypeCode && !newQuestion.weightage) {
      newQuestion.weightage = userSettings.hasOwnProperty(USER_SETTINGS.QUESTION_POINTS) ? parseInt(userSettings[USER_SETTINGS.QUESTION_POINTS]) : DEFAULT_POINTS;
    }

    //
    // if (QUESTION_TYPES.MH.name === qTypeCode && !newQuestion.weightage) {
    //   let pts = !!userSettings && userSettings.hasOwnProperty(USER_SETTINGS.QUESTION_POINTS) ? userSettings[USER_SETTINGS.QUESTION_POINTS] : DEFAULT_POINTS;
    //   pts = parseInt(pts);
    //   newQuestion.weightage = !!pts ? pts : DEFAULT_POINTS;
    // }
    delete newQuestion.optionsImageMap;
    return copyOfQuestions;
  };

  export const updateAnswerImage = (file, itemKey, indexToChange, questions) => {
    const imageKey = "image" + itemKey;
    // Make a shallow copy of the items
    let copyOfQuestions = [...questions];
    // Make a shallow copy of the item you want to mutate
    let newQuestion = { ...copyOfQuestions[indexToChange] };
    // Replace the property you're intested in
    newQuestion[imageKey] = file;
    // Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    copyOfQuestions[indexToChange] = newQuestion;
    // Return our new copy
    return copyOfQuestions;
  };

  // FITB:
  const arrangeBlanks = (blankKeys) => {
    let newBlanks = {};
    if (blankKeys) {
      blankKeys.forEach((key) => {
        key = key.replaceAll(")", "").replaceAll("(", "");
        newBlanks[key] = [""];
    });
  }
    return newBlanks;
  };

  export const handleAddAnswer = (key, correctAnswers, indexToChange, questions, setQuestions) => {
    correctAnswers[key].push("");
    setQuestions(
      updateCorrectAnswers({ ...correctAnswers }, indexToChange, questions)
    );
  };

  export const handleUpdateAnswer = (key, indexInAnswersArray, text, correctAnswers, indexToChange, questions, setQuestions) => {
    correctAnswers[key][indexInAnswersArray] = text;
    setQuestions(
      updateCorrectAnswers({ ...correctAnswers }, indexToChange, questions)
    );
  };

  export const handleDeleteAnswer = (key, indexInAnswersArray, correctAnswers, indexToChange, questions, setQuestions, editorState, setEditorState, compositeDecorator) => {
    let newQuestions = questions;

    if (correctAnswers[key].length === 1) {
      const newEditorState = removeTextFromEditor("(" + key + ")", editorState, compositeDecorator);
      setEditorState(newEditorState);
      newQuestions = updateTitleInput(
        newEditorState.getCurrentContent().getPlainText(),
        indexToChange,
        questions
      );
      delete correctAnswers[key];
    } else {
      correctAnswers[key].splice(indexInAnswersArray, 1);
      console.log(newQuestions);
    }
    setQuestions(
      updateCorrectAnswers({ ...correctAnswers }, indexToChange, newQuestions)
    );
  };

  const removeTextFromEditor = (text, editorValue, compositeDecorator) => {
    const currentContent = editorValue.getCurrentContent();
    let parsedContent = currentContent;
    currentContent
      .getBlockMap()
      .filter((block) => block.getText().includes(text))
      .forEach((block) => {
        const offset = block.getText().indexOf(text);
        parsedContent = Modifier.replaceText(
          parsedContent,
          SelectionState.createEmpty(block.getKey())
            .set("anchorOffset", offset)
            .set("focusOffset", offset + text.length),
          ""
        );
      });

    return EditorState.createWithContent(parsedContent, compositeDecorator);
  };
