import React, { useState } from "react";
import {useHistory, useLocation} from "react-router-dom";
import { useLocalStorageNew } from "../hooks/useLocalStorageNew";
import styles from "../layouts/Layout.module.css";
import NavBarStyles from "./YuJaNavbar.module.css";
import {
    BUTTON,
    COLLAPSED_SIDE_BAR_WIDTH,
    EXPANDED_SIDE_BAR_WIDTH, HOME_ICON,
    IDENTITY_PROVIDER_TYPES,
    MENU_LIST,
    NAVBAR_ENGAGE, RESPONSIVE_SIDE_BAR_POINT,
} from "../utils/constants";
import JoinPollModal from "./modals/JoinPollModal";
import WarningModal from "./modals/WarningModal";
import CollapsibleMenu from "./CollapsibleMenu";
import { ReactComponent as YuJaLogo } from "../images/yujalogo.svg";
import useWindowDimensions from "../hooks/useWindowDimensions";


export default function NewSidebar({ isMobile = false, collapsed, setCollapsed }) {
    const {width} = useWindowDimensions();
    const location = useLocation();
    const history = useHistory();
    const [, getSession] = useLocalStorageNew("session", {});
    const session = getSession();
    const { role = 'ANONYMOUS' } = session;
    const isEmbeddedFrame = (session.idpType && session.idpType === IDENTITY_PROVIDER_TYPES.LMS && window.location !== window.parent.location);
    const [code, setCode] = useState("");
    const [joinModalShow, setJoinModalShow] = useState(false);
    const [warningModalShow, setWarningModalShow] = useState(false);

    const onClickLogo = () => {
        if (!!session.gradedLink) {
            return;
        }
        history.push("/home");
    }

    return (<>
        {width > RESPONSIVE_SIDE_BAR_POINT && !isMobile &&
            <div
                className={styles.sideBarContainer}
                style={{ width: (collapsed ? COLLAPSED_SIDE_BAR_WIDTH : EXPANDED_SIDE_BAR_WIDTH) }}
                onMouseLeave={() => setCollapsed(true)}
                onMouseEnter={() => setCollapsed(false)}
            >
                <div style={{padding: 8, display: "flex", flexDirection: "column", gap: 5}}>

                        {Object.values(MENU_LIST).filter(menu => menu.roles.includes(role)).map((menu, idx) => (
                            <div
                                key={idx}
                                // onMouseOver={(e) => mouseOver(e, menu.name)}
                                // onMouseLeave={(e) => mouseLeave(e, menu.name)}
                            >
                                {menu.hasOwnProperty("subStates") &&
                                    <CollapsibleMenu key={idx} menu={menu} sideBarCollapsed={collapsed} setSideBarCollapsed={setCollapsed}/>
                                }

                                {!menu.hasOwnProperty("subStates") &&
                                    <span
                                        tabIndex={0}
                                        aria-label={menu.name}
                                        role={BUTTON}
                                        id={`menu-link-${idx}`}
                                        onClick={() => {
                                            if (location.pathname !== menu.path) {
                                                setCollapsed(true);
                                            }
                                            history.push({pathname: menu.path});
                                        }}
                                        className={`${(window.location.pathname === menu.path || (!!menu.parentPage && window.location.pathname.includes(menu.parentPage))) ? styles.activeSideBarLink : styles.sidebarLink}`}
                                        hidden={!menu.roles.includes(role)}
                                    >
                                        {!collapsed && <div>
                                            <img
                                                id={`menu-icon-${menu.name}`}
                                                src={(window.location.pathname === menu.path || (!!menu.parentPage && window.location.pathname.includes(menu.parentPage))) ? menu.activeIcon : menu.icon}
                                                style={{width: "50px", height: "18px", marginRight: "5px"}}
                                                alt={`${menu.name} icon`}
                                                className={`${(window.location.pathname === menu.path || (!!menu.parentPage && window.location.pathname.includes(menu.parentPage))) ? styles.activeSubIcon : ""}`}
                                            />
                                            <span className={styles.sidebarText}>{menu.name}</span>
                                        </div>}
                                        {collapsed &&
                                            <div style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                width: "100%"
                                            }}>
                                                <img
                                                    id={`menu-icon-${menu.name}`}
                                                    src={(window.location.pathname === menu.path || (!!menu.parentPage && window.location.pathname.includes(menu.parentPage))) ? menu.activeIcon : menu.icon}
                                                    style={{height: "18px"}}
                                                    alt={`${menu.name} icon`}
                                                    className={`${(window.location.pathname === menu.path || (!!menu.parentPage && window.location.pathname.includes(menu.parentPage))) ? styles.activeSubIcon : ""}`}
                                                />
                                            </div>
                                        }
                                    </span>
                                }
                            </div>
                        ))}
                    </div>
            </div>
        }
        {isMobile &&
            <div className={styles.sideBarContainerViewer} style={{ width: "240px" }}>
                <div className={styles.sidebarMenu}>
                    <div style={{padding: 10, display: "flex", flexDirection: "column", gap: 5}}>
                        <div className={styles.sidebarTitle} style={{justifyContent: "start", marginLeft: 0, gap: 5, height: "auto"}}>
                            <div
                                tabIndex={0}
                                aria-label={HOME_ICON}
                                className={`${NavBarStyles.logoContainer} ${!session.gradedLink ? NavBarStyles.clickable : ""}`}
                                onClick={onClickLogo}
                            >
                                <YuJaLogo width={22} height={22}/>
                            </div>
                            <span tabIndex={0} className={NavBarStyles.pathContainer}>
                                {NAVBAR_ENGAGE}
                            </span>
                        </div>
                        <div className={styles.sidebarDivideLine}/>
                        {Object.values(MENU_LIST).filter(menu => menu.roles.includes(role)).map((menu, idx) => (
                            <div
                                key={idx}
                                // onMouseOver={(e) => mouseOver(e, menu.name)}
                                // onMouseLeave={(e) => mouseLeave(e, menu.name)}
                            >
                                {menu.hasOwnProperty("subStates") &&
                                    <CollapsibleMenu key={idx} menu={menu} sideBarCollapsed={collapsed} setSideBarCollapsed={setCollapsed}/>
                                }


                                {!menu.hasOwnProperty("subStates") &&
                                    <span
                                        tabIndex={0}
                                        id={`menu-link-${idx}`}
                                        onClick={() => {
                                            if (location.pathname !== menu.path) {
                                                setCollapsed(true);
                                            }
                                            history.push({pathname: menu.path});
                                        }}
                                        className={`${(window.location.pathname === menu.path || (!!menu.parentPage && window.location.pathname.includes(menu.parentPage))) ? styles.activeSideBarLink : styles.sidebarLink}`}
                                        hidden={!menu.roles.includes(role)}
                                    >
                                        <div>
                                            <img
                                                id={`menu-icon-${menu.name}`}
                                                src={window.location.pathname === menu.path ? menu.activeIcon : menu.icon}
                                                style={{width: "50px", height: "18px", marginRight: "5px"}}
                                                alt={`${menu.name} icon`}
                                                className={`${window.location.pathname === menu.path ? styles.activeSubIcon : ""}`}
                                            />
                                            <span className={styles.sidebarText}>{menu.name}</span>
                                        </div>
                                    </span>
                                }
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        }
        <JoinPollModal
            code={code}
            setCode={setCode}
            modalShow={joinModalShow}
            setModalShow={setJoinModalShow}
            setWarningModalShow={setWarningModalShow}
        />
        {warningModalShow && <WarningModal
            show={warningModalShow}
            setModalShow={setWarningModalShow}
            code={code}
            usecase={"2"}
        />}
    </>)
};
