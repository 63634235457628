import { useFetchWrapper } from "../hooks/useFetchWrapper";
import { INTEGRATION_API_URL } from "../utils/properties";
import {useLocalStorageNew} from "../hooks/useLocalStorageNew";
import {INTEGRATION_TYPES} from "../utils/constants";
import {usePPTWebView} from "../hooks/usePPTWebView";

export const LTIApiCalls = () => {
    const fetchWrapper = useFetchWrapper();
    const [, getSession] = useLocalStorageNew("session", {});
    const {isPPTPage, parseToken} = usePPTWebView();
    return {
        gradebookSync,
        createGradeColumn
    }

    // Function that syncs the score to the LMS
    function gradebookSync(pollCode, userId, gradePercentage, callback=()=>{}) {
        console.log(pollCode, userId, gradePercentage);
        return fetchWrapper.post({
            url:`${INTEGRATION_API_URL}/lti/gradebook/score`,
            body: {
                pollCode: pollCode,
                userId: userId,
                gradePercentage: gradePercentage
            }
        }).then(result => {
            // console.log(result);
            callback();
            console.log("Gradebook Sync Done!");
            return true;
        }).catch(err => {
            console.log("Error in Gradebook Sync", err);
            return false;
        })
    }

    // Function that create grade column to the LMS
    function createGradeColumn(pollKey, pollCode) {
        if (isPPTPage()) {
            const { intType=""} = parseToken();
            if (intType !== INTEGRATION_TYPES.BLACKBOARD) {
                return;
            }
        } else {
            let {intType=""} = getSession();
            if (intType !== INTEGRATION_TYPES.BLACKBOARD) {
                return;
            }
        }

        return fetchWrapper.post({
            url:`${INTEGRATION_API_URL}/lti/gradebook/column`,
            body: {
                intType: INTEGRATION_TYPES.BLACKBOARD,
                pollKey,
                pollCode,
            }
        }).then(result => {
           if (result.success) {
               console.log("Create grade column successfully.");
           } else {
               console.log("Fail to Create grade column.");
           }
        }).catch(err => {
            console.log("Error in creating grade column.", err);
            return false;
        })
    }
}
