import styles from "./JoinQuizPagination.module.css";
import React, { useEffect, useState } from "react";
import { ReactComponent as PREVIOUS } from "./images/pagination_previous_icon.svg";
import { ReactComponent as NEXT } from "./images/pagination_next_icon.svg";
import { ReactComponent as PREVIOUS_SKIP } from "./images/pagination_previous_skip_icon.svg";
import { ReactComponent as NEXT_SKIP } from "./images/pagination_next_skip_icon.svg";
import {checkUnansweredNew} from "../../utils/helpers";
import {YuJaTooltip} from "../../components/standardization/YuJaTooltip";
import useWindowDimensions from "../../hooks/useWindowDimensions";


export default function JoinQuizPagination({curSerialNo=1, questions=[], questionAnswers=[], limitation=5, switchSerialNo}) {

    const {width} = useWindowDimensions();
    const [serialNo, setSerialNo] = useState(curSerialNo);
    const [visibleQuestions, setVisibleQuestions] = useState([]);
    const [previousEnabled, setPreviousEnabled] = useState(true);
    const [nextEnabled, setNextEnabled] = useState(true);
    const [skipPreviousEnabled, setSkipPreviousEnabled] = useState(true);
    const [skipNextEnabled, setSkipNextEnabled] = useState(true);


    const initialize = () => {
        if (questions.length <= limitation) {
            setVisibleQuestions(questions);
            return;
        }

        const pageNum = Math.floor(serialNo / limitation);
        const start = pageNum * limitation;
        const end = pageNum * limitation + limitation;
        if (end > questions.length) {
            setVisibleQuestions(questions.slice(questions.length - limitation, questions.length));
        } else {
            setVisibleQuestions(questions.slice(start, end));
        }
    }

    useEffect(() => {
        initialize();
    }, []);

    useEffect(() => {
        setSerialNo(curSerialNo);
    }, [curSerialNo]);

    useEffect(() => {
        if (serialNo === 1) {
            setPreviousEnabled(false);
        } else {
            setPreviousEnabled(true);
        }

        if (serialNo >= questions.length) {
            setNextEnabled(false);
        } else {
            setNextEnabled(true);
        }


        if (serialNo - 1 < limitation) {
            setSkipPreviousEnabled(false);
        } else {
            setSkipPreviousEnabled(true);
        }


        if (questions.length - serialNo < limitation ) {
            setSkipNextEnabled(false);
        } else {
            setSkipNextEnabled(true);
        }

        if (!visibleQuestions || visibleQuestions.length === 0) {
            return;
        }


        if (serialNo < visibleQuestions[0].serialNo
            || serialNo > visibleQuestions[visibleQuestions.length - 1].serialNo
        ) {
            initialize();
        }

    }, [serialNo, questions]);


    const onClickNext = () => {
        if (!nextEnabled) {
            return;
        }
        const copy = JSON.parse(JSON.stringify(visibleQuestions));

        if (serialNo !== copy[copy.length - 1].serialNo) {
            switchSerialNo(serialNo + 1);
            setSerialNo(serialNo + 1);
            return;
        }

        copy.shift();
        copy.push(questions.find(q => q.serialNo === serialNo + 1));
        setVisibleQuestions(copy);
        setSerialNo(serialNo + 1);
        switchSerialNo(serialNo + 1);
    }

    const onClickPrevious = () => {
        if (!previousEnabled) {
            return;
        }

        const copy = JSON.parse(JSON.stringify(visibleQuestions));

        if (serialNo !== copy[0].serialNo) {
            switchSerialNo(serialNo - 1);
            setSerialNo(serialNo - 1);
            return;
        }
        copy.pop();
        copy.unshift(questions.find(q => q.serialNo === serialNo - 1));
        setVisibleQuestions(copy);
        setSerialNo(serialNo - 1);
        switchSerialNo(serialNo - 1);
    }

    const onClickSkipNext = () => {
        if (!skipNextEnabled) {
            return;
        }

        const firstSerialNo = visibleQuestions[0].serialNo;
        const lastSerialNo = visibleQuestions[visibleQuestions.length -1 ].serialNo;

        const start = firstSerialNo + limitation - 1;
        const end = lastSerialNo + limitation;
        if (end >= questions.length) {
            setVisibleQuestions(questions.slice(questions.length - limitation, questions.length));
            switchSerialNo(questions.length - limitation + 1);
            setSerialNo(questions.length - limitation + 1);
        } else {
            setVisibleQuestions(questions.slice(start, end));
            switchSerialNo(start + 1);
            setSerialNo(start + 1);
        }
    }


    const onClickNum = (serialNo) => {
        switchSerialNo(serialNo);
        setSerialNo(serialNo);
    }


    const onClickSkipPrevious = () => {
        if (!skipPreviousEnabled) {
            return;
        }

        const firstSerialNo = visibleQuestions[0].serialNo;
        const lastSerialNo = visibleQuestions[visibleQuestions.length -1 ].serialNo;

        setVisibleQuestions(questions.slice(firstSerialNo - limitation - 1, lastSerialNo - limitation - 1));

        const start = firstSerialNo - limitation - 1;
        const end = lastSerialNo - limitation;
        if (start < 0) {
            setVisibleQuestions(questions.slice(0, limitation));
            switchSerialNo(1);
            setSerialNo(1);
        } else {
            setVisibleQuestions(questions.slice(start, end));
            switchSerialNo(start + 1);
            setSerialNo(start + 1);
        }
    }

    return (
        <div className={styles.container}>
            {width >= 1200 ?
                <>
                    <YuJaTooltip placement={"bottom"} text={"Skip"}>
                        <div className={skipPreviousEnabled ? styles.backForthButton : styles.disabledBackForthButton} onClick={onClickSkipPrevious}>
                            <PREVIOUS_SKIP/>
                        </div>
                    </YuJaTooltip>
                    <YuJaTooltip placement={"bottom"} text={"Previous"}>
                        <div className={previousEnabled ? styles.backForthButton : styles.disabledBackForthButton} onClick={onClickPrevious}>
                            <PREVIOUS/>
                        </div>
                    </YuJaTooltip>
                </>
                :
                <>
                    <div className={skipPreviousEnabled ? styles.backForthButton : styles.disabledBackForthButton} onClick={onClickSkipPrevious}>
                        <PREVIOUS_SKIP/>
                    </div>
                    <div className={previousEnabled ? styles.backForthButton : styles.disabledBackForthButton} onClick={onClickPrevious}>
                        <PREVIOUS/>
                    </div>
                </>
            }


            {visibleQuestions.map((que, index)=> {
                const answer = questionAnswers.find(i => i?.serialNo === que.serialNo);
                const isUnanswered = checkUnansweredNew(!!que.weightage, que.questionType, answer?.selectedOption);

                return (width >= 1200 ?
                        <YuJaTooltip key={index} placement={"bottom"} text={isUnanswered ? "Unattempted" : "Attempted"}>
                            <div
                                className={`${isUnanswered ? styles.unansweredQuestionButton : styles.answeredQuestionButton} ${serialNo === que.serialNo ? styles.active : ""}`}
                                onClick={() => onClickNum(que.serialNo)}
                            >
                                {que.serialNo}
                            </div>
                        </YuJaTooltip>
                        :
                        <div
                            key={index}
                            className={`${isUnanswered ? styles.unansweredQuestionButton : styles.answeredQuestionButton} ${serialNo === que.serialNo ? styles.active : ""}`}
                            onClick={() => onClickNum(que.serialNo)}
                        >
                            {que.serialNo}
                        </div>
                );
            })}


            {width >= 1200 ?
                <>
                    <YuJaTooltip placement={"bottom"} text={"Next"}>
                        <div className={nextEnabled ? styles.backForthButton : styles.disabledBackForthButton}
                             onClick={onClickNext}>
                            <NEXT/>
                        </div>
                    </YuJaTooltip>
                    <YuJaTooltip placement={"bottom"} text={"Skip"}>
                        <div className={skipNextEnabled ? styles.backForthButton : styles.disabledBackForthButton} onClick={onClickSkipNext}>
                            <NEXT_SKIP/>
                        </div>
                    </YuJaTooltip>
                </>
                :
                <>
                    <div className={nextEnabled ? styles.backForthButton : styles.disabledBackForthButton}
                         onClick={onClickNext}>
                        <NEXT/>
                    </div>
                    <div className={skipNextEnabled ? styles.backForthButton : styles.disabledBackForthButton} onClick={onClickSkipNext}>
                        <NEXT_SKIP/>
                    </div>
                </>
            }
        </div>
    );
}