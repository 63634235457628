import React, { useRef, useEffect, useState } from "react";
import ClockBlack from "./images/timer-icon.svg";
import ClockRed from "./images/timer-icon-red.svg";
import Countdown from 'react-countdown';
import {HOUR_LABEL, MIN_LABEL, QUIZ_STATE, SEC_LABEL, TEXTBOX, TIME_REMAINING} from "../../utils/constants";
import useMobileAccess from "../../hooks/useMobileAccess";
import Lottie from "react-lottie";
import Clock from "./animations/Green_Timer.json";
import RedClock from "./animations/Red_Timer.json";

export default function CountdownTimerAll({ pollEndTime, timeUp, state }) { // timedSurvey=false
  let hurry = false;
  const countDownRef = useRef();
  const isNarrow = useMobileAccess(1200);
  const handleStart = () => countDownRef.current.start();
  const handlePause = () => countDownRef.current.pause();
  // const [displaySurveyTimer, setDisplaySurveyTimer] = useState(true);

  useEffect(() => {
    if (state === QUIZ_STATE.PAUSED) {
      handlePause();
      console.log('Time Is Paused');
    }
    else if (state === QUIZ_STATE.RESUMED) {
      handleStart();
      console.log('Time Is Resumed');
    }
  }, [state]);

  const isEndRef = useRef();
  isEndRef.current = new Date() >= pollEndTime;

  useEffect(() => {
    if (new Date() >= pollEndTime) {
      timeUp();
    }
  }, [pollEndTime, timeUp])

  const renderer = ({ days, hours, minutes, seconds }) => {
    if (days === 0 && hours === 0 && minutes === 0 && seconds <= 1 ) {
      if (document.getElementById("tc") !== null)
        document.getElementById("tc").style.color = '#C93E3E';
    }
    const time = (seconds + minutes * 60 + hours * 3600 + days * 86400);
    // if(timedSurvey && time <= 20 ) {
    //   if (document.getElementById("timer-counter") !== null) {
    //       document.getElementById("timer-counter").style.display = "flex";
    //   }
    // }

    if(days === 0 && hours === 0 && minutes === 0 && seconds <= 10 ) {
      if (document.getElementById("th") !== null) {
          document.getElementById("th").style.border = "2.5px solid #BF0033";
      }
      hurry = true;
    }

    // if (timedSurvey && time > 20 ) {
    //   if (document.getElementById("timer-counter") !== null) {
    //       document.getElementById("timer-counter").style.display = "none";
    //   }
    // }
    // pad with 2 leading zeros
    hours = ('00' + ((days*24)+hours)).slice(-2);
    minutes = ('00' + minutes).slice(-2);
    seconds = ('00' + seconds).slice(-2);

    return (<>
      {/* <img src={hurry ? ClockRed : ClockBlack} id="clock" className="timer-logo" alt="clock.icon"  style={{width: "32px", height: "32px"}}/> */}
      <Lottie
                options={{
                  loop: hurry ? true : false,
                  autoplay: true,
                  animationData: hurry ? RedClock : Clock,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  }
                }}
                height={isNarrow ? 28 : 32}
                style={{margin: "0px 0px 5px 0px"}}
                width={isNarrow ? 28 : 32}
                isClickToPauseDisabled={true}
              /> 
      <span className="timer-count" tabIndex={0} role={TEXTBOX} aria-live="off" aria-atomic="true" aria-label={TIME_REMAINING + hours + HOUR_LABEL + minutes + MIN_LABEL + seconds + SEC_LABEL} id="tc" style={{fontSize: isNarrow ? 14 : 18}}>{hours}:{minutes}:{seconds}</span>
    </>);
  };

  return (
    <>
      <div className="timer-outer" style={{height: "100%"}}>
        <div className="img-wrap timer-holder" id="th">
          <Countdown
            ref={countDownRef}
            className="timer-count"
            date={pollEndTime}
            renderer={renderer}
            onComplete={() => {
              timeUp();
            }}
          />
        </div>
      </div>
    </>
  );
}
