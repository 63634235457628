import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import { ADD_QUESTION_MODAL_BODY_TEXT, ADD_QUESTION_MODAL_TITLE, ADD_QUESTION_TEXT, CANCEL_UPDATE_TEXT, MODAL, MODAL_CANCEL_TEXT, TEXTBOX, UPDATE_QUESTION_MODAL_BODY_TEXT, UPDATE_QUESTION_TEXT } from "../../utils/constants";
import YuJaButton from "../standardization/YuJaButton";
import "./modal.css";
import loadingGif from "../../images/LoadingAnimation.gif";


export default function AddQuestionConfirmationModal({ show, setShow, handleSubmit, isNewQuestion }) {
    const [isProgressing, setIsProgressing] = useState(false);

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <ModalClose onClick={() => setShow(false)} className="modal-close" />
                <Modal.Title
                    className="modal-title"
                    id="contained-modal-title-vcenter"
                    tabIndex={0}
                    aria-label={"Set Poll Time Limit" + MODAL}
                    role={TEXTBOX}
                >
                    {isNewQuestion ? ADD_QUESTION_MODAL_TITLE : UPDATE_QUESTION_TEXT}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isNewQuestion ? ADD_QUESTION_MODAL_BODY_TEXT : UPDATE_QUESTION_MODAL_BODY_TEXT}
            </Modal.Body>
            <Modal.Footer>
                <YuJaButton type="secondary" onClick={() => setShow(false)}>{isNewQuestion ? MODAL_CANCEL_TEXT : CANCEL_UPDATE_TEXT}</YuJaButton>
                <YuJaButton
                    style={{width: 130}}
                    disabled={isProgressing}
                    onClick={async () => {
                        setIsProgressing(true);
                        await handleSubmit();
                        setIsProgressing(false);
                    }}
                >
                    {isProgressing
                        ? <img src={loadingGif} alt={"loading..."} style={{height: 20}}/>
                        : isNewQuestion ? ADD_QUESTION_TEXT : UPDATE_QUESTION_TEXT
                    }
                </YuJaButton>
            </Modal.Footer>
        </Modal>
    );
}
