import React, {useState} from "react";
import useMobileAccess from "../../hooks/useMobileAccess";
import "../styles.css";
import RKQuestionOption from "./RKQuestionOption";
import { ReactComponent as PlusIcon } from "../../images/plus.svg";
import {  addRankToQuestion } from "../../utils/questionUtils";
import YuJaButton from "../../components/standardization/YuJaButton";
import { notifyInfo } from "../../utils/helpers";
import { MCSS_ADD_OPTION_TEXT } from "../../utils/constants";
import { ADD_OPTIONS_INFO } from "../../utils/toast-message-constants";
import {useCheckMainContainerNarrow} from "../../hooks/useCheckMainContainerNarrow";

export default function RKQuestion({
    setQuestions,
    questions,
    selectedQuestion,
    questionBuilder = false,
    disabled = false,
    showAddOptionBtn = true,
}) {
  const { optionsMap} = selectedQuestion;
  const {isNarrow} = useCheckMainContainerNarrow();
  const [warningShowed, setWarningShowed] = useState(false);

  return (
          <div
            className={questionBuilder ? "question-builder-options" : "droppable-multiple-choice-question-options"}
            id="mcq-options-container"
            style={{
                marginTop: questionBuilder ? 0 : 10,
                display: "flex",
                padding: questionBuilder ? 0 : 3,
                flexDirection: "column",
                gap: (isNarrow || questionBuilder) ? 10 : 20
            }}
          >
              {optionsMap.map((option, index) => {
                  return (
                      <div key={index}>
                          <RKQuestionOption
                              optionData={{choice: index, optionValue: option}}
                              selectedQuestion={selectedQuestion}
                              questions={questions}
                              setQuestions={setQuestions}
                              index={index}
                              maxSize={optionsMap.length}
                              questionBuilder={questionBuilder}
                              disabled={disabled}
                              warningShowed={warningShowed}
                              setWarningShowed={setWarningShowed}

                          />
                      </div>
                  );
              })}

              {!!showAddOptionBtn &&
                  <div style={{width: "100%", display: "flex", justifyContent: "center", paddingBottom: 5}}>
                      <YuJaButton
                          title="Add Option"
                          onClick={() => {
                              const newQuestions = addRankToQuestion(
                                  selectedQuestion,
                                  questions
                              );
                              if (newQuestions) {
                                  setQuestions(newQuestions);
                                  setTimeout(() => {
                                      const mcqOptionsContainer = document.getElementById("mcq-options-container");
                                      mcqOptionsContainer?.scroll({
                                          top: mcqOptionsContainer.scrollHeight,
                                          behavior: "smooth"
                                      });
                                  }, 10);
                              } else if (!warningShowed) {
                                  setWarningShowed(true);
                                  notifyInfo(ADD_OPTIONS_INFO);
                              }
                          }}
                      >
                          <PlusIcon style={{width: 15, height: 15, marginRight: 2}}/> {MCSS_ADD_OPTION_TEXT}
                      </YuJaButton>
                  </div>
              }

          </div>
  );
}