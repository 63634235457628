import { useEffect, useRef, useState } from "react";
// import { notifyError } from "../utils/helpers";
// import { QUESTION_POINTS_MIN_ERROR } from "../utils/toast-message-constants";
import { UNGRADED_POINT_INPUT_DESCRIPTION } from "../utils/constants";
import { notifyError } from "../utils/helpers";
import { INVALID_POINTS_ERROR } from "../utils/toast-message-constants";
import styles from "./TimeLimitInputBox.module.css";
import { YuJaTooltip } from "./standardization/YuJaTooltip";


export default function PointsInputBox({
                                        //    unZero = false,
                                           containerStyle = {},
                                           onBlur,
                                           tooltipText=UNGRADED_POINT_INPUT_DESCRIPTION,
                                           tooltipStyle={},
                                           ...props
                                          }) {
    const [temporaryVal, setTemporaryVal] = useState("");

    const initializeInput = (value) => {
        if (isNaN(value)) {
            value = props.value;
        }
        if (!!value && parseInt(value)) {
            setTemporaryVal(value);
        } else {
            setTemporaryVal("");
        }
    }

    useEffect(() => {
        initializeInput();
    }, [props.value]);

    const actualInputRef = useRef(null);

    const onClick = (e) => {
        e.stopPropagation();
        actualInputRef.current.focus();
    }

    const onChangeFunc = (e) => {
        e.stopPropagation();
        let val = e.target.value.replace(/[^0-9]/g, "");
        setTemporaryVal(val);
    }

    const onBlurFunc = () => {
        const points = Number(temporaryVal);
        if (!onBlur) {
            return;
        }

        if (isNaN(points)) {
            initializeInput();
        } else {
            // if (!points && !!unZero) {
            //     notifyError(QUESTION_POINTS_MIN_ERROR);
            //     initializeInput();
            // }
            if (points > 100) {
                notifyError(INVALID_POINTS_ERROR);
                initializeInput();
            } else {
                onBlur(points);
                initializeInput(points);
            }

        }

    }

    return (
        <>
            {!!tooltipText &&
                <YuJaTooltip
                    placement={"top"}
                    text={tooltipText}
                    tooltipStyle={tooltipStyle}
                >
                    <div
                        className={`${styles.box} ${props.disabled ? styles.disabled : ""}`}
                        style={{...containerStyle}}
                        onClick={onClick}
                        tabIndex={-1}
                    >
                        <input
                            {...props}
                            ref={actualInputRef}
                            value={temporaryVal}
                            onChange={onChangeFunc}
                            onBlur={onBlurFunc}
                            placeholder={"0"}
                            disabled={props.disabled}
                            aria-label={"textbox"}
                        />
                    </div>
                </YuJaTooltip>
            }
            {!tooltipText &&
                <div
                    className={`${styles.box} ${props.disabled ? styles.disabled : ""}`}
                    style={{...containerStyle}}
                    onClick={onClick}
                    tabIndex={-1}
                >
                    <input
                        {...props}
                        ref={actualInputRef}
                        value={temporaryVal}
                        onChange={onChangeFunc}
                        onBlur={onBlurFunc}
                        placeholder={"0"}
                        disabled={props.disabled}
                        aria-label={"textbox"}
                    />
                </div>
            }
        </>

    );
}