import React, { useEffect, useRef, useState } from "react";
import Countdown from 'react-countdown';
import Lottie from "react-lottie";
import useMobileAccess from "../../hooks/useMobileAccess";
import { ATTENDANCE_COUNTDOWN_START, COUNTDOWN_SUFFIX, HOUR_LABEL, MIN_LABEL, QUIZ_STATE, SEC_LABEL, TEXTBOX, TIME_REMAINING } from "../../utils/constants";
import Clock from "./animations/Green_Timer.json";
import RedClock from "./animations/Red_Timer.json";

export default function CountdownTimer({ questionEndTime, state, setIsTimeOut, forAttendance=false }) {
  let hurry = false;
  const countDownRef = useRef();
  const isNarrow = useMobileAccess(1200);
  const handleStart = () => countDownRef.current.start();
  const handlePause = () => countDownRef.current.pause();
  const [visibility, setVisibility] = useState(false);

  useEffect(() => {
    if (state === QUIZ_STATE.PAUSED) {
      handlePause();
      console.log('Time Is Paused');
    }
    else if (state === QUIZ_STATE.RESUMED) {
      handleStart();
      console.log('Time Is Resumed');
    }
  }, [state]);

  const isEndRef = useRef();
  isEndRef.current = new Date() >= questionEndTime;

  useEffect(() => {
    if (new Date() >= questionEndTime && setIsTimeOut) {
      setIsTimeOut(true);
    }
  }, [questionEndTime, setIsTimeOut]);

  const renderer = ({ hours, minutes, seconds }) => {
    if (forAttendance) {
      if (hours === 0 && minutes === 0 && seconds <= ATTENDANCE_COUNTDOWN_START && seconds > 0) {
        if (document.getElementById("tc") !== null) document.getElementById("tc").style.color = '#C93E3E';
        setVisibility(true);
      } else {
        setVisibility(false);
      }
    } else {
      if (hours === 0 && minutes === 0 && seconds <= 1 ) {
        if (document.getElementById("tc") !== null)
          document.getElementById("tc").style.color = '#C93E3E';
      }
      if(hours === 0 && minutes === 0 && seconds <= 10 ) {
        if (document.getElementById("th") !== null) {
            document.getElementById("th").style.border = "2.5px solid #BF0033";
        }
        hurry = true;
      } if (hours > 0 || minutes > 0 || seconds > 10) {
        if (document.getElementById("th") !== null) {
          document.getElementById("th").style.border = "2.5px solid #056808";
      }
      hurry = false;
      }
    }

    // pad with 2 leading zeros
    hours = ('00' + hours).slice(-2);
    minutes = ('00' + minutes).slice(-2);
    seconds = ('00' + seconds).slice(-2);

    return (
        <>
          {(hurry || forAttendance) && <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: RedClock,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  }
                }}
                height={isNarrow ? 28 : 31.5}
                style={{margin: "0px 0px 5px 0px"}}
                width={isNarrow ? 28 : 31.5}
                isClickToPauseDisabled={true}
              /> }
          {(!hurry && !forAttendance) && <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: Clock,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  }
                }}
                height={isNarrow ? 28 : 32}
                style={{margin: "0px 0px 5px 0px"}}
                width={isNarrow ? 28 : 32}
                isClickToPauseDisabled={true}
              /> }
          {forAttendance ?
            <span className="timer-count" tabIndex={0} role={TEXTBOX} aria-live="off" aria-atomic="true" aria-label={TIME_REMAINING + seconds + SEC_LABEL} id="tc" style={{fontSize: isNarrow ? 14 : 18}}>{seconds}{COUNTDOWN_SUFFIX}</span> :
            <span className="timer-count" tabIndex={0} role={TEXTBOX} aria-live="off" aria-atomic="true" aria-label={TIME_REMAINING + hours + HOUR_LABEL + minutes + MIN_LABEL + seconds + SEC_LABEL} id="tc" style={{fontSize: isNarrow ? 14 : 18}}>{hours}:{minutes}:{seconds}</span>
          }
        </>
    );
  };

  return (<>
      <div className={`timer-outer ${forAttendance && visibility ? "attendance-countdown-wrapper" : ""}`} style={{display: (!forAttendance || visibility) ? "block" : "none", height: "100%"}}>
        <div className="img-wrap timer-holder" id="th">
          <Countdown
            ref={countDownRef}
            className="timer-count"
            date={questionEndTime}
            renderer={renderer}
            onComplete={() => {
              if (setIsTimeOut) {
                setIsTimeOut(true);
                setVisibility(false);
              }
            }}
          />
        </div>
      </div>
  </>);
}
