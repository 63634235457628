import React, {Fragment, useEffect, useState} from "react";
import { Form, Card } from "react-bootstrap";
import { InstitutionApiCalls } from "../../../../apis/InstitutionApiCalls";
import {
  CREATION_ROLES,
  INTEGRATION_TYPES,
  INTEGRATION_TYPES_LOWERCASE,
  ROLE_MAPPING,
  blackboardRoles,
  canvasRoles,
  moodleRoles,
  d2lRoles,
  LTI_USER_MAPPING,
  API_CONFIG,
  ISS,
  CLIENT_ID,
  USER_CONFIG,
  AUTO_PROVISION_COURSE,
  AUTO_SYNC_COURSE,
  AUTO_SYNC_USER,
  AUTO_PROVISION_USER,
  OIDC,
  JWKS,
  OAUTH,
  DEPLOYMENT,
  COURSE_CONFIG,
  TEXTBOX,
  INTEG_TEXT_BOX,
  AUTO_PROVISION_USER_DESC,
  AUTO_SYNC_USER_DESC,
  AUTO_PROVISION_COURSE_DESC, AUTO_SYNC_COURSE_DESC
} from "../../../../utils/constants";
import styles from "./LTI3.module.css";
import {YuJaCheckbox} from "../../../../components/standardization/YuJaCheckbox";
import {YuJaTextBox} from "../../../../components/standardization/YuJaTextBox";
import {YuJaDropdown} from "../../../../components/standardization/YuJaDropdown";
import crossIntegrationLTI from "../../../../images/crossIntegrationLTI.svg";
import roleMapping from "../../../../images/roleMapping.svg";
import userProvisioning from "../../../../images/userProvisioning.svg";
import LTiIcon from "../../../../images/ltiicon.svg";
import generalIcon from "../../../../images/generalIcon.svg";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {YuJaToggle} from "../../../../components/standardization/YuJaToggle";

export default function GeneralLTIPage({selectedMenu, canvasForm, blackboardForm, d2lForm, moodleForm, setD2lForm, setBlackboardForm, setCanvasForm, setMoodleForm}) {
  const [loaded, setLoaded] = useState(false);
  const { getSSODetails } = InstitutionApiCalls();
  const [loading , setLoading] = useState(false); 

  const getIntegrationType = () => {
    if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.BLACKBOARD) {return INTEGRATION_TYPES.BLACKBOARD}
    else if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.CANVAS) {return INTEGRATION_TYPES.CANVAS}
    else if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.MOODLE) {return INTEGRATION_TYPES.MOODLE}
    else if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.D2L) {return INTEGRATION_TYPES.D2L}
  };

  const getSSOMap = (ssoMap) => {
    if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.BLACKBOARD) {return ssoMap.BLACKBOARD}
    else if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.CANVAS) {return ssoMap.CANVAS}
    else if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.MOODLE) {return ssoMap.MOODLE}
    else if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.D2L) {return ssoMap.D2L}
  };

  const fetchData = () => {
    setLoading(true);
    getSSODetails().then((result) => {
      let ssoMap = result.ssoConfig;
      const ssoConfigData = JSON.parse(
        ssoMap.hasOwnProperty(getIntegrationType())
          ? getSSOMap(ssoMap)
          : "{}"
      );
      if (
        ssoConfigData !== "undefined" ||
        ssoConfigData.propertyIsEnumerable()
      ) {
        if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.BLACKBOARD) { setBlackboardForm(ssoConfigData); }
        else if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.CANVAS) { setCanvasForm(ssoConfigData); }
        else if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.MOODLE) { setMoodleForm(ssoConfigData); }
        else if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.D2L) { setD2lForm(ssoConfigData); }
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
    setLoaded(true);
    if(document.getElementById("lmsLastElem") != null) {
      document.getElementById("lmsLastElem").addEventListener('keydown', function (e) {
          if (e.key === 'Tab' && !e.shiftKey && document.getElementById("integrationSaveBtn")) {
            e.preventDefault();
            document.getElementById('integrationSaveBtn').focus();
          }
      });
  }
  }, [loaded, selectedMenu]);

  const handleBlackboardFormChange = (e) => {
    const input = e.currentTarget;
    const blackboardFormConst = Object.assign({}, blackboardForm);
    if (input.type === "checkbox") {
      blackboardFormConst[input.name] = input.checked;
    } else {
      blackboardFormConst[input.name] = input.value;
    }
    setBlackboardForm(blackboardFormConst);
  };

  const handleCanvasFormChange = (e) => {
    const input = e.currentTarget;
    const canvasFormConst = Object.assign({}, canvasForm);
    if(input.type === "checkbox") {
        canvasFormConst[input.name] = input.checked;
    } else {
        canvasFormConst[input.name] = input.value;
    }
    setCanvasForm(canvasFormConst);
  };

  const handleD2lFormChange = (e) => {
    const input = e.currentTarget;
    const d2lFormConst = Object.assign({}, d2lForm);
    if (input.type === "checkbox") {
      d2lFormConst[input.name] = input.checked;
    } else {
      d2lFormConst[input.name] = input.value;
    }
    setD2lForm(d2lFormConst);
  };

  const handleMoodleFormChange = (e) => {
    const input = e.currentTarget;
    const moodleFormConst = Object.assign({}, moodleForm);
    if (input.type === "checkbox") {
      moodleFormConst[input.name] = input.checked;
    } else {
      moodleFormConst[input.name] = input.value;
    }
    setMoodleForm(moodleFormConst);
  };

  const getValueBlackboard = (element) => {
    if(element.display === LTI_USER_MAPPING.STUDENT) {return blackboardForm.studentRole}
    else if(element.display === LTI_USER_MAPPING.INSTRUCTOR) {return blackboardForm.instructorRole}
    else if(element.display === LTI_USER_MAPPING.GRADER) {return blackboardForm.graderRole}
    else if(element.display === LTI_USER_MAPPING.COURSE_BUILDER) {return blackboardForm.courseBuilderRole}
    else if(element.display === LTI_USER_MAPPING.TEACHING_ASSISTANT) {return blackboardForm.teachingAssistantRole}
    else if(element.display === LTI_USER_MAPPING.GUEST) {return blackboardForm.guestRole}
    else if(element.display === LTI_USER_MAPPING.ADMIN) {return blackboardForm.adminRole}
    else if(element.display === LTI_USER_MAPPING.OTHER) {return blackboardForm.otherRole}
  };

  const getValueCanvas = (element) => {
    if(element.display === LTI_USER_MAPPING.ADMIN) {return canvasForm.adminRole}
    else if(element.display === LTI_USER_MAPPING.STUDENT) {return canvasForm.studentRole}
    else if(element.display === LTI_USER_MAPPING.TEACHER) {return canvasForm.teacherRole}
    else if(element.display === LTI_USER_MAPPING.TEACHING_ASSISTANT) {return canvasForm.teachingAssistantRole}
    else if(element.display === LTI_USER_MAPPING.DESIGNER) {return canvasForm.designerRole}
    else if(element.display === LTI_USER_MAPPING.OBSERVER) {return canvasForm.observerRole}
  };

  const getValueMoodle = (element) => {
    if(element.display === LTI_USER_MAPPING.STUDENT_COURSE) {return moodleForm.studentRole}
    else if(element.display === LTI_USER_MAPPING.TEACHER_MANAGER) {return moodleForm.instructorRole}
    else if(element.display === LTI_USER_MAPPING.ADMIN) {return moodleForm.adminRole}
  };

  const getValueD2L = (element) => {
    if(element.display === LTI_USER_MAPPING.STUDENT_COURSE) {return d2lForm.studentRole}
    else if(element.display === LTI_USER_MAPPING.INSTRUCTOR) {return d2lForm.instructorRole}
    else if(element.display === LTI_USER_MAPPING.ADMIN) {return d2lForm.adminRole}
  };

  const callUpdateFunction = (e) => {
    if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.BLACKBOARD) {handleBlackboardFormChange(e);}
    else if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.CANVAS) {handleCanvasFormChange(e);}
    else if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.MOODLE) {handleMoodleFormChange(e);}
    else if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.D2L) {handleD2lFormChange(e);}
  };

  const getForm = () => {
    if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.BLACKBOARD) {return blackboardForm;}
    else if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.CANVAS) {return canvasForm;}
    else if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.MOODLE) {return moodleForm;}
    else if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.D2L) {return d2lForm;}
  };

  return (
    <div style={{display: "flex", flexDirection: "row", gap: 20, flexWrap: "wrap", marginTop: 20}}>
      <Card className={styles.smallBox}>
        <Card.Header>
            <img src={roleMapping} alt="RoleMapping" width={"24px"} height={"24px"} />
            <span tabIndex={0} role={TEXTBOX} className={styles.cardHeader}>User {ROLE_MAPPING}</span>
        </Card.Header>
        <Card.Body>
            <div style={{display: "grid", gridTemplateColumns: "1fr auto 1fr", gap: 10, alignItems: "center"}}>
              <div tabIndex={0} className="form-labelReg" style={{position: "relative", height: "100%"}}>
                <span style={{position: "absolute", top: 0, left: 0, whiteSpace: "nowrap"}}>{selectedMenu} Course Role</span>
              </div>
              <div style={{width: 32}}/>
              <div tabIndex={0} style={{fontWeight: "700", fontSize: 15, width: 150, justifySelf: "end"}}>YuJa Role</div>
            </div>

              {selectedMenu === INTEGRATION_TYPES_LOWERCASE.BLACKBOARD && !loading &&
                  <div style={{display: "grid", gridTemplateColumns: "auto 1fr auto", gap: 10, alignItems: "center", marginTop: 10}}>
                    {blackboardRoles.map(element =>
                        <Fragment key={element.display}>
                            {element.display === LTI_USER_MAPPING.ADMIN &&
                                <Fragment>
                                  <div tabIndex={0} className="form-label" style={{position: "relative", height: "100%"}}>
                                  <span style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    whiteSpace: "nowrap"
                                  }}>{selectedMenu} System Role</span>
                                  </div>
                                  <div style={{width: 32}}/>
                                  <div tabIndex={0} style={{fontWeight: "700", fontSize: 15, width: 150}}>YuJa Role</div>
                                </Fragment>
                            }
                            <Fragment>
                              <div tabIndex={0} className={styles.labelDesc}>{element.display}</div>
                              <img src={LTiIcon} alt="LTiIcon" width={"32px"} height={"32px"} style={{justifySelf: "center"}}/>
                              <YuJaDropdown
                                  containerStyle={{width: 150}}
                                  data={Object.values(CREATION_ROLES)}
                                  value={getValueBlackboard(element)}
                                  getOptionLabel={item => item.display}
                                  onChange={item => {
                                    const e = {currentTarget: {value: item.value, name: element.value}};
                                    handleBlackboardFormChange(e);
                                  }}
                              />
                            </Fragment>
                    </Fragment>
                    )}
                  </div>
              }
                    {selectedMenu === INTEGRATION_TYPES_LOWERCASE.BLACKBOARD && loading &&
                        <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                          {blackboardRoles.map(element =>
                              <Skeleton height={37} borderRadius={10} inline={true} style={{marginTop: 20}}/>
                          )}
                        </SkeletonTheme>
                    }
                    {selectedMenu === INTEGRATION_TYPES_LOWERCASE.CANVAS && !loading &&
                        <div style={{
                          display: "grid",
                          gridTemplateColumns: "auto 1fr auto",
                          gap: 10,
                          alignItems: "center",
                          marginTop: 10
                        }}>
                          {canvasRoles.map(element =>
                              <Fragment key={element.display}>
                                <div tabIndex={0} className={styles.labelDesc}>{element.display}</div>
                                <img src={LTiIcon} alt="LTiIcon" width={"32px"} height={"32px"}
                                     style={{justifySelf: "center"}}/>
                                <YuJaDropdown
                                    containerStyle={{width: 150}}
                                    data={Object.values(CREATION_ROLES)}
                                    value={getValueCanvas(element)}
                                    getOptionLabel={item => item.display}
                                    onChange={item => {
                                      const e = {currentTarget: {value: item.value, name: element.value}};
                                      handleCanvasFormChange(e);
                                    }}
                                />
                              </Fragment>
                          )}
                        </div>
                    }
                    {selectedMenu === INTEGRATION_TYPES_LOWERCASE.CANVAS && loading &&
                        <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                          {canvasRoles.map(element =>
                              <Skeleton height={37} borderRadius={10} inline={true} style={{marginTop: 20}}/>
                          )}
                        </SkeletonTheme>
                    }
                    {selectedMenu === INTEGRATION_TYPES_LOWERCASE.MOODLE && !loading &&
                        <div style={{
                          display: "grid",
                          gridTemplateColumns: "auto 1fr auto",
                          gap: 10,
                          alignItems: "center",
                          marginTop: 10
                        }}>
                          {moodleRoles.map(element =>
                              <Fragment key={element.display}>
                                <div tabIndex={0} className={styles.labelDesc}>{element.display}</div>
                                <img src={LTiIcon} alt="LTiIcon" width={"32px"} height={"32px"}
                                     style={{justifySelf: "center"}}/>
                                <YuJaDropdown
                                    containerStyle={{width: 150}}
                                    data={Object.values(CREATION_ROLES)}
                                    value={getValueMoodle(element)}
                                    getOptionLabel={item => item.display}
                                    onChange={item => {
                                      const e = {currentTarget: {value: item.value, name: element.value}};
                                      handleMoodleFormChange(e);
                                    }}
                                />
                              </Fragment>
                          )}
                        </div>
                    }
                    {selectedMenu === INTEGRATION_TYPES_LOWERCASE.MOODLE && loading &&
                        <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                          {moodleRoles.map(element =>
                              <Skeleton height={37} borderRadius={10} inline={true} style={{marginTop: 20}}/>
                          )}
                        </SkeletonTheme>
                    }
                    {selectedMenu === INTEGRATION_TYPES_LOWERCASE.D2L && !loading &&
                        <div style={{
                          display: "grid",
                          gridTemplateColumns: "auto 1fr auto",
                          gap: 10,
                          alignItems: "center",
                          marginTop: 10
                        }}>
                          {d2lRoles.map(element =>
                              <Fragment key={element.display}>
                                <div tabIndex={0} className={styles.labelDesc}>{element.display}</div>
                                <img src={LTiIcon} alt="LTiIcon" width={"32px"} height={"32px"}
                                     style={{justifySelf: "center"}}/>
                                <YuJaDropdown
                                    containerStyle={{width: 150}}
                                    data={Object.values(CREATION_ROLES)}
                                    value={getValueD2L(element)}
                                    getOptionLabel={item => item.display}
                                    onChange={item => {
                                      const e = {currentTarget: {value: item.value, name: element.value}};
                                      handleD2lFormChange(e);
                                    }}
                                />
                              </Fragment>
                          )}
                        </div>
                    }
                    {selectedMenu === INTEGRATION_TYPES_LOWERCASE.D2L && loading &&
                        <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                          {d2lRoles.map(element =>
                              <Skeleton height={37} borderRadius={10} inline={true} style={{marginTop: 20}}/>
                          )}
                        </SkeletonTheme>
                    }
                  </Card.Body>
                </Card>
                <Card className={styles.smallBox}>
          <Card.Header>
            <img src={crossIntegrationLTI} alt="CrossIntegrationLTI" width={"24px"} height={"24px"}/>
          <span tabIndex={0} role={TEXTBOX} className={styles.cardHeader}>{API_CONFIG}</span>
        </Card.Header>
        <Card.Body>
          <Form>
            {!loading &&
                <>
                  <Form.Group>
                    <div className="form-label">ISS</div>
                    <YuJaTextBox
                        label={ISS + INTEG_TEXT_BOX}
                        placeholder={ISS}
                        name="iss"
                        value={getForm().iss}
                        containerStyle={{fontSize: 14}}
                        onChange={callUpdateFunction}
                    />
                  </Form.Group>
                  <Form.Group>
                    <div className="form-label">Client Id</div>
                    <YuJaTextBox
                        label={CLIENT_ID + INTEG_TEXT_BOX}
                        placeholder={CLIENT_ID}
                        name="clientId"
                        value={getForm().clientId}
                        containerStyle={{fontSize: 14}}
                        onChange={callUpdateFunction}
                    />
                  </Form.Group>
                  <Form.Group>
                    <div className="form-label">OIDC Endpoint</div>
                    <YuJaTextBox
                        label={OIDC + INTEG_TEXT_BOX}
                        placeholder={OIDC}
                        name="oidcEndpoint"
                        value={getForm().oidcEndpoint}
                        containerStyle={{fontSize: 14}}
                        onChange={callUpdateFunction}
                    />
                  </Form.Group>
                  <Form.Group>
                    <div className="form-label">JWKS Endpoint</div>
                    <YuJaTextBox
                        label={JWKS + INTEG_TEXT_BOX}
                        placeholder={JWKS}
                        name="jwksEndpoint"
                        value={getForm().jwksEndpoint}
                        containerStyle={{fontSize: 14}}
                        onChange={callUpdateFunction}
                    />
                  </Form.Group>
                  <Form.Group>
                    <div className="form-label">OAuth2 Token Endpoint</div>
                    <YuJaTextBox
                        label={OAUTH + INTEG_TEXT_BOX}
                        placeholder={OAUTH}
                        name="oAuth2Endpoint"
                        value={getForm().oAuth2Endpoint}
                        containerStyle={{fontSize: 14}}
                        onChange={callUpdateFunction}
                    />
                  </Form.Group>
                  <Form.Group>
                    <div className="form-label">Deployment Id</div>
                    <YuJaTextBox
                        placeholder={DEPLOYMENT}
                        name="deploymentId"
                        value={getForm().deploymentId}
                        containerStyle={{fontSize: 14}}
                        onChange={callUpdateFunction}
                    />
                  </Form.Group>
                </>
            }
            {loading &&
                <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <Skeleton height={37} borderRadius={10} inline={true} style={{marginTop: 42}}/>
                    <Skeleton height={37} borderRadius={10} inline={true} style={{marginTop: 42}}/>
                    <Skeleton height={37} borderRadius={10} inline={true} style={{marginTop: 42}}/>
                    <Skeleton height={37} borderRadius={10} inline={true} style={{marginTop: 42}}/>
                    <Skeleton height={37} borderRadius={10} inline={true} style={{marginTop: 42}}/>
                    <Skeleton height={37} borderRadius={10} inline={true} style={{marginTop: 42}}/>
                  </div>
                </SkeletonTheme>
            }
          </Form>
        </Card.Body>
      </Card>
      <div style={{display: "flex", flexWrap: "wrap", gap: 20, flex: 1, minWidth: 400, height: "fit-content"}}>
        <Card className={styles.smallBox}>
          <Card.Header>
            <img src={userProvisioning} alt="User Provisioning" width={"24px"} height={"24px"}/>
            <span tabIndex={0} role={TEXTBOX} className={styles.cardHeader}>{USER_CONFIG}</span>
          </Card.Header>
          <Card.Body>
            <Form>
              {!loading &&
                  <>
                    <Form.Group controlId="autoProvisionUser" className={styles.toggleRow}>
                      <div className={styles.toggleTextRow}>
                        <div tabIndex={0}>{AUTO_PROVISION_USER}</div>
                        <div tabIndex={0} className={styles.toggleDescriptionText}>{AUTO_PROVISION_USER_DESC}</div>
                      </div>
                      <YuJaToggle name="autoProvisionUser" disabled={true} toggled={true} onClick={callUpdateFunction}/>
                    </Form.Group>

                    <Form.Group controlId="autoSyncData" className={styles.toggleRow}>
                      <div className={styles.toggleTextRow}>
                        <div tabIndex={0}>{AUTO_SYNC_USER}</div>
                        <div tabIndex={0} className={styles.toggleDescriptionText}>{AUTO_SYNC_USER_DESC}</div>
                      </div>
                      <YuJaToggle name="autoSyncData" toggled={getForm().autoSyncData} onClick={callUpdateFunction}/>
                    </Form.Group>
                  </>
              }
              {loading &&
                  <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                    <div style={{display: "flex", flexDirection: "column"}}>
                      <Skeleton height={37} borderRadius={10} inline={true} style={{marginTop: 5}}/>
                      <Skeleton height={37} borderRadius={10} inline={true} style={{marginTop: 5}}/>
                      </div>
                   </SkeletonTheme>
                }
              </Form>
          </Card.Body>
        </Card>
        <Card className={styles.smallBox}>
          <Card.Header>
              <img src={generalIcon} alt="CourseConfig" width={"24px"} height={"24px"} />
              <span tabIndex={0} role={TEXTBOX} className={styles.cardHeader}>{COURSE_CONFIG}</span>
          </Card.Header>
          <Card.Body>
              <Form>
              {!loading && 
               <>
                 <Form.Group controlId="autoProvisionCourse" className={styles.toggleRow}>
                   <div className={styles.toggleTextRow}>
                     <div tabIndex={0}>{AUTO_PROVISION_COURSE}</div>
                     <div tabIndex={0} className={styles.toggleDescriptionText}>{AUTO_PROVISION_COURSE_DESC}</div>
                   </div>
                   <YuJaToggle name="autoProvisionCourse" toggled={true} onClick={callUpdateFunction} disabled={true}/>
                 </Form.Group>
                 <Form.Group controlId="autoSyncCourseData" className={styles.toggleRow}>
                   <div className={styles.toggleTextRow}>
                     <div tabIndex={0}>{AUTO_SYNC_COURSE}</div>
                     <div tabIndex={0} className={styles.toggleDescriptionText}>{AUTO_SYNC_COURSE_DESC}</div>
                   </div>
                   <YuJaToggle name="autoSyncCourseData" toggled={getForm().autoSyncCourseData} onClick={callUpdateFunction}/>
                 </Form.Group>
               </>
              }
                {
                    loading && <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                      <div style={{display: "flex", flexDirection: "column"}}>
                      <Skeleton height={37} borderRadius={10} inline={true} style={{marginTop: 5}}/>
                            <Skeleton height={37} borderRadius={10} inline={true} style={{marginTop: 5}}/>
                            </div>
                            </SkeletonTheme>
                        }
              </Form>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}
