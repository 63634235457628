import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import FeedBackModal from "../../components/modals/FeedBackModal";
import YuJaButton from "../../components/standardization/YuJaButton";
import { Spin } from "../../components/standardization/YuJaLoading";
import useMobileAccess from "../../hooks/useMobileAccess";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import { useLoading } from "../../utils/LoadingContext";
import {
    BUTTON,
    CLOSE_MODAL,
    ENDED_POLL_TEXT,
    FEEDBACK_BUTTON_TEXT,
    NO_TEXT,
    REATTEMPT_MODAL_BODY,
    REATTEMPT_MODAL_TITLE,
    RETRY_TEXT,
    SEE_RESPONSES_BUTTON_TEXT,
    TEXTBOX,
    UNLIMITED_ATTEMPTS,
    USER_COMPLETE_MERGED_RESPONSES, USER_COMPLETE_POLL_REATTEMPT_SUBTITLE,
    USER_COMPLETE_POLL_SUBTITLE,
    YES_TEXT, YOU_ARE_ALL_DONE_TEXT
} from "../../utils/constants";
import { newPageLog, removePrefix } from "../../utils/helpers";
import ClassResultAllMerged from "./ClassResultAllMerged";
import thankYou from "./animations/PurpleThankYou.json";
import "./styles.css";
import styles from "./styles.module.css";
import PollTitleContainer from "./PollTitleContainer";
import Lottie from "react-lottie";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function UserCompleteMergedPoll({
    stopped = false,
    websocketState,
    handleReattempt,
    allowReattempt,
    attemptsCount,
    maxAttempts,
    pollTitle,
    currentPoll,
    institutionId,
    userName,
    pollInfo, 
    pollEndTime,
    handlePollComplete
}) {
    const windowDimensions = useWindowDimensions();

    const getImageSize = () => {
        let {width, height} = windowDimensions;
        let adjustedWidth = Math.min(isNarrow ? width * 0.4 : width * 0.3, 300);
        let adjustedHeight = Math.min(isNarrow ? height * 0.4 : height * 0.3, 300);

        return Math.min(adjustedWidth, adjustedHeight);
    }



    const isNarrow = useMobileAccess(1200);
    const isMobile = useMobileAccess(480);
    const { loading } = useLoading();
    const [showClassResult, setShowClassResult] = useState(false);
    const [canReattempt, setCanReattempt] = useState(false);
    const [feedbackModalShow, setFeedBackModalShow] = useState(false);
    const [showReattemptModal, setShowReattemptModal] = useState(false);
    const [imageSize, setImageSize] = useState(() => getImageSize());

    document.getElementById('root').style.backgroundImage = "none";

    useEffect(() => {
        newPageLog("UserCompletePoll.js");
        if (isNarrow && document.getElementById("viewer-navbar-container") !== null) {
            document.getElementById("viewer-navbar-container").style.backgroundColor = "#FCFBFF";
        }

        document.body.classList.add("waiting-room-background");
        return () => {
            document.body.classList.remove("waiting-room-background");
        };
    }, []);

    useEffect(() => {
        setCanReattempt(allowReattempt && (maxAttempts === UNLIMITED_ATTEMPTS || attemptsCount < maxAttempts));
    }, [allowReattempt, attemptsCount, maxAttempts]);

    const onClickReattempt = () => {
        setShowReattemptModal(true);
    };

    const handleClose = () => {
        setShowReattemptModal(false);
    };

    useEffect(() => {
        if (stopped) {
            setShowReattemptModal(false);
        }
    }, [stopped]);

    useEffect(() => {
        const now = Date.now();
        if (now > pollEndTime) {
            handlePollComplete();
        }
    }, [handlePollComplete, pollEndTime]);

    useEffect(() => {
        setImageSize(getImageSize());
    }, [windowDimensions, getImageSize]);


    return (
        <div style={{ height: "100%" }}>
            <FeedBackModal
                show={feedbackModalShow}
                setModalShow={setFeedBackModalShow}
                pollKey={removePrefix(currentPoll.questions[0].pollKey)}
                pollUniqueCode={currentPoll.questions[0].pollSortKey.split("#")[2]}
                userId={userName}
                institutionId={institutionId}
            />
            <Modal
                id="reattempt-modal"
                show={showReattemptModal}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                backdrop={loading ? "static" : true}
                dialogClassName="sa-modal"
            >
                <Spin tip="Loading..." size="large" spinning={loading}>
                    <ModalClose tabIndex={0} role={BUTTON} aria-label={CLOSE_MODAL} onClick={handleClose} className="modal-close" />
                    <Modal.Header style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Modal.Title tabIndex={0} role={TEXTBOX}>
                            {REATTEMPT_MODAL_TITLE}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body tabIndex={0} role={TEXTBOX}>
                        {REATTEMPT_MODAL_BODY}
                    </Modal.Body>
                    <Modal.Footer>
                        <YuJaButton type="secondary" onClick={handleClose}>
                            {NO_TEXT}
                        </YuJaButton>
                        <YuJaButton onClick={handleReattempt}>
                            {YES_TEXT}
                        </YuJaButton>
                    </Modal.Footer>
                </Spin>
            </Modal>
            {showClassResult &&
                <ClassResultAllMerged
                    currentPoll={currentPoll}
                    institutionId={institutionId}
                    websocketState={websocketState}
                    pollInfo={pollInfo}
                    pollTitle={pollTitle}
                    userName={userName}
                />
            }
            {!showClassResult &&
                <>
                <div style={{ position: "fixed", height: "100vh", backgroundColor: "#FCFBFF", width: "100vw", zIndex: "-1" }} />
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        alignItems: "center",
                        padding: "min(5%, 30px)",
                    }}>
                        <PollTitleContainer pollTitle={pollTitle} websocketState={websocketState}/>
                        <div style={{flex: 1}}/>
                        <Lottie
                            options={{
                                loop: false,
                                autoplay: true,
                                animationData: thankYou,
                                rendererSettings: {preserveAspectRatio: "xMidYMid slice"}
                            }}
                            height={imageSize}
                            width={imageSize}
                            isClickToPauseDisabled={true}
                        />

                        <span tabIndex={0} role={TEXTBOX} className={styles.emptyBoxTitle}>
                            {stopped ? ENDED_POLL_TEXT : YOU_ARE_ALL_DONE_TEXT}
                        </span>
                        <span style={{height: 20}}/>
                        <span tabIndex={0} role={TEXTBOX} className={styles.emptyBoxDesc}>
                            {stopped && USER_COMPLETE_MERGED_RESPONSES}
                            {!stopped && canReattempt && USER_COMPLETE_POLL_REATTEMPT_SUBTITLE}
                            {!stopped && !canReattempt && USER_COMPLETE_POLL_SUBTITLE}
                        </span>

                        {stopped &&
                            <YuJaButton
                                style={{fontSize: 14, padding: "8px, 10px, 8px, 10px", marginTop: 40}}
                                id="sub-btn-curr"
                                onClick={() => setShowClassResult(true)}
                            >
                                {SEE_RESPONSES_BUTTON_TEXT}
                            </YuJaButton>
                        }
                        {canReattempt && !stopped &&
                            <YuJaButton
                                onClick={onClickReattempt}
                                style={{
                                    fontSize: 14,
                                    padding: "8px, 10px, 8px, 10px",
                                    width: 125,
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: 40
                                }}
                            >
                                {RETRY_TEXT}
                            </YuJaButton>
                        }


                        <div style={{flex: 3}}/>

                        <YuJaButton
                            style={{position: "fixed", right: 0,
                                transform: "translate(50%, 50%) rotate(-90deg)",
                                transformOrigin: "bottom",
                                top: "calc(80% - 90px)"}}
                            type={"secondary"}
                            onClick={() => setFeedBackModalShow(true)}
                        >
                            {FEEDBACK_BUTTON_TEXT}
                        </YuJaButton>
                    </div>
                </>
            }
        </div>
    );
}