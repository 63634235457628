import {useEffect, useState} from "react";
import styles from "../layouts/Layout.module.css";
import {BUTTON} from "../utils/constants";
import { ReactComponent as Down } from "../images/links/active_down.svg";
import { ReactComponent as Up } from "../images/links/active_up.svg";
import { ReactComponent as ActiveDown } from "../images/links/down.svg";
import { ReactComponent as ActiveUp } from "../images/links/up.svg";
import {useHistory, useLocation} from "react-router-dom";

export default function CollapsibleMenu({menu={}, sideBarCollapsed=true, setSideBarCollapsed}) {
    const { pathname, state } = useLocation();
    let history = useHistory();
    const { path, altPath, subStates={}} = menu;

    const [collapsed, setCollapsed] = useState(true);

    useEffect(() => {
        if (!isActive()) {
            setCollapsed(true);
        }

    }, [pathname, state]);

    const isActive = () => {
        return pathname === path || (!!altPath && pathname.includes(altPath));
    }

    const isSubActive = (pollType) => {
        return isActive() && state?.pollType === pollType;
    }

    const isCurrentMenuItem = (pollType) => {
       return pathname === path && pollType === state?.pollType;
    }

    return (
        <div className={!!collapsed ? styles.collapsedMenuContainer : styles.expandedMenuContainer}>
            <div
                tabIndex={0}
                aria-label={menu.name}
                role={BUTTON}
                onClick={() => setCollapsed(!collapsed)}
                className={isActive() && collapsed ? styles.activeSideBarLink : styles.sidebarLink}
                style={{display: "flex", justifyContent: "space-between"}}
            >
                {!sideBarCollapsed &&
                    <div>
                        <img
                            src={isActive() ? menu.activeIcon : menu.icon}
                            style={{width: "50px", height: "18px", marginRight: "5px"}}
                            alt={`${menu.name} icon`}
                            className={isActive() && !!collapsed ? styles.activeSubIcon : ""}
                        />
                        <span className={styles.sidebarText}>{menu.name}</span>
                    </div>
                }
                {sideBarCollapsed &&
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%"}}>
                        <img
                            src={isActive() ? menu.activeIcon : menu.icon}
                            style={{height: "18px"}}
                            alt={`${menu.name} icon`}
                            className={isActive() && !!collapsed ? styles.activeSubIcon : ""}
                        />
                    </div>
                }
                {!sideBarCollapsed && !collapsed && !isActive() &&
                    <Up style={{width: "18px", cursor: "pointer", marginRight: 8}}/>
                }
                {!sideBarCollapsed && !collapsed && isActive() &&
                    <ActiveUp style={{width: "18px", cursor: "pointer", marginRight: 8}}/>
                }

                {!sideBarCollapsed && collapsed && !isActive() &&
                    <Down style={{width: "18px", cursor: "pointer", marginRight: 8}}/>
                }
                {!sideBarCollapsed && collapsed && isActive() &&
                    <ActiveDown style={{width: "18px", cursor: "pointer", marginRight: 8}}/>
                }
            </div>
            {!collapsed &&
                <div className={styles.createOptionWrap}>
                    {Object.keys(subStates).map((key, idx) => {
                        const {icon, activeIcon, name} = subStates[key];
                        return (
                            <span
                                key={idx}
                                tabIndex={0}
                                onClick={() => {
                                    if (!isCurrentMenuItem(key)) {
                                        setSideBarCollapsed(true);
                                    }
                                    history.push({
                                        pathname: path,
                                        state: {
                                            pollType: key,
                                            fromNavbar: true,
                                            previous: pathname
                                        }
                                    });
                                }}
                                className={isSubActive(key) ? styles.activeCreateOption : styles.createOption}
                            >

                                {!sideBarCollapsed &&
                                    <div>
                                        <img
                                            src={isActive() ? activeIcon : icon}
                                            style={{width: "50px", height: 18, marginRight: "5px"}}
                                            alt={`${name} icon`}
                                            className={isSubActive(key) ? styles.activeSubIcon : ""}
                                        />
                                        <span className={styles.sidebarText}>{name}</span>
                                    </div>
                                }
                                {sideBarCollapsed &&
                                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%"}}>
                                        <img
                                            src={isActive(key) ? activeIcon : icon}
                                            style={{height: 18}}
                                            alt={`${name} icon`}
                                            className={isSubActive() ? styles.activeSubIcon : ""}
                                        />
                                    </div>
                                }

                                {/*<img*/}
                                {/*    src={isSubActive(key) ? activeIcon : icon}*/}
                                {/*    style={!sideBarCollapsed ? {width: 50, height: 18, marginRight: 5} : {width: 32, height: 18}}*/}
                                {/*    alt={`${menu.name} icon`}*/}
                                {/*    className={isSubActive(key) ? styles.activeSubIcon : ""}*/}
                                {/*/>*/}
                                {/*{!sideBarCollapsed &&*/}
                                {/*    <span className={styles.sidebarText}>{name}</span>*/}
                                {/*}*/}
                            </span>
                        );
                    })}
                </div>
            }
        </div>
    );

}