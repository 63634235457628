import React, {useEffect, useRef, useState} from "react";
import {
    CREATE_QUESTION_TUTORIAL_DESC,
    HOW_TO_USE_TEXT,
    QUESTION_TYPES, WATCH_VIDEO_TEXT
} from "../utils/constants";
import {ReactComponent as BULB} from "../images/LightBulb.svg";
import {ReactComponent as VIDEO} from "../images/video.svg";
import {ReactComponent as EXTERNAL} from "../images/ExtenrnalIcon.svg";
import {useYuJaEventBus} from "../components/standardization/YuJaEventBusProvider";
import classes from "../pages/Report/Report.module.css";
import Skeleton from "react-loading-skeleton";
import useWindowDimensions from "../hooks/useWindowDimensions";

export default function CreateQuestionGuidance({questionType="MCSS"}) {
    const URL_MAPPING = {
        MCSS: "https://demo.video.yuja.com/V/Video?v=11072575&node=49127356&a=166285668",
        SA: "https://demo.video.yuja.com/V/Video?v=11072578&node=49127359&a=157824720",
        TF: "https://demo.video.yuja.com/V/Video?v=11072580&node=49127368&a=124698386",
        FITB: "https://demo.video.yuja.com/V/Video?v=11072584&node=49127390&a=175292728",
        CI: "https://demo.video.yuja.com/V/Video?v=11072569&node=49127314&a=32818730",
        WC: "https://demo.video.yuja.com/V/Video?v=11072570&node=49127330&a=175750107",
        MH: "https://demo.video.yuja.com/V/Video?v=11072571&node=49127331&a=181153166",
        RK: "https://demo.video.yuja.com/V/Video?v=11072573&node=49127333&a=60802832",
        OE: "https://demo.video.yuja.com/V/Video?v=11072574&node=49127341&a=154067260",
    }

    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    const linkRef = useRef();
    const containerRef = useRef();
    const { addClickEventHandler, removeClickEventHandler } = useYuJaEventBus();
    let {width: windowWidth} = useWindowDimensions();
    const [isOnLeft, setIsOnLeft] = useState(true);
    const [guidanceWindowWidth, setGuidanceWindowWidth] = useState(500);


    useEffect(() => {
        const handleGlobalClick = (event) => {
            // Check if the click occurred outside of the dropdown
            if (isOpen && containerRef.current && !containerRef.current.contains(event.target) && !linkRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            resetContainerPosition();
        } else {
            setIsLoading(true);
        }

        addClickEventHandler(handleGlobalClick);
        return () => {
            removeClickEventHandler(handleGlobalClick);
        };
    }, [isOpen]);


    useEffect(() => {
        resetContainerPosition();

    }, [windowWidth, linkRef]);

    const resetContainerPosition = () => {
        const guidanceWindowWidth = Math.min(windowWidth * 0.4, 500);
        setGuidanceWindowWidth(guidanceWindowWidth);

        const {left} = linkRef.current.getBoundingClientRect();
        let parentContainer = document.getElementsByClassName("create-question-body")[0];
        let {left: parentLeft, width: parentWidth} = parentContainer.getBoundingClientRect();

        setIsOnLeft(left + guidanceWindowWidth + 50 <= parentLeft + parentWidth);
    }

    return (
        <div className="create-question-guidance-container">
            <BULB style={{marginRight: 3}}/>
            <span>{`${HOW_TO_USE_TEXT}: ${QUESTION_TYPES[questionType].detail}`}</span>
            <span
                onLoad={resetContainerPosition}
                ref={linkRef}
                className="fitb-desc"
                style={{ marginLeft: 3,  alignItems: "center", position: "relative", whiteSpace: "nowrap"}}
                onClick={() => setIsOpen(true)}
            >
                <VIDEO /> <span style={{textDecoration: "underline", color: "#0B6F85", cursor: "pointer", fontStyle: "normal"}}>{WATCH_VIDEO_TEXT}</span>
                {isOpen &&
                    <div
                        className={`create-question-guidance ${isOnLeft ? "question-guidance-left" : "question-guidance-right"}`}
                        ref={containerRef}
                        style={{width: guidanceWindowWidth}}
                    >
                        <div className="create-question-guidance-video-container">
                            <div className="create-question-guidance-body">
                                <div className="create-question-guidance-row">
                                <span
                                    className="create-question-guidance-title">{QUESTION_TYPES[questionType].description}</span>
                                </div>
                                <div className="create-question-guidance-row">
                                <span style={{color: "#42296E", fontWeight: "400", whiteSpace: "normal"}}>
                                    {CREATE_QUESTION_TUTORIAL_DESC}
                                </span>
                                    <EXTERNAL style={{cursor: "pointer", fill: "#38225E"}}
                                              onClick={() => window.open(URL_MAPPING[questionType], "blank")}/>
                                </div>
                            </div>
                            {isLoading &&
                            <div style={{
                                width: "100%",
                                height: guidanceWindowWidth * 9 / 16,
                                display: "flex",
                                flexDirection: "column",
                                padding: 20,
                                gap: 20
                            }}>
                                <div style={{flex: 1}}/>
                                <Skeleton borderRadius={10} height={25} inline={true} width={"20%"}
                                          className={classes.cardNumberSkeleton}
                                          containerClassName={classes.cardNumberSkeletonWrapper}
                                />
                                <Skeleton borderRadius={10} height={25} inline={true} width={"30%"}
                                          className={classes.cardNumberSkeleton}
                                          containerClassName={classes.cardNumberSkeletonWrapper}
                                />
                                <Skeleton borderRadius={10} height={25} inline={true} width={"50%"}
                                          className={classes.cardNumberSkeleton}
                                          containerClassName={classes.cardNumberSkeletonWrapper}
                                />
                                <div style={{flex: 1}}/>
                            </div>
                            }
                            <iframe
                                onLoad={() => {
                                    setTimeout(() => {
                                        setIsLoading(false);
                                    }, 1000)
                                }}
                                allowFullScreen
                                style={{width: "100%", height: guidanceWindowWidth * 9 / 16, display: isLoading ? "none" : "block"}}
                                src={URL_MAPPING[questionType]}
                            />
                        </div>
                    </div>
                }

            </span>

        </div>

    );
}