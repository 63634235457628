import React, { useEffect, useState } from "react";
import { Spin } from "../../components/standardization/YuJaLoading";
import {
    MCSSoptUpper, NO_RESPONSE_FOUND_TEXT,
    POLL_SHARE_MODE,
    POLL_TYPE, RESPONSE_TITLE,
    RESPONSES,

    RESPONSES_SUBTITLE_PREFIX,
    TEXTBOX
} from "../../utils/constants";
import TabParticipantsLive from "./TabParticipantsLive";
import { ReactComponent as ArrowDown } from "../../images/YuJa_arrow_down_icon.svg";
import { ReactComponent as ArrowUp } from "../../images/YuJa_arrow_up_icon.svg";
import { ReactComponent as NoResponsesIcon } from "./images/NO_RESPONSES.svg";
import { ReactComponent as ShiftNext } from "./images/SHIFT_Q_NEXT.svg";
import { ReactComponent as ShiftPrev } from "./images/SHIFT_Q_PREV.svg";
import "./styles.css";
import styles from "./SharePollPage.module.css";

export default function ViewResponses({data=[], loading=true, initialized=false, pollData={}, currQues, setCurrQues, localState, collapsed}) {
    // const location = useLocation();
    const [cardCollapsed, setCardCollapsed] = useState(true); // !location?.state?.expandedAnalytics
    const [chartData, setChartData] = useState({});
    const gradeEnabled = currQues <= pollData?.questions?.length ? !!pollData?.questions[currQues - 1]?.weightage : false;

    useEffect(() => {
        if (data.length >= currQues) {
            setChartData(data[currQues - 1]);
        } else {
            setChartData({});
        }
    }, [currQues, data]);

    return(
        <div
            className={styles.panelCard}
            style={{
                maxHeight: !cardCollapsed ? 300 : 48,
                overflow: "hidden"
            }}
        >
            <div className={styles.panelCardHeader} onClick={() => setCardCollapsed(!cardCollapsed)}>
                <div className={styles.panelCardHeaderTitle} tabIndex={0} role={TEXTBOX}>{RESPONSE_TITLE}</div>
                {cardCollapsed ?
                    <ArrowDown />
                    :
                    <ArrowUp />
                }
            </div>

            <div className={styles.panelCardBody}>
                <span className={styles.panelCardBodySubTitle} tabIndex={0} role={TEXTBOX}>
                    {RESPONSES_SUBTITLE_PREFIX}{pollData?.poll?.pollType === POLL_TYPE.ATTENDANCE ? "" : MCSSoptUpper[16] + currQues + " "}{RESPONSES}
                </span>
                {!initialized &&
                    <Spin size="large" wrapperClassName="panelSpin" spinning={true} tip="Loading...">
                        <div style={{width: "100%", height: 280}}/>
                    </Spin>
                }
                {initialized && (!chartData || !Object.entries(chartData).length || chartData.total === 0 || !gradeEnabled || localState === "SHARED_ALL") &&
                    <>
                        <NoResponsesIcon style={{flexShrink: 0, alignSelf: "center"}}/>
                        <span className={styles.questionBuilderDefaultLabel} tabIndex={0} role={TEXTBOX} style={{marginTop: 10, alignSelf: "center"}}>{NO_RESPONSE_FOUND_TEXT}</span>
                        {[POLL_SHARE_MODE.SHARE_ALL, POLL_SHARE_MODE.SCHEDULE].includes(pollData?.poll?.pollShareMode) && pollData.questions.length > 1 &&
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }} className="shiftQText">
                                <ShiftPrev style={{marginRight: "10px", cursor: "pointer"}}
                                           onClick={() => setCurrQues(currQues === 1 ? 1 : currQues - 1)}/>Question {currQues}
                                <ShiftNext style={{marginLeft: "10px", cursor: "pointer"}}
                                           onClick={() => setCurrQues(currQues === pollData.questions.length ? currQues : currQues + 1)}/>
                            </div>
                        }
                    </>
                }
                {initialized && (!!chartData && !!Object.entries(chartData).length && chartData.total !== 0) && (!!pollData?.questions && !!pollData?.questions?.length) && gradeEnabled && localState !== "SHARED_ALL" &&
                    <TabParticipantsLive
                        chartData={chartData}
                        minimized={false}
                        pollData={pollData}
                        currQues={currQues}
                        setCurrQues={setCurrQues}
                        localState={localState}
                        collapsed={collapsed}
                    />
                }

            </div>
        </div>
    );
}
