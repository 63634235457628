import React, { useEffect, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import "./styles.css";
import {
  POLL_STATE,
  SCHEDULE_POLL_OPEN_MESSAGE,
  GET_READY_PAGE_TIMER_COUNTER_DESCRIPTION,
  GET_READY_PAGE_TIME_LIMIT_1ST_PART,
  GET_READY_PAGE_TIME_LIMIT_2ND_PART,
  GET_READY_LABEL,
  SECONDS_LABEL,
  TEXTBOX,
  DEFAULT_GET_READY_ALL_TIME_LIMIT
} from "../../utils/constants";
import useMobileAccess from './../../hooks/useMobileAccess';
import {newPageLog} from "../../utils/helpers";
import {ReactComponent as ScheduleLoading} from "../../images/schedule_loading.svg";
import { useCountdown } from "../../hooks/useCountdown";
import waitingRoomBackground from "../../images/waitingRoomBackground.svg";
import waitingRoomBackgroundMobile from "../../images/waitingRoomBackgroundMobile.svg";
import PollTitleContainer from "./PollTitleContainer";

const renderTime = ({ remainingTime }) => {
  if (remainingTime <= 0) {
    document.getElementById("root").style.backgroundImage = "none";
  }


  return (
    <div className="timer">
      <div tabIndex={0} role={TEXTBOX} aria-label={GET_READY_LABEL + (remainingTime-1) + SECONDS_LABEL} className="get-ready-timer-value">{remainingTime - 1}</div>
    </div>
  );
};

const renderTimeNew = ({ remainingTime }) => {
  return (
    <div className="timer">
      <div tabIndex={0} role={TEXTBOX} aria-label={GET_READY_LABEL + (remainingTime - 1 >= 0 ? remainingTime - 1 : 0) + SECONDS_LABEL} className="get-ready-timer-value">{remainingTime - 1 >= 0 ? remainingTime - 1 : 0}</div>
    </div>
  );
};

export default function GetReadyAll({poll, handleGetReadyAllComplete, pollState, pollTitle, websocketState}) {
  const {timeReadyDuration, timeLimit, pollStartTime} = poll;
  // const {timeReadyDuration} = current;
  const isNarrow = useMobileAccess(1200);
  const { getReturnValues, resetCountdown } = useCountdown(new Date(pollStartTime).getTime());
  const [days, hours, minutes, seconds] = getReturnValues; 
  const [showCountDown, setShowCountDown] = useState(false); 

  const [countDownSeconds, setCountDownSeconds ] = useState(null);

  useEffect(() => {
    let getReadyCountdown = poll.getReadyAllTimeLimit ? poll.getReadyAllTimeLimit : DEFAULT_GET_READY_ALL_TIME_LIMIT;
    if(days === 0 && hours === 0 && minutes === 0 && seconds <= getReadyCountdown && pollState.includes(POLL_STATE.SCHEDULED)) {
      if(countDownSeconds === null) {
        setCountDownSeconds(seconds);
      }
      setShowCountDown(true)
    }
    if (days + hours + minutes + seconds <= 0) {
      handleGetReadyAllComplete();
    }
  }, [seconds]);
  const [keyNo, setKeyNo] = useState(0);

  const convertTime = () => {
    if (pollStartTime === null) {
      return ""; 
    }
    const dateTime = new Date(pollStartTime);
    const monthNames = [
      "January", "February", "March",
      "April", "May", "June", "July",
      "August", "September", "October",
      "November", "December"
    ];
    
    const month = monthNames[dateTime.getMonth()];
    const day = dateTime.getDate();
    const year = dateTime.getFullYear();
    
    let hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();
    const period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;

    const timeZone = dateTime.toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];

    return`${month} ${day}, ${year} - ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period} (${timeZone})`;
  }

  useEffect(() => {
    newPageLog("GetReadyAll.js");
    if(isNarrow && document.getElementById("viewer-navbar-container") !== null) {
      document.getElementById("viewer-navbar-container").style.backgroundColor = "#FCFBFF";
    }
    // if(document.getElementById("viewer-navbar") !== null) {
    //   document.getElementById("viewer-navbar").style.backgroundColor = "";
    // }

    // document.body.classList.add("get-ready-background");
    // return () => {
    //   document.body.classList.remove("get-ready-background");
    // };
  }, []);

  let timeLimitMessage = "";
  let tempTimeLimit = timeLimit;

  if (tempTimeLimit >= 3600) {
    if (tempTimeLimit >= 7200) {
      timeLimitMessage += Math.floor(tempTimeLimit / 3600) + " hours";
    } else {
      timeLimitMessage += "1 hour";
    }
    tempTimeLimit = tempTimeLimit % 3600;
    if (tempTimeLimit !== 0) {
      timeLimitMessage += ", ";
    }
  }

  if (tempTimeLimit >= 60) {
    if (tempTimeLimit >= 120) {
      timeLimitMessage +=
        tempTimeLimit % 60 === 0 && timeLimit >= 3600
          ? "and " + Math.floor(tempTimeLimit / 60) + " minutes"
          : Math.floor(tempTimeLimit / 60) + " minutes";
    } else {
      timeLimitMessage +=
        tempTimeLimit % 60 === 0 && timeLimit >= 3600
          ? "and 1 minute"
          : "1 minute";
    }

    tempTimeLimit = tempTimeLimit % 60;
    if (tempTimeLimit !== 0) {
      timeLimitMessage += ", ";
    }
  }

  if (tempTimeLimit >= 1) {
    if (tempTimeLimit >= 2) {
      timeLimitMessage +=
        timeLimit >= 60
          ? "and " + tempTimeLimit + " seconds"
          : tempTimeLimit + " seconds";
    } else {
      timeLimitMessage += timeLimit >= 60 ? "and 1 second" : "1 second";
    }
  }

  useEffect(() => {
    setKeyNo(keyNo + 1);
  }, [timeReadyDuration]);

  useEffect(() => {
    resetCountdown(pollStartTime);
  }, [pollStartTime]);

  return (
    <div
        className="get-ready-container"
        style={{
          display: "flex",
          flexDirection: "column",
          padding: 20,
          backgroundImage: window.innerWidth < 480 ? `url(${waitingRoomBackgroundMobile})` : `url(${waitingRoomBackground})`,
          backgroundPositionX: window.innerWidth >= 480 ? "-310px" : "",
          backgroundPositionY: window.innerWidth >=  480 ? "80px" : "",
          backgroundColor: "#FCFBFF",
          backgroundRepeat: "no-repeat"
        }}
    >
      {!pollState.includes(POLL_STATE.SCHEDULED) &&
        <>
          <PollTitleContainer pollTitle={pollTitle} websocketState={websocketState}/>
          <div className="get-ready-background" style={{marginTop: "min(4%, 50px)"}}>
                <div className="get-ready-sub-title" id="title" style={{marginTop: "30px"}}>
                  <div tabIndex={0} role={TEXTBOX} >{`${GET_READY_PAGE_TIME_LIMIT_1ST_PART} ${timeLimitMessage} ${GET_READY_PAGE_TIME_LIMIT_2ND_PART}`}<br/></div>
                  <div tabIndex={0} role={TEXTBOX} style={{marginTop: "10px"}}>{GET_READY_PAGE_TIMER_COUNTER_DESCRIPTION}</div>
                </div>

                <div className="timer-wrapper" style={{marginTop: "min(4%, 50px)"}}>
                  <CountdownCircleTimer
                    size={isNarrow ? 190 : 256}
                    isPlaying
                    key={"countdown-" + keyNo}
                    duration={timeReadyDuration}
                    colors={[
                      "#0277AD",
                      "#0277AD",
                      "#0277AD",
                      "#0277AD",
                      "#0277AD",
                      "#0277AD",
                      "#0277AD",
                      "#0277AD",
                      "#F1B843",
                      "#F1B843",
                      "#F1B843",
                      "#0A820E",
                    ]}
                    colorsTime={[11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0]}
                    strokeWidth={20}
                  >
                    {renderTime}
                  </CountdownCircleTimer>
                </div>
          </div>
        </>
      }

      {pollState.includes(POLL_STATE.SCHEDULED) &&
          <>
            <PollTitleContainer pollTitle={pollTitle} websocketState={websocketState}/>
            <div className="center" style={{marginTop: "min(4%, 50px)"}}>
              <ScheduleLoading/>
            </div>
            <div tabIndex={0} role={TEXTBOX} className="waiting-room-schedule" style={{marginTop: 10}}>
              {SCHEDULE_POLL_OPEN_MESSAGE + convertTime()}
            </div>
            {showCountDown &&
              <div className="timer-wrapper" style={{marginTop: "min(4%, 50px)"}}>
                <CountdownCircleTimer
                    size={isNarrow ? 190 : 256}
                    isPlaying
                    duration={countDownSeconds}
                    colors={[
                      "#0277AD",
                      "#0277AD",
                      "#0277AD",
                      "#0277AD",
                      "#0277AD",
                      "#0277AD",
                      "#0277AD",
                      "#0277AD",
                      "#F1B843",
                      "#F1B843",
                      "#F1B843",
                      "#0A820E",
                    ]}
                    colorsTime={[11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0]}
                    strokeWidth={20}
                >{renderTimeNew}
                </CountdownCircleTimer>
              </div>
            }
          </>
      }
    </div>
  );
}
