import "bootstrap/dist/css/bootstrap.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Col } from "react-bootstrap";
import Countdown from 'react-countdown';
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import { YuJaDropdown } from "../../components/standardization/YuJaDropdown";
import useMobileAccess from "../../hooks/useMobileAccess";
import { ReactComponent as ArrowRight } from "../../images/arrow-right-question.svg";
import { ReactComponent as COORDINATE } from "../../images/coordiante.svg";
import { ReactComponent as NoResultIllustration } from "../../images/no_result.svg";
import { ReactComponent as ThumbDown } from "../../images/openEndedThumbDown.svg";
import { ReactComponent as ThumbUp } from "../../images/openEndedThumbUp.svg";
import { YuJaGlobalState, useLoading } from "../../utils/LoadingContext";
import {
    BLANK_REGEX,
    BLANK_REGEX_NEW,
    CI_COORDINATE_SIZE,
    DEFAULT_IMAGE_ALT,
    EMPTY_WORD_CLOUD_TEXT,
    FITB_NARRATOR,
    FIXED_ANSWER,
    NO_RESPONSES_TEXT,
    OPEN_ENDED_DISLIKE_SORT,
    OPEN_ENDED_LIKE_SORT,
    OPEN_ENDED_TIME_SORT,
    PARTICIPANT_RESPONSE_TEXT,
    QUESTION_TITLE,
    QUESTION_TYPES,
    SORT_BY_TEXT,
    TEXTBOX,
    UNANSWERED,
} from "../../utils/constants";
import {InitializeQuestionResultCount, newPageLog, stripHTMLTags} from "../../utils/helpers";
import { decodeCIParam, questionCodeToName } from "../../utils/questionUtils";
import ClassResultChart from "./ClassResultChart";
import ClassResultMH from "./ClassResultMH";
import ClassResultRank from "./ClassResultRank";
import FITBResponseModal from "./FITBResponseModal";
import WordCloudResult from "./WordCloudResult";
import "./styles.css";
import parse from "html-react-parser";
import PollTitleContainer from "./PollTitleContainer";

export default function ClassResult({ pollInfo, currentQuestion, handleClassResultComplete, websocketState, sharedQuestionList, questions }) { // , handleSAResult, institutionId, quesShareCount
    const isNarrow = useMobileAccess(1200);
    const isMobile = useMobileAccess(800);
    // const curWindow = useWindowDimensions();
    const { pollKey, userName, pollTitle, showAnswerOnViewer } = pollInfo;
    const { question, classResultEndTime } = currentQuestion;
    const { queTitle, hasBlankRestriction = false, weightage = 0 } = question; // optionsMap, correctAnswers, questionType
    const blankRegex = hasBlankRestriction ? BLANK_REGEX_NEW : BLANK_REGEX;
    const gradeEnabled = !!weightage;
    const { getAllClassResult, getStudentResultByPoll} = ResponseApiCalls();
    const [pollResults, setPollResults] = useState([]);
    const { setLoading } = useLoading();
    const [selectedQuestion, setSelectedQuestion] = useState(question);
    const [dataReady, setDataReady] = useState(false);
    const imageRef = useRef(null);
    const [isImgLoaded, setIsImgLoaded] = useState(false);
    const [modalshow, setModalShow] = useState(false);
    const {isOnline} = useContext(YuJaGlobalState);
    // const [yourAnswer, setYourAnswer] = useState(() => {
    //     return getYourAnswer(pollType, questionType, questionResponse.selectedOption);
    // });
    const unanswered = useRef(0);
    // const intervalRef = useRef(0);

    const [dataLoading, setDataLoading] = useState(false);
    // const [participantCount, setParticipantCount] = useState("-");
    // const [responsesCount, setResponsesCount] = useState("-");
    // const [completionRate, setCompletionRate] = useState("-");
    // const [correctCount, setCorrectCount] = useState("-");
    // const [incorrectCount, setIncorrectCount] = useState("-");
    const [questionData, setQuestionData] = useState(() => {
        let correctAnswers;
        if (question.questionType === QUESTION_TYPES.MCSS.name) {
            correctAnswers = question.correctAnswers[0];
        } else if (question.questionType === QUESTION_TYPES.TF.name || question.questionType === QUESTION_TYPES.MH.name) {
            correctAnswers = question.correctAnswers;
        } else {
            correctAnswers = null;
        }
        return {
            correctAnswers: correctAnswers,
            questionType: question.questionType,
            queTitle: question.queTitle,
            questionResults: InitializeQuestionResultCount(question),
            rawResults: {},
            serialNo: question.serialNo,
            image: question.image,
            imageURL: question.imageURL,
            suffix: question.suffix,
            directLinkEvp: question.directLinkEvp
        };
    });
    const [questionResponses, setQuestionResponses] = useState(null);
    const [isSwitching, setIsSWitching] = useState(false);
    const [openEndedData, setOpenEndedData] =  useState([]);
    const [originalOpenEnded, setOriginalOpenEneded] = useState(null);
    const [sortOption, setSortOption] = useState(OPEN_ENDED_TIME_SORT);

    function removeUnanswered(result) {
        let newResult = [];
        result.forEach((r) => {
            if (r.ans !== UNANSWERED) {
                newResult.push({...r});
            } else {
                unanswered.current = r.people;
            }
        });
        return newResult;
    };

    const onLoad = () => {
        setIsImgLoaded(true);
    }

    const getUnanswered = (result) => {
        if (result == null) {
          return 0;
        }
    
        let unansweredCount = 0; 
        result.forEach((r) => {
            if (r.ans === UNANSWERED) {
                unansweredCount = r.people;
            }
        });
        return unansweredCount;
    
      }

    async function getQuestionsData() {
        setLoading(true);
        const responses = await getStudentResultByPoll(pollKey, questions[0].pollSortKey.split("#")[2], userName);
        setQuestionResponses(responses);
        const results = await getAllClassResult(pollKey, questions[0].pollSortKey.split("#")[2], questions);
        let tempQuestionsData = [];
        for (const result of results) { 
          let serialNo = result.serialNo; 
          const question = questions.find(({serialNo: no}) => parseInt(no) === parseInt(serialNo));
          let correctAnswers;
          if (question.questionType === QUESTION_TYPES.MCSS.name) {
            correctAnswers = question.correctAnswers[0];
          } else if (question.questionType === QUESTION_TYPES.TF.name || question.questionType === QUESTION_TYPES.MH.name || question.questionType === QUESTION_TYPES.CI.name) {
            correctAnswers = question.correctAnswers;
          } else {
            correctAnswers = null;
          }
          let questionType = question.questionType;
          let queTitle = question.queTitle;
          let MCSSelements = [];
          if (questionType === "MCSS") {
            for (let key in question.optionsMap) {
              MCSSelements.push(question.optionsMap[key]);
            }
          }
          let MCSSnum = MCSSelements.length;
          let questionImageURL;
          let isVideo;
          if(!!question.directLinkEvp) {
            questionImageURL = question.directLinkEvp;
            isVideo = true;
          } else {
              questionImageURL = question.imageURL;
              isVideo = false;
          }
  
          let questionData = {
            correctAnswers: correctAnswers,
            questionType: questionType,
            queTitle: queTitle,
            questionResults: result.questionResults,
            serialNo: serialNo,
            MCSSnum: MCSSnum,
            questionImageURL: questionImageURL,
            isVideo: isVideo,
            rawResponse: result.rawResponse
          };
          tempQuestionsData.push(questionData); 
        }
  
        tempQuestionsData.forEach(item => {
          item.unansweredCount = getUnanswered(item.questionResults);
        });
  
        tempQuestionsData.forEach(item => {
          item.questionResults = removeUnanswered(item.questionResults);
        });
  
        setPollResults(tempQuestionsData);
        setDataReady(true);
        setLoading(false);
      }

    useEffect(() => {
        if (!isOnline || websocketState !== WebSocket.OPEN) {
          return;
        }
        setDataLoading(true);
        if (gradeEnabled) {
            setTimeout(() => {
                getQuestionsData();
            }, 3000);
        } else {
            const interval = setInterval(getQuestionsData, 5000);
            return () => interval && clearInterval(interval);
        }
      }, [isOnline, websocketState]);


    // clean up function
    useEffect(() => {
        document.getElementById('root').style.backgroundColor = "#FCFBFF";
        document.getElementById('root').style.backgroundImage = ""
        newPageLog("ClassResult.js");
        if(isNarrow && document.getElementById("viewer-navbar-container") !== null) {
            document.getElementById("viewer-navbar-container").style.backgroundColor = "#FCFBFF";
        }
        return () => { setPollResults([]); }
    }, []);



    useEffect(() => {
        if (pollResults && pollResults.length !== 0) {
            // let incorrectCount = 0;
            let serialNo = selectedQuestion.serialNo;
            const questiondata = pollResults.find(({ serialNo: no }) => parseInt(no) === parseInt(serialNo));
            setQuestionData(questiondata);
            if (selectedQuestion.questionType === QUESTION_TYPES.OE.name && questionData && questionData.rawResponse && questionData.rawResponse.openEnded) {
                setOriginalOpenEneded(questionData.rawResponse.openEnded);
                setOpenEndedData(Object.entries(questionData.rawResponse.openEnded));
                handleSort();
            }
        }
        setDataLoading(false);
    }, [pollResults]);

    useEffect(() => {
        handleSort();
    }, [sortOption])

    const handleSort = () => { 
        if (sortOption === OPEN_ENDED_TIME_SORT && originalOpenEnded) {
            setOpenEndedData(Object.entries(originalOpenEnded));
          } else if (sortOption === OPEN_ENDED_LIKE_SORT && originalOpenEnded && Object.keys(originalOpenEnded).length > 1) {
            let entries = Object.entries(originalOpenEnded);
            entries.sort(([, valueA], [, valueB]) => {
              return valueB["1"] - valueA["1"];
            });
            setOpenEndedData(entries);
          } else if (sortOption === OPEN_ENDED_DISLIKE_SORT && originalOpenEnded && Object.keys(openEndedData).length > 1) {
            let entries = Object.entries(originalOpenEnded);
            entries.sort(([, valueA], [, valueB]) => {
              return valueB["2"] - valueA["2"];
            });
            setOpenEndedData(entries);
          }
    }

    
      const removeSelected = (data, target, questionType) => {
        if (questionType === QUESTION_TYPES.SA.name && data) {
        const indexToRemove = data.findIndex(item => item['0'] === target);
        if (indexToRemove !== -1) {
            const filteredList = [
              ...data.slice(0, indexToRemove),
              ...data.slice(indexToRemove + 1),
            ];
      
            return filteredList
          }
        }
        else if (questionType === QUESTION_TYPES.FITB.name && data) {
            const indexToRemove = data.findIndex(item =>
                Object.entries(item).every(([key, value]) => target[key] === value)
              );
          
              if (indexToRemove !== -1) {
                const filteredList = [
                    ...data.slice(0, indexToRemove),
                    ...data.slice(indexToRemove + 1),
                ];
            return filteredList
        }
      }
    }

    useEffect(() => {
        setIsSWitching(true);
        getQuestionsData();
    }, [window.innerWidth])

    return (
        <div style={{display: "flex", flexDirection: "column", width: '100%', height: "100%", padding: 20, gap: 20}}>
            {gradeEnabled && questionData.questionType === QUESTION_TYPES.FITB.name && (
                <FITBResponseModal
                    show={modalshow}
                    setModalShow={setModalShow}
                    content={ questionResponses && questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption ? removeSelected(questionData.rawResponse, questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption, questionData.questionType) : questionData.rawResponse}
                    selectedOption={questionResponses && questionResponses.questions[selectedQuestion.serialNo - 1] && questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption ? questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption : null}
                />
            )}

                <PollTitleContainer pollTitle={pollTitle} websocketState={websocketState}/>
                <div style={{ display: "flex", width:  "100%", gap: isMobile? "8px" : "25px", flexDirection: isMobile? "column" : ""}}>
                    <div tabIndex={0} role={TEXTBOX} style={{ color: "#000",  fontSize: isMobile ? "14px" :"18px", alignItems: "center", display: "flex" }}> Choose Question </div>
                    <YuJaDropdown
                        containerStyle={{width: !isMobile? "40%": "100%" }}
                        data={sharedQuestionList ? questions.filter(question => {
                            const  QuestionList = JSON.parse(sharedQuestionList);
                            return QuestionList.includes(question.serialNo);
                          }) : [question]}
                        value={"Question " + selectedQuestion.serialNo + ": " + stripHTMLTags(selectedQuestion.queTitle)}
                        getOptionLabel={item => "Question " + item.serialNo + ": " + stripHTMLTags(item.queTitle)}
                        getOptionValue={option => "Question " + option.serialNo + ": " + stripHTMLTags(option.queTitle)}
                        textStyle={{ overflow: "hidden", color: "#000",  fontSize: isMobile ? "14px" : "18px", letterSpacing: "0.63px", whiteSpace: "nowrap", textOverflow: 'ellipsis', textWrap: "nowrap", height: "20px", maxWidth: "100%",  paddingTop: isMobile? "2px" : ""}}
                        onChange={(item) => {
                            setDataReady(false);
                            setDataLoading(true)
                            setIsSWitching(true);
                            getQuestionsData();
                            setSelectedQuestion(item);
                        }}
                        style={{height: isMobile? "50px" : "40px"}}
                    />
                </div>

                
                {isMobile && <>
                    <div style={{display: "flex", flexDirection: "column", width: "100%", gap: "15px", flex: "1", overflowY : "auto"}}>
                    <span className="gradientWrapQuesType" style={{ height: "45px", width: "150px", marginLeft: "0px"}}>
                                <span className="questionPanelQuestionTypeContainer" style={{ width: "", padding: "0px", height: "", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <div className="questionPanelQuestionType" role={TEXTBOX} style={{ fontSize: "14px", lineHeight: "normal", display: "flex", alignItems: "center", justifyContent: "center", height: isNarrow? "12px" : ""}}> {questionCodeToName(selectedQuestion.questionType)} </div>
                                </span>
                            </span>

                            <div className="cur-question-title" style={{ fontSize: isMobile ? "14px" : "18px", height: 'fit-content', wordBreak:"break-word"}}>
                                Question {selectedQuestion.serialNo}  {selectedQuestion.questionType !== QUESTION_TYPES.FITB.name ? " - " : ""}
                                {selectedQuestion.questionType !== QUESTION_TYPES.FITB.name && parse(selectedQuestion.queTitle) }
                            </div>
                            {questionData.questionType === QUESTION_TYPES.FITB.name && <p className="ques-title" style={{whiteSpace: "pre-line", wordBreak:"break-word", order: "initial"}}>{ questionData.queTitle.replace(blankRegex, "______")}</p>}

                            {questionData.questionImageURL && questionData.questionType !== QUESTION_TYPES.CI.name && (
                        <div className="img-container" style={{display: 'flex',  justifyContent: 'center', flex: "1", marginRight: 0, padding: "25px"}}>
                            {!questionData.isVideo && <img className="img-class-result" src={questionData.questionImageURL} alt="Question" tabIndex={0} aria-label={selectedQuestion.imageAlt ? selectedQuestion.imageAlt : DEFAULT_IMAGE_ALT}/>}
                            {questionData.isVideo && <iframe allowFullScreen className="img-class-result" src={questionData.questionImageURL} alt="Question"/>}
                        </div>
                    )}

                    {questionData.questionImageURL && questionData.questionType === QUESTION_TYPES.CI.name && (
                         <div className="img-container" style={{display: 'flex',  justifyContent: 'center', flex: "1", marginRight: 0, padding: "25px"}}>
                         <div style={{position: "relative"}}>
                             <img ref={imageRef} className="img-class-result" src={questionData.questionImageURL} tabIndex={0} aria-label={selectedQuestion.imageAlt ? selectedQuestion.imageAlt : DEFAULT_IMAGE_ALT} alt="Question Image" onLoad={onLoad} />
                             {isImgLoaded && questionResponses !== null && imageRef.current !== null
                                 && !!questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption
                                 && questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption.hasOwnProperty("x")
                                 && questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption.hasOwnProperty("y")
                                 &&
                                 <COORDINATE
                                     style={{
                                         position: "absolute",
                                         top: `${decodeCIParam(questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption.y, imageRef.current.height) - CI_COORDINATE_SIZE.height}px`,
                                         left: `${decodeCIParam(questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption.x, imageRef.current.width) - CI_COORDINATE_SIZE.width / 2}px`
                                     }}
                                 />
                             }
                         </div>
                     </div>
                    )}

                    {(questionData.questionType !== QUESTION_TYPES.WC.name && questionData.questionType !== QUESTION_TYPES.RK.name && questionData.questionType !== QUESTION_TYPES.OE.name && questionData.questionType !== QUESTION_TYPES.SA.name && questionData.questionType !== QUESTION_TYPES.FITB.name && (questionData.questionType !== QUESTION_TYPES.MH.name || gradeEnabled)) && (
                        <>
                            <ClassResultChart
                                correctAnswers={questionData.correctAnswers}
                                chartData={questionData.questionType === QUESTION_TYPES.RK.name ? questionData.rawResponse : questionData.questionResults}
                                questionType={questionData.questionType}
                                numOptions={questionData.questionType === QUESTION_TYPES.MCSS.name || questionData.questionType === QUESTION_TYPES.CI.name
                                    ? pollResults.length : 3}
                                gradeEnabled={gradeEnabled}
                                showAnswerOnViewer={showAnswerOnViewer}
                                queResponse={questionResponses ? questionResponses.questions[selectedQuestion.serialNo - 1] : []}
                                question={selectedQuestion}
                                isLoading={dataLoading}
                            />
                        </>
                    )}

                    {/* {(!dataReady &&
                        questionData.questionType !== QUESTION_TYPES.WC.name && questionData.questionType !== QUESTION_TYPES.SA.name && 
                        !(pollType === POLL_TYPE.SURVEY && (questionData.questionType === QUESTION_TYPES.FITB.name))) && (
                        <div className="loading-text-back">
                            <p className="loading-text">Class Results Loading...</p>
                        </div>
                    )} */}

                    {questionData.questionType === QUESTION_TYPES.WC.name && (
                        <WordCloudResult
                            data={questionData.rawResponse}
                            // pollType={pollType}
                            // correctAnswers={correctAnswers}
                            isSwitching={isSwitching}
                            setIsSWitching={setIsSWitching}
                        />
                    )}

                    {questionData.questionType === QUESTION_TYPES.RK.name && (
                        <ClassResultRank chartData={questionData.rawResponse ? questionData.rawResponse.responses : questionData.rawResponse}  isLoading={dataLoading}/>
                    )}

                    {(questionData.questionType === QUESTION_TYPES.SA.name &&
                    <>
                        <div style={{width: selectedQuestion.questionImageURL? "50%" : "100%", marginBottom: "40px"}}>
                            <>
                                <Col style={{maxHeight:"300px", overflowY: "auto"}}>
                                {dataReady && questionResponses && questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption && questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption !=="unanswered" &&
                                    <>
                                    <div
                                            className= {`questionPanelAnswersText resultAnimation`}
                                            style={{
                                                marginTop: "20px",
                                                backgroundColor: "#C4BDD2",
                                                color: "#000", borderRadius: "10px",
                                                width: "fit-content",
                                                padding: "15px"
                                            }}
                                        >
                                        {`"${questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption["0"]}"`}
                                        </div> 
                                    
                                    {removeSelected(questionData.rawResponse,questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption["0"], questionData.questionType)?.map((item) =>
                                        item !== "unanswered" ? (
                                        <div
                                            tabIndex={0} 
                                            className= {`questionPanelAnswersText resultAnimation`}
                                            style={{
                                                marginTop: "20px",
                                                backgroundColor: "#CACACA",
                                                color: "#000", borderRadius: "10px",
                                                width: "fit-content",
                                                padding: "15px"
                                            }}
                                        >
                                            {`"${item["0"]}"`}
                                        </div>
                                        ) : <></>
                                    )}
                                    </> 
                                 }

                                 {questionResponses && (!questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption ||  questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption ==="unanswered")&& 
                                  <>{questionData.rawResponse?.map((item) =>
                                    item !== "unanswered" ? (
                                    <div
                                        className= {`questionPanelAnswersText resultAnimation`}
                                        style={{
                                            marginTop: "20px",
                                            backgroundColor: "#CACACA",
                                            color: "#000", borderRadius: "10px",
                                            width: "fit-content",
                                            padding: "15px"
                                        }}
                                    >
                                        {`"${item["0"]}"`}
                                    </div>
                                    ) : <></>
                                )}</>
                                 }

                                {(!questionData.rawResponse || !questionData.rawResponse.filter(item => item !== FIXED_ANSWER.UNANSWERED).length) &&
                                      <div style={{
                                        color: "grey",
                                        fontSize: 20,
                                        height: "300px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                        }}> {EMPTY_WORD_CLOUD_TEXT} </div>
                                    }
                                </Col>
                            </>
                        </div>
                    </>
                    )}

                    {((questionData.questionType === QUESTION_TYPES.OE.name) && <><div><Col>
                        {dataReady && questionData?.rawResponse?.responses && Object.entries(questionData.rawResponse.responses).length && openEndedData.filter(entry => questionData.rawResponse.responses[entry[0]] && questionData.rawResponse.responses[entry[0]] !== FIXED_ANSWER.UNANSWERED).length != 0 && <>
                            <div style={{ backgroundColor: "#FFFFFF", border: "1px solid #E8EDF1", boxShadow: "0px 0px 4px 0px #00000040", borderRadius: "7.39px", padding: 20, width: "100%", maxHeight: "500px", overflowY: "auto" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                                    <div style={{ fontSize: 16, fontWeight: 600 }}> {PARTICIPANT_RESPONSE_TEXT} </div>
                                    <div style={{ display: "flex", gap: "5px", alignItems: "center", justifyContent: "end" }}>
                                        <div style={{ fontSize: 15, color: "#0000000" }}> {SORT_BY_TEXT}</div>
                                        <YuJaDropdown
                                            data={[OPEN_ENDED_TIME_SORT, OPEN_ENDED_LIKE_SORT, OPEN_ENDED_DISLIKE_SORT]}
                                            getOptionLabel={item => item}
                                            getOptionValue={item => item}
                                            onChange={option => setSortOption(option)}
                                            value={sortOption}
                                            containerStyle={{ marginRight: 15, width: 130 }}
                                            style={{ padding: "8px 15px" }}
                                        />
                                    </div>
                                </div>
                                {openEndedData.map(([key, value], index) => (<>
                                    {questionData.rawResponse.responses[key] && questionData.rawResponse.responses[key] !== FIXED_ANSWER.UNANSWERED &&
                                        <div
                                            style={{
                                                marginTop: "20px",
                                                border: "1px solid #E8EDF1",
                                                color: "#000", borderRadius: "7.4px",
                                                minHeight: '94px', padding: 10, wordBreak: "break-word", boxShadow: "0px 0px 4px 0px #00000040"
                                            }}
                                        >
                                            {`${questionData.rawResponse.responses[key]["0"]}`}
                                            <div style={{ borderRadius: "200px", gap: "20px", backgroundColor: "#ECEAF1", display: "flex", justifyContent: "space-between", height: 35, width: 135, marginTop: 10, padding: "5px 20px" }}>
                                                <div style={{ display: "flex", gap: '5px' }}>

                                                    <div>  <ThumbUp /></div>
                                                    <div style={{ fontSize: 15, fontWeight: 500, color: "#4D4D4D" }}> {value["1"]} </div>
                                                </div>

                                                <div style={{ display: "flex", gap: '5px' }}>
                                                    <div> <ThumbDown /></div>
                                                    <div style={{ fontSize: 15, fontWeight: 500, color: "#4D4D4D" }}> {value["2"]} </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>)
                                )}
                            </div>
                        </>}
                        {dataReady && (!questionData?.rawResponse?.responses || !Object.entries(questionData.rawResponse.responses)?.length || !openEndedData.filter(entry => questionData.rawResponse.responses[entry[0]] && questionData.rawResponse.responses[entry[0]] !== FIXED_ANSWER.UNANSWERED).length) &&
                            <div style={{ backgroundColor: "#FFFFFF", border: "1px solid #E8EDF1", boxShadow: "0px 0px 4px 0px #00000040", borderRadius: "7.39px", padding: 20, width: "100%", maxHeight: "500px", overflowY: "auto" }}>
                                <div style={{ width: "100%", height: "100%", justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center", gap: 5 }}>
                                    <NoResultIllustration width={192} height={100} />
                                    <div style={{ fontSize: 18, marginTop: 10, fontWeight: 600 }}>
                                        {NO_RESPONSES_TEXT}
                                    </div>
                                </div>
                            </div>
                        }
                    </Col></div></>)}

                    {questionData.questionType === QUESTION_TYPES.FITB.name && <>
                        <div style={{width: selectedQuestion.questionImageURL? "50%" : "100%" }}>
                            <>
                                <Col style={{maxHeight:"300px", overflowY: "auto"}}>
                                {dataReady && questionResponses && questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption && questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption !=="unanswered" &&
                                <>
                                 <div
                                        className={`questionPanelAnswersText resultAnimation`}
                                        style={{
                                            marginTop: "20px",
                                            backgroundColor: "#C4BDD2",
                                            color: "#000", borderRadius: "10px",
                                            width: "fit-content",
                                            padding: "15px",
                                        }}
                                        tabIndex={0}
                                    >
                                        {Object.keys(questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption).map((key) => `"${questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption[key]}"`).join(", ")}
                                </div>
                                <div>
                                {removeSelected(questionData.rawResponse, questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption, questionData.questionType)?.map((item) =>
                                 item !== "unanswered"? (
                                    <div
                                        className={`questionPanelAnswersText resultAnimation`}
                                        style={{
                                            marginTop: "20px",
                                            backgroundColor: "#CACACA",
                                            color: "#000", borderRadius: "10px",
                                            width: "fit-content",
                                            padding: "15px",
                                        }}
                                        tabIndex={0}
                                    >
                                        {Object.keys(item).map((key) => `"${item[key]}"`).join(", ")}
                                    </div>
                                ) : <></> )}
                                </div>

                                </>
                                    }

                                    {questionResponses && (!questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption ||  questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption ==="unanswered") &&
                                        <>{questionData.rawResponse?.map((item) =>
                                        item !== "unanswered"? (
                                           <div
                                               className={`questionPanelAnswersText resultAnimation`}
                                               style={{
                                                   marginTop: "20px",
                                                   backgroundColor: "#CACACA",
                                                   color: "#000", borderRadius: "10px",
                                                   width: "fit-content",
                                                   padding: "15px",
                                               }}
                                           >
                                               {Object.keys(item).map((key) => `"${item[key]}"`).join(", ")}
                                           </div>
                                       ) : <></> )} </>
                                    
                                    }

                                    {(!questionData.rawResponse || !questionData.rawResponse.filter(item => item !== FIXED_ANSWER.UNANSWERED).length) &&
                                      <div style={{
                                        color: "grey",
                                        fontSize: 20,
                                        height: "300px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                        }}> {EMPTY_WORD_CLOUD_TEXT} </div>
                                    }
                                </Col>
                            </>
                        </div>
                    </>}

                    {questionData.questionType === QUESTION_TYPES.MH.name && !gradeEnabled && (
                        <ClassResultMH
                            chartData={questionData.rawResponse}
                            correctAnswers={questionData.correctAnswers}
                            isLoading={dataLoading}
                            queResponse={questionResponses ? questionResponses.questions[selectedQuestion.serialNo - 1] : []}
                        />
                    )}
                    </div>
                </>
                }

                    {!isMobile && <>
                        <div style={{
                            width: "100%",
                            borderRadius: "8px",
                            background: "#FFF",
                            boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.15)",
                            marginTop: "30px",
                            minHeight: "385px",
                            padding: "22px",
                            marginLeft: "18px",
                            marginRight: "18px",
                            display: "flex",
                            flexDirection: "column"
                        }}>
                            <div className="header-board" style={{display: "flex", alignItems: "center", gap: "10px"}}>
                                <div tabIndex={0} role={TEXTBOX} className="cur-question-title"
                                     style={{fontSize: "18px"}}>Question {selectedQuestion.serialNo}</div>
                                {selectedQuestion.questionType !== QUESTION_TYPES.FITB.name && (
                                    <>
                                        <ArrowRight/>
                                        <p tabIndex={0} aria-label={QUESTION_TITLE + stripHTMLTags(question.queTitle)}
                                           className="ques-desc" style={{
                                            lineHeight: "normal",
                                            color: "#000",
                                            order: "unset",
                                            marginTop: "0px",
                                            flexShrink: "1"
                                        }}>
                                            {parse(selectedQuestion.queTitle)}
                                        </p>
                                    </>
                                )}
                                <div style={{display: "flex", gap: "10px", justifyContent: "flex-end", flex: "1"}}>
                            <span className="gradientWrapQuesType" style={{height: "45px", width: "150px"}}>
                                <span className="questionPanelQuestionTypeContainer" style={{padding: "0px"}}>
                                    <div tabIndex={0} role={TEXTBOX} className="questionPanelQuestionType" style={{
                                        fontSize: "14px",
                                        lineHeight: "normal",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}> {questionCodeToName(selectedQuestion.questionType)} </div>
                                </span>
                            </span>
                                </div>
                            </div>

                            {questionData.questionType === QUESTION_TYPES.FITB.name &&
                                <p
                                    tabIndex={0}
                                    aria-label={queTitle.replace(blankRegex, FITB_NARRATOR)}
                                    className="ques-title"
                                    style={{
                                        whiteSpace: "pre-line",
                                        wordBreak: "break-word",
                                        order: "initial"
                                    }}
                                >{questionData.queTitle.replace(blankRegex, "______")}</p>
                            }

                            <div style={{display: "flex", flex: 1}}>
                                {(questionData.questionType !== QUESTION_TYPES.WC.name && questionData.questionType !== QUESTION_TYPES.SA.name && questionData.questionType !== QUESTION_TYPES.OE.name && questionData.questionType !== QUESTION_TYPES.RK.name && questionData.questionType !== QUESTION_TYPES.FITB.name && (questionData.questionType !== QUESTION_TYPES.MH.name || gradeEnabled)) && (
                                    <div style={{width: "50%"}}>
                                        <ClassResultChart
                                            correctAnswers={questionData.correctAnswers}
                                            chartData={questionData.questionResults}
                                            questionType={questionData.questionType}
                                            numOptions={questionData.questionType === QUESTION_TYPES.MCSS.name || questionData.questionType === QUESTION_TYPES.CI.name
                                                ? questionData.questionResults.length : 3}
                                            gradeEnabled={gradeEnabled}
                                            showAnswerOnViewer={showAnswerOnViewer}
                                            queResponse={questionResponses ? questionResponses.questions[selectedQuestion.serialNo - 1] : []}
                                            question={selectedQuestion}
                                            isLoading={dataLoading}
                                        />
                                    </div>
                                )}

                                {/* {(!dataReady &&
                        questionData.questionType !== QUESTION_TYPES.WC.name && questionData.questionType !== QUESTION_TYPES.SA.name &&
                        !(pollType === POLL_TYPE.SURVEY && (questionData.questionType === QUESTION_TYPES.FITB.name))) && (
                        <div className="loading-text-back">
                            <p tabIndex={0} className="loading-text">Class Results Loading...</p>
                        </div>
                    )} */}

                                {questionData.questionType === QUESTION_TYPES.WC.name && (
                                    <div style={{width: questionData.questionImageURL ? "50%" : "100%"}}>
                                        <WordCloudResult
                                            data={questionData.rawResponse}
                                            // pollType={pollType}
                                            // correctAnswers={correctAnswers}
                                            isSwitching={isSwitching}
                                            setIsSWitching={setIsSWitching}
                                        />
                                    </div>
                                )}

                                {questionData.questionType === QUESTION_TYPES.RK.name && (
                                    <div style={{width: "50%"}}>
                                        <ClassResultRank
                                            chartData={questionData.rawResponse ? questionData.rawResponse.responses : questionData.rawResponse}
                                            isLoading={dataLoading}/>
                                    </div>
                                )}

                                {questionData.questionType === QUESTION_TYPES.MH.name && !gradeEnabled && (
                                    <ClassResultMH
                                        chartData={questionData.rawResponse}
                                        correctAnswers={questionData.correctAnswers}
                                        isLoading={dataLoading}
                                        queResponse={questionResponses ? questionResponses.questions[selectedQuestion.serialNo - 1] : []}
                                    />
                                )} {((questionData.questionType === QUESTION_TYPES.OE.name) &&
                                <>
                                    <div style={{width: "100%"}}>
                                        <Col style={{marginTop: "10px", width: "100%"}}>
                                            {dataReady && (questionData?.rawResponse?.responses && Object.entries(questionData.rawResponse.responses).length && openEndedData.filter(entry => questionData.rawResponse.responses[entry[0]] && questionData.rawResponse.responses[entry[0]] !== FIXED_ANSWER.UNANSWERED).length != 0) &&
                                                <>
                                                    <div style={{
                                                        backgroundColor: "#FFFFFF",
                                                        border: "1px solid #E8EDF1",
                                                        borderRadius: "7.39px",
                                                        padding: 20,
                                                        width: "100%",
                                                        maxHeight: "500px",
                                                        overflowY: "auto",
                                                        boxShadow: "0px 0px 4px 0px #00000040"
                                                    }}>
                                                        <div style={{display: "flex", justifyContent: "space-between"}}>
                                                            <div style={{
                                                                fontSize: 16,
                                                                fontWeight: 600
                                                            }}> {PARTICIPANT_RESPONSE_TEXT} </div>
                                                            <div style={{
                                                                display: "flex",
                                                                gap: "5px",
                                                                alignItems: "center"
                                                            }}>
                                                                <div style={{
                                                                    fontSize: 16,
                                                                    color: "#0000000"
                                                                }}> {SORT_BY_TEXT}</div>
                                                                <YuJaDropdown
                                                                    data={[OPEN_ENDED_TIME_SORT, OPEN_ENDED_LIKE_SORT, OPEN_ENDED_DISLIKE_SORT]}
                                                                    getOptionLabel={item => item}
                                                                    getOptionValue={item => item}
                                                                    onChange={option => setSortOption(option)}
                                                                    value={sortOption}
                                                                    containerStyle={{width: 130}}
                                                                    style={{padding: "8px 15px"}}
                                                                />
                                                            </div>
                                                        </div>
                                                        {openEndedData.map(([key, value], index) => (
                                                                <>
                                                                    {questionData.rawResponse.responses[key] && questionData.rawResponse.responses[key] !== FIXED_ANSWER.UNANSWERED &&
                                                                        <div
                                                                            style={{
                                                                                marginTop: "20px",
                                                                                border: "1px solid #E8EDF1",
                                                                                color: "#000",
                                                                                borderRadius: "7.4px",
                                                                                minHeight: '94px',
                                                                                padding: 10,
                                                                                wordBreak: "break-word",
                                                                                boxShadow: "0px 0px 4px 0px #00000040"
                                                                            }}
                                                                        >
                                                                            {`${questionData.rawResponse.responses[key]["0"]}`}
                                                                            <div style={{
                                                                                borderRadius: "200px",
                                                                                gap: "20px",
                                                                                backgroundColor: "#ECEAF1",
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                height: 35,
                                                                                width: 165,
                                                                                marginTop: 10,
                                                                                padding: "5px 20px"
                                                                            }}>
                                                                                <div style={{display: "flex", gap: '5px'}}>

                                                                                    <div><ThumbUp/></div>
                                                                                    <div style={{
                                                                                        fontSize: 15,
                                                                                        fontWeight: 500,
                                                                                        color: "#4D4D4D"
                                                                                    }}> {value["1"]} </div>
                                                                                </div>

                                                                                <div style={{display: "flex", gap: '5px'}}>
                                                                                    <div><ThumbDown/></div>
                                                                                    <div style={{
                                                                                        fontSize: 15,
                                                                                        fontWeight: 500,
                                                                                        color: "#4D4D4D"
                                                                                    }}> {value["2"]} </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </>
                                                            )
                                                        )}
                                                    </div>
                                                </>
                                            }
                                            {dataReady && (!questionData?.rawResponse?.responses || !Object.entries(questionData.rawResponse.responses).length || !openEndedData.filter(entry => questionData.rawResponse.responses[entry[0]] && questionData.rawResponse.responses[entry[0]] !== FIXED_ANSWER.UNANSWERED).length) &&
                                                <div style={{
                                                    backgroundColor: "#FFFFFF",
                                                    border: "1px solid #E8EDF1",
                                                    boxShadow: "0px 0px 4px 0px #00000040",
                                                    borderRadius: "7.39px",
                                                    padding: 20,
                                                    width: "100%",
                                                    maxHeight: "500px",
                                                    overflowY: "auto"
                                                }}>
                                                    <div style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        justifyContent: "center",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                        gap: 5
                                                    }}>
                                                        <NoResultIllustration width={192} height={100}/>
                                                        <div style={{fontSize: 18, marginTop: 10, fontWeight: 600}}>
                                                            {NO_RESPONSES_TEXT}
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </Col>
                                    </div>
                                </>
                            )}

                                {((questionData.questionType === QUESTION_TYPES.SA.name) &&
                                    <>
                                        <div style={{width: "50%"}}>
                                            <>
                                                <Col style={{maxHeight: "300px", overflowY: "auto"}}>
                                                    {dataReady && questionResponses && questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption && questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption !== "unanswered" &&
                                                        <>
                                                            <div
                                                                className={`questionPanelAnswersText resultAnimation`}
                                                                style={{
                                                                    marginTop: "20px",
                                                                    backgroundColor: "#C4BDD2",
                                                                    color: "#000", borderRadius: "10px",
                                                                    width: "fit-content",
                                                                    padding: "15px"
                                                                }}
                                                            >
                                                                {`"${questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption["0"]}"`}
                                                            </div>

                                                            {removeSelected(questionData.rawResponse, questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption["0"], questionData.questionType)?.map((item) =>
                                                                item !== "unanswered" ? (
                                                                    <div
                                                                        tabIndex={0}
                                                                        className={`questionPanelAnswersText resultAnimation`}
                                                                        style={{
                                                                            marginTop: "20px",
                                                                            backgroundColor: "#CACACA",
                                                                            color: "#000", borderRadius: "10px",
                                                                            width: "fit-content",
                                                                            padding: "15px"
                                                                        }}
                                                                    >
                                                                        {`"${item["0"]}"`}
                                                                    </div>
                                                                ) : <></>
                                                            )}
                                                        </>
                                                    }

                                                    {questionResponses && (!questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption || questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption === "unanswered") &&
                                                        <>{questionData.rawResponse?.map((item) =>
                                                            item !== "unanswered" ? (
                                                                <div
                                                                    className={`questionPanelAnswersText resultAnimation`}
                                                                    style={{
                                                                        marginTop: "20px",
                                                                        backgroundColor: "#CACACA",
                                                                        color: "#000", borderRadius: "10px",
                                                                        width: "fit-content",
                                                                        padding: "15px"
                                                                    }}
                                                                >
                                                                    {`"${item["0"]}"`}
                                                                </div>
                                                            ) : <></>
                                                        )}</>
                                                    }

                                                    {(!questionData.rawResponse || !questionData.rawResponse.filter(item => item !== FIXED_ANSWER.UNANSWERED).length) &&
                                                        <div style={{
                                                            color: "grey",
                                                            fontSize: 20,
                                                            height: 300,
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center"
                                                        }}> {EMPTY_WORD_CLOUD_TEXT} </div>
                                                    }
                                                </Col>
                                            </>
                                        </div>

                                        {/* {!isNarrow ?
                        <>
                            {questionData.questionImageURL && (
                                <div className="img-container" style={{ display: 'flex', justifyContent: 'center', flex: "1", marginRight: 0, padding: "25px" }}>
                                    <img className="img-class-result" src={questionData.questionImageURL} alt="Question Image" />
                                </div>
                            )}
                        </> : <></>
                    } */}
                                    </>
                                )}

                                {questionData.questionType === QUESTION_TYPES.FITB.name &&
                                    <>
                                        <div style={{width: "50%"}}>
                                            <>
                                                <Col style={{maxHeight: "300px", overflowY: "auto"}}>
                                                    {dataReady && questionResponses && questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption && questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption !== "unanswered" &&
                                                        <>
                                                            <div
                                                                className={`questionPanelAnswersText resultAnimation`}
                                                                style={{
                                                                    marginTop: "20px",
                                                                    backgroundColor: "#C4BDD2",
                                                                    color: "#000", borderRadius: "10px",
                                                                    width: "fit-content",
                                                                    padding: "15px",
                                                                }}
                                                                tabIndex={0}
                                                            >
                                                                {Object.keys(questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption).map((key) => `"${questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption[key]}"`).join(", ")}
                                                            </div>
                                                            <div>
                                                                {removeSelected(questionData.rawResponse, questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption, questionData.questionType)?.map((item) =>
                                                                    item !== "unanswered" ? (
                                                                        <div
                                                                            className={`questionPanelAnswersText resultAnimation`}
                                                                            style={{
                                                                                marginTop: "20px",
                                                                                backgroundColor: "#CACACA",
                                                                                color: "#000", borderRadius: "10px",
                                                                                width: "fit-content",
                                                                                padding: "15px",
                                                                            }}
                                                                            tabIndex={0}
                                                                        >
                                                                            {Object.keys(item).map((key) => `"${item[key]}"`).join(", ")}
                                                                        </div>
                                                                    ) : <></>)}
                                                            </div>

                                                        </>
                                                    }

                                                    {questionResponses && (!questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption || questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption === "unanswered") &&
                                                        <>{questionData.rawResponse?.map((item) =>
                                                            item !== "unanswered" ? (
                                                                <div
                                                                    className={`questionPanelAnswersText resultAnimation`}
                                                                    style={{
                                                                        marginTop: "20px",
                                                                        backgroundColor: "#CACACA",
                                                                        color: "#000", borderRadius: "10px",
                                                                        width: "fit-content",
                                                                        padding: "15px",
                                                                    }}
                                                                >
                                                                    {Object.keys(item).map((key) => `"${item[key]}"`).join(", ")}
                                                                </div>
                                                            ) : <></>)} </>

                                                    }
                                                    {(!questionData.rawResponse || !questionData.rawResponse.filter(item => item !== FIXED_ANSWER.UNANSWERED).length) &&
                                                        <div style={{
                                                            color: "grey",
                                                            fontSize: 20,
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center"
                                                        }}> {EMPTY_WORD_CLOUD_TEXT} </div>
                                                    }
                                                </Col>
                                            </>
                                        </div>
                                        {/* {!isNarrow ?
                                    <>
                                        {questionData.questionImageURL && (
                                            <div className="img-container" style={{display: 'flex',  justifyContent: 'center', flex: "1", marginRight: 0, padding: "25px"}}>
                                                <img className="img-class-result" src={questionData.questionImageURL} alt="Question" />
                                            </div>
                                        )}
                                    </> : <></>
                        } */}
                                    </>
                                }

                                {questionData.questionImageURL && questionData.questionType !== QUESTION_TYPES.CI.name && (
                                    <div className="img-container" style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flex: "1",
                                        marginRight: 0,
                                        padding: "25px"
                                    }}>
                                        {!questionData.isVideo &&
                                            <img className="img-class-result" src={questionData.questionImageURL}
                                                 alt="Question" tabIndex={0}
                                                 aria-label={selectedQuestion.imageAlt ? selectedQuestion.imageAlt : DEFAULT_IMAGE_ALT}/>}
                                        {questionData.isVideo && <iframe allowFullScreen className="img-class-result"
                                                                         src={questionData.questionImageURL}
                                                                         alt="Question"/>}
                                    </div>
                                )}

                                {questionData.questionImageURL && questionData.questionType === QUESTION_TYPES.CI.name && (
                                    <div className="img-container" style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flex: "1",
                                        marginRight: 0,
                                        padding: "25px"
                                    }}>
                                        <div style={{position: "relative"}}>
                                            <img ref={imageRef} className="img-class-result"
                                                 src={questionData.questionImageURL} tabIndex={0}
                                                 aria-label={selectedQuestion.imageAlt ? selectedQuestion.imageAlt : DEFAULT_IMAGE_ALT}
                                                 alt="Question Image" onLoad={onLoad}/>
                                            {isImgLoaded && questionResponses !== null && imageRef.current !== null
                                                && !!questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption
                                                && questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption.hasOwnProperty("x")
                                                && questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption.hasOwnProperty("y")
                                                &&
                                                <COORDINATE
                                                    style={{
                                                        position: "absolute",
                                                        top: `${decodeCIParam(questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption.y, imageRef.current.height) - CI_COORDINATE_SIZE.height}px`,
                                                        left: `${decodeCIParam(questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption.x, imageRef.current.width) - CI_COORDINATE_SIZE.width / 2}px`
                                                    }}
                                                />
                                            }
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                    }

                {!!classResultEndTime &&
                    <Countdown className="hide" date={classResultEndTime} onComplete={() => handleClassResultComplete()}/>
                }
        </div>
    );
}