import React, { useState } from "react";
import Resizer from "react-image-file-resizer";
import { YuJaTextBox } from "../../components/standardization/YuJaTextBox";
import useMobileAccess from "../../hooks/useMobileAccess";
import CancelIcon from "../../images/cancel.svg";
import { DELETE_OPT_BTN, MC_ANSWER_LENGTH, TEXTBOX, TEXT_BOX } from "../../utils/constants";
import { notifyError, notifyInfo } from "../../utils/helpers";
import {
  updateRankingAnswers
} from "../../utils/questionUpdate";
import { addOptionToQuestion, addRankToQuestion } from "../../utils/questionUtils";
import { ADD_OPTIONS_INFO, MC_CI_ANSWER_LENGTH_EXCEEDED } from "../../utils/toast-message-constants";
import "../styles.css";

export default function RKQuestionOption({
  optionData,
  selectedQuestion,
  questions,
  setQuestions,
  index,
  maxSize,
  questionBuilder=false,
  disabled=false,
  warningShowed,
  setWarningShowed
}) {

  const { choice, optionValue } = optionData;
  const { serialNo, optionsMap} = selectedQuestion;
  const selectedQuestionIndex = serialNo - 1;


  const handleAnswerChange = (input, itemKey) => {
    setQuestions(
        updateRankingAnswers(input, itemKey, selectedQuestionIndex, questions)
    );
  };

  const handleDeleteOption = () => {
    let newQuestions = [];

    questions.forEach((q) => {
      if (q.serialNo === serialNo) {
        let newOptionsMap = [];
        q.optionsMap.map((value, index) => {
          if (index !== choice) {
            if (index > choice) {
              const newIndex = index - 1;
              newOptionsMap[newIndex] = value;
            } else {
              newOptionsMap[index] = value;
            }
          }
        });
        let updatedQuestion = {
          ...q,
          optionsMap: newOptionsMap,
        };
        // updatedQuestion["image" + choice] = null;
        newQuestions.push(updatedQuestion);
      } else {
        newQuestions.push({ ...q });
      }
    });
    setQuestions(newQuestions);
  };

  const handlePaste = (e) => {
    if(e.target.value.length <= MC_ANSWER_LENGTH) {
      if (index + 1 === maxSize) {
        const newQuestions = addRankToQuestion(
          selectedQuestion,
          questions
        );
        if (newQuestions) {
          setQuestions(newQuestions);
        } else if(!warningShowed) {
          setWarningShowed(true);
          notifyInfo(ADD_OPTIONS_INFO);
        }
      }
    }
    else {
      notifyError(MC_CI_ANSWER_LENGTH_EXCEEDED);
    }
  }

  return (
        <div tabIndex={-1}>
          <div
              style={{display: "flex", flex: 1, alignItems: "center"}}
          >
            <YuJaTextBox
                disabled={questionBuilder && disabled}
                label={TEXT_BOX + (choice + 1) }
                containerStyle={{
                  width: "100%",
                  fontSize: questionBuilder ? 15 : 20,
                  padding: questionBuilder ? undefined : "12.5px",
                  border: "1px solid #858687"
                }}
                placeholder={`Option ${choice + 1}`}
                value={optionValue}
                name={"question-text"}
                maxLength={MC_ANSWER_LENGTH}
                onPaste={(e) => {handlePaste(e)}}
                onChange={(e) => {
                  e.preventDefault();
                  if(e.target.value.length <= MC_ANSWER_LENGTH) {
                    handleAnswerChange(e.target.value, choice);
                    if (index + 1 === maxSize) {
                      const newQuestions = addRankToQuestion(
                        selectedQuestion,
                        questions
                      );
                      if (newQuestions) {
                        setQuestions(newQuestions);
                        setTimeout(() => {
                          const mcqOptionsContainer = document.getElementById("mcq-options-container");
                          mcqOptionsContainer?.scroll({ top: mcqOptionsContainer.scrollHeight, behavior: "smooth" });
                        }, 10);
                      } else if (!warningShowed) {
                        setWarningShowed(true);
                        notifyInfo(ADD_OPTIONS_INFO);
                      }
                    }
                  }
                  else {
                    notifyError(MC_CI_ANSWER_LENGTH_EXCEEDED);
                  }
                }}
                showRemaining={true}
              />
              {(!questionBuilder || !disabled) && <div style={{ width: questionBuilder ? 20 : 30, marginLeft: questionBuilder ? 5 : 10, marginRight: questionBuilder ? 0 : 10 }}>
                {optionsMap.length > 1 && (
                  <img
                    aria-label={DELETE_OPT_BTN + choice + " button "}
                    tabIndex={0}
                    role={TEXTBOX}
                    onClick={handleDeleteOption}
                    src={CancelIcon}
                    alt="delete icon"
                    title={"Delete Option"}
                    className="cursor-pointer"
                    width={questionBuilder ? 20 : 30}
                    height={questionBuilder ? 20 : 30}
                  />
                )}
              </div>
              }

          </div>
        </div>
  );
}