import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
// import { useWindowDimensions } from "react-native-web";
import useMobileAccess from "../../hooks/useMobileAccess";
import {
    ASCII_CODE_A,
    ASCII_CODE_SMALL_A,
    FIXED_ANSWER,
    OPTION_LABEL,
    QUESTION_TYPES,
    TEXTBOX
} from "../../utils/constants";
import { checkUnanswered } from "../../utils/helpers";
import Checkmark from "./images/checkmark.svg";
import "./styles.css";
import styles from "./styles.module.css";
import {OptionImageViewer} from "./OptionImageViewer";

export default function ClassResultChart({ correctAnswers, chartData, questionType, gradeEnabled, showAnswerOnViewer=false, queResponse, question={}, isLoading=false}) {
    // const curWindow = useWindowDimensions();
    const [data, setData] = useState([]);
    const [selectedIdx, setSelectedIdx] = useState([]);
    const [totalData, setTotalData] = useState(0);
    const [correctAnswerIndexArr, setCorrectAnswerIndexArr] = useState([]);
    const isMobile = useMobileAccess(480);
    const {optionsImageMap={}} = question;
    useEffect(() => {
        let optionArrData = [];
        if (!chartData || !Object.entries(chartData).length) {
            if (questionType === QUESTION_TYPES.MCSS.name) {//{"a":"Margin-buying","b":"Overproduction ","c":"Expansion of credit buying","d":"The Dust Bowl "}
                optionArrData = Object.keys(question.optionsMap).sort().map(i => ({index: i.toUpperCase(), value: 0}));
            } else if (questionType === QUESTION_TYPES.CI.name) {//[{"text":"ab","x":0.147369,"y":0.068148,"width":0.310526,"height":0.16},{"text":"b","x":0.511404,"y":0.470617,"width":0.411404,"height":0.206913},{"text":"c","x":0.116667,"y":0.801482,"width":0.297368,"height":0.162468}]

            } else if (questionType === QUESTION_TYPES.FITB.name
                || questionType === QUESTION_TYPES.MH.name) {
                    optionArrData.push({ index: "Correct", value: 0});
                    optionArrData.push({ index: "Incorrect", value: 0});
                }
            else if (questionType === QUESTION_TYPES.TF.name
            ) {//graded poll TF or FITB or MH
                optionArrData.push({ index: "True", value: 0});
                optionArrData.push({ index: "False", value: 0});
            }
            setData(optionArrData);
            return;
        }


        const selectedIdxCopy = []
        let tempTotalData = 0;
        const correctAnswerIndexArrCopy = JSON.parse(JSON.stringify(correctAnswerIndexArr));
        chartData.forEach((result, index) => {
            if (questionType === QUESTION_TYPES.MCSS.name) {
                optionArrData.push({ index: result.ans.split(". ")[0], value: result.people, answer: result.ans.substring(result.ans.indexOf(". ") + ". ".length)});
            } else if (questionType === QUESTION_TYPES.CI.name) {
                if (result.ans === FIXED_ANSWER.OTHER) {
                    optionArrData.push({ index: FIXED_ANSWER.OTHER, value: result.people});
                } else {
                    optionArrData.push({ index: result.ans.split(". ")[0].toUpperCase(), value: result.people, answer: result.ans.substring(result.ans.indexOf(". ") + ". ".length)});
                }
            }else if (questionType === QUESTION_TYPES.TF.name) {
                optionArrData.push({ index: result.ans, value: result.people });
            } else {
                optionArrData.push({ index: result.ans, value: result.people});
            }

            if (!!correctAnswers && !!Object.entries(correctAnswers).length) {
                if ((questionType === QUESTION_TYPES.MCSS.name && correctAnswers.includes(optionArrData[index]?.index?.toLowerCase()))
                    || (questionType === QUESTION_TYPES.CI.name && correctAnswers.includes(result.name))
                    || (questionType === QUESTION_TYPES.TF.name && result.name.toString().includes(correctAnswers))
                    || (questionType === QUESTION_TYPES.SA.name && result.ans === "Correct")
                    || (questionType === QUESTION_TYPES.MH.name && result.ans === "Correct")
                    || (questionType === QUESTION_TYPES.FITB.name && result.ans === "Correct")
                ) {
                    correctAnswerIndexArrCopy.push(index);
                }
            }
            tempTotalData += result.people
            if (!checkUnanswered(gradeEnabled, questionType, queResponse?.selectedOption)) {
                if (questionType === QUESTION_TYPES.MCSS.name && !gradeEnabled && queResponse.selectedOption.includes(optionArrData[index]?.index?.toLowerCase())) {
                    selectedIdxCopy.push(index);
                }else if (questionType === QUESTION_TYPES.MCSS.name && !!gradeEnabled && queResponse.selectedOption === optionArrData[index]?.index?.toLowerCase()) {
                    selectedIdxCopy.push(index);
                }else if ( questionType === QUESTION_TYPES.TF.name && queResponse.selectedOption?.toLowerCase() === optionArrData[index]?.index?.toLowerCase()) {
                    selectedIdxCopy.push(index);
                }else if (questionType === QUESTION_TYPES.FITB.name) {
                    if ((!!queResponse.isCorrect && optionArrData[index]?.index?.toLowerCase() === 'correct') || (!queResponse.isCorrect && optionArrData[index]?.index?.toLowerCase() === 'incorrect') ) {
                        selectedIdxCopy.push(index);
                    }
                } else if (questionType === QUESTION_TYPES.MH.name) {
                    if ((!!queResponse.isCorrect && optionArrData[index]?.index?.toLowerCase() === 'correct') || (!queResponse.isCorrect && optionArrData[index]?.index?.toLowerCase() === 'incorrect') ) {
                        selectedIdxCopy.push(index);
                    }
                } else if (questionType === QUESTION_TYPES.CI.name) {
                    const selectedOptIdx = isNaN(queResponse.selectedOption.selected) ? FIXED_ANSWER.OTHER : String.fromCharCode(ASCII_CODE_A + parseInt(queResponse.selectedOption.selected));
                    if (optionArrData[index]?.index?.toUpperCase() === selectedOptIdx.toUpperCase()) {
                        selectedIdxCopy.push(index);
                    }
                }
            }

        })


        setData(optionArrData);
        setTotalData(tempTotalData);
        setSelectedIdx(selectedIdxCopy);
        setCorrectAnswerIndexArr(correctAnswerIndexArrCopy);
        setTimeout(() => {
            optionArrData.forEach((entry, index) => {
                document.getElementById(`option${index}`).style.width = `${!!tempTotalData ? (entry.value / tempTotalData) * 100 : 0}%`;
            });
        }, 500);
    }, [chartData]);

    const setBar = () => {
        setTimeout(() => {
            data.forEach((entry, index) => {
                document.getElementById(`option${index}`).style.width = `${!!totalData ? (entry.value / totalData) * 100 : 0}%`;
            });
        }, 500);
    }

    const onMouseOver = (props) => {
        const target = props.target;
        if (target.scrollWidth > target.offsetWidth) {
            const idx = parseInt(target.id.substring('optionText'.length));
            const dataCopy = JSON.parse(JSON.stringify(data));
            dataCopy[idx].show = true;
            setData(dataCopy);
        }
    }

    const onMouseOut = () => {
        const dataCopy = JSON.parse(JSON.stringify(data));
        setData(dataCopy.map(i => {
            i.show = false;
            return i;
        }));
    }

    useEffect(() => {
        console.log(selectedIdx);
    }, [selectedIdx])

    return (
        <div className={styles.chartContainer} onLoad={setBar}>
            {!!data && !isLoading &&
                data.map((entry, index) => {
                    const optIdx = String.fromCharCode(ASCII_CODE_SMALL_A + index);
                    return (
                        <div className={styles.optionContainer} key={index}>
                            <div className={styles.option}>
                                <div tabIndex={0} role={TEXTBOX} aria-label={OPTION_LABEL + entry.index} className={`${styles.optionIndex} ${selectedIdx.includes(index) ? styles.correctOptionText : styles.normalOptionText}`}>{entry.index}</div>
                                <OptionImageViewer optionsImageMap={optionsImageMap} optIdx={optIdx} isSelected={false}/>
                                {!!entry.answer &&
                                    <OverlayTrigger
                                        // show={!!entry.show}
                                        overlay={
                                            <Tooltip>{entry.answer}</Tooltip>

                                        }
                                    >
                                        <div id={`optionText${index}`} tabIndex={0} role={TEXTBOX} className={`${styles.optionText} ${selectedIdx.includes(index) ? styles.correctOptionText :  styles.normalOptionText}`} onMouseEnter={onMouseOver} onMouseLeave={onMouseOut}>
                                            {entry.answer}
                                        </div>
                                    </OverlayTrigger>
                                }

                                {gradeEnabled && showAnswerOnViewer && correctAnswerIndexArr.includes(index) &&
                                    <img src={Checkmark} tabIndex={0} alt="Check Mark" className={styles.optionSelectedMark} />
                                }
                                <div
                                    id={`option${index}`}
                                    className={`${styles.optionCountBar} ${selectedIdx.includes(index) ? styles.correct : ''}`}
                                />
                            </div>
                            <div className={`${styles.countValueContainer}`} role={TEXTBOX} tabIndex={0}>{entry.value}</div>
                        </div>
                    );

            })}
            {
                isLoading && Array.from({ length: 4 }).map((_, rowIndex) => (
                    <div className={styles.optionContainer}>
                            <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
						    <Skeleton borderRadius={10} height={55} width= {isMobile ? "80vw" : "50vw"} style={{ position: "relative", borderRadius: "10px" }} />
						    </SkeletonTheme>
                    </div> 
                ))
            }
        </div>
    );
}
