import { React, useEffect, useState } from "react";
import { Spin } from "../../components/standardization/YuJaLoading";
import {
    COMPLETION_RATE_TITLE, NO_PARTICIPANTS_FOUND_TEXT,
    PARTICIPANT_SUB_TITLE_EACH_QUESTION, PARTICIPANT_SUB_TITLE_OVER_ALL, PARTICIPANTS_TITLE,
    POLL_SHARE_MODE,
    POLL_STATE,
    TEXTBOX
} from "../../utils/constants";
import { fillFormatText } from "../../utils/helpers";
import { ReactComponent as EngageIcon } from "./images/engage_icon.svg";
import { ReactComponent as ArrowDown } from "../../images/YuJa_arrow_down_icon.svg";
import { ReactComponent as ArrowUp } from "../../images/YuJa_arrow_up_icon.svg";
import { ReactComponent as NoResultIllustration } from "../../images/no-result-found.svg";
import "./styles.css";
import styles from "./SharePollPage.module.css";

export default function ViewParticipants({
                                             data=[],
                                             dataAll={},
                                             pollData=[],
                                             loading=true,
                                             initialized=false,
                                             currQues,
                                             shareMode,
                                             localState,
                                             collapsed,
                                             selectedQuestion,
}) {
    // const location = useLocation();
    const [cardCollapsed, setCardCollapsed] = useState(true); // !location?.state?.expandedAnalytics
    const [pollWasShared, setPollWasShared] = useState(false);
    const [chartData, setChartData] = useState({});

    useEffect(() => {
        if (!initialized || !!loading) {
            return;
        }

        if ([POLL_SHARE_MODE.SHARE_ALL, POLL_SHARE_MODE.SCHEDULE].includes(pollData?.poll?.pollShareMode) && !selectedQuestion) {
            setChartData(dataAll);
        } else {
            if (!!data && data.length > 0 && data.length >= currQues) {
                setChartData(data[currQues - 1]);
            } else {
                setChartData({});
            }
        }

    }, [data, dataAll, pollData, currQues, loading, initialized, selectedQuestion]);

    useEffect( () => {
        if (Object.entries(chartData).length === 0) {
            return;
        }


        if(document.getElementById("partsUnasTotal") !== null) {
            let width = chartData.unattempted * 100 / chartData.total;
            document.getElementById("partsUnasTotal").style.width = width + "%";
        }
        if(document.getElementById("partsAnsTotal") !== null) {
            let width = chartData.attempted * 100 /chartData.total;
            document.getElementById("partsAnsTotal").style.width = width + "%";
        }
    }, [chartData, loading, initialized, cardCollapsed]);


    const displayParticipants = () => {
        if(shareMode === POLL_SHARE_MODE.SHARE_EACH) {
            return true;
        }

        if(pollWasShared) {
            return true;
        }

        if(localState !== "START POLL") {
            setPollWasShared(true);
            return true;
        }

        if(localState !== "SHARED_ALL" && (shareMode === POLL_SHARE_MODE.SHARE_ALL || shareMode === POLL_SHARE_MODE.SCHEDULE)) {
            return true;
        }
        
        if(!!pollData && !!pollData.poll && !!pollData.poll.pollState) {
            const currentTime = new Date();

            if(pollData.poll.pollState === POLL_STATE.SCHEDULED && !!pollData.poll.startTime) {
                if(currentTime > new Date(pollData.poll.startTime)) {
                    return true;
                }
            } 
            else if(pollData.poll.pollState === POLL_STATE.SHARED_ALL || pollData.poll.pollState === POLL_STATE.STOPPED_ALL) {
                return true;
            }
        }
        return false;
    };

    return(
        <div
            className={styles.panelCard}
            style={{
                maxHeight: !cardCollapsed ? 300 : 48,
                overflow: "hidden"
            }}
        >
            <div className={styles.panelCardHeader} onClick={() => setCardCollapsed(!cardCollapsed)}>
                <div className={styles.panelCardHeaderTitle} tabIndex={0} role={TEXTBOX}>{PARTICIPANTS_TITLE}</div>
                {cardCollapsed ? <ArrowDown/> : <ArrowUp/>}
            </div>

            <div className={styles.panelCardBody}>
                <span className={styles.panelCardBodySubTitle} tabIndex={collapsed ? -1 : 0} role={TEXTBOX} style={{marginBottom: 10}}>
                    {[POLL_SHARE_MODE.SHARE_ALL, POLL_SHARE_MODE.SCHEDULE].includes(pollData?.poll?.pollShareMode) && !selectedQuestion
                        ? PARTICIPANT_SUB_TITLE_OVER_ALL
                        : fillFormatText(PARTICIPANT_SUB_TITLE_EACH_QUESTION, {Question: "Q" + currQues})
                    }
                </span>
                {!!loading &&
                    <Spin size="large" wrapperClassName="panelSpin" spinning={true} tip="Loading...">
                        <div style={{width: "100%", height: 280}}/>
                    </Spin>
                }
                {!loading && initialized && (!chartData || !chartData.total || !displayParticipants()) &&
                    <>
                        <NoResultIllustration style={{maxHeight: 90, alignSelf: "center", flexShrink: 0}}/>
                        <span className={styles.questionBuilderDefaultLabel} tabIndex={0} role={TEXTBOX} style={{marginTop: 10, alignSelf: "center"}}>{NO_PARTICIPANTS_FOUND_TEXT}</span>
                    </>
                }
                {!loading && initialized && displayParticipants() && chartData && chartData.total > 0 &&
                    <div style={{
                        justifyContent: "flex-start",
                        display: "flex",
                        flexDirection: "column",
                        width: "100%"
                    }}>
                        <div className="participantsPanelText" style={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "8px"
                        }}>
                            {shareMode === POLL_SHARE_MODE.SHARE_EACH &&
                                <div tabIndex={collapsed ? -1 : 0}
                                     role={TEXTBOX}>{localState === "START POLL" ? "Finished " : "Done "}</div>
                            }
                            {shareMode === POLL_SHARE_MODE.SHARE_EACH &&
                                <div style={{color: "#0277AD"}} tabIndex={collapsed ? -1 : 0}
                                     role={TEXTBOX}>
                                    {chartData.attempted}
                                </div>
                            }
                            {(shareMode === POLL_SHARE_MODE.SHARE_ALL || shareMode === POLL_SHARE_MODE.SCHEDULE) &&
                                <div tabIndex={collapsed ? -1 : 0}
                                     role={TEXTBOX}>{localState === "START POLL" ? "Finished " : "Done "}</div>
                            }

                            {(shareMode === POLL_SHARE_MODE.SHARE_ALL || shareMode === POLL_SHARE_MODE.SCHEDULE) &&
                                <div style={{color: "#0277AD"}} tabIndex={collapsed ? -1 : 0}
                                     role={TEXTBOX}>
                                    {chartData.attempted}
                                </div>
                            }

                        </div>
                        <div className="slidecontainer">
                            <div className="slider2" id="partsAnsTotal">.</div>
                        </div>

                        <div className="participantsPanelText" style={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "20px",
                            marginBottom: "8px"
                        }}>
                            {shareMode === POLL_SHARE_MODE.SHARE_EACH &&
                                <div tabIndex={collapsed ? -1 : 0}
                                     role={TEXTBOX}>{localState === "START POLL" ? "Incomplete " : "In Progress "}</div>
                            }
                            {shareMode === POLL_SHARE_MODE.SHARE_EACH &&
                                <div style={{color: "#01BEA3"}} tabIndex={collapsed ? -1 : 0}
                                     role={TEXTBOX}>
                                    {chartData.unattempted}
                                </div>
                            }

                            {(shareMode === POLL_SHARE_MODE.SHARE_ALL || shareMode === POLL_SHARE_MODE.SCHEDULE) &&
                                <div tabIndex={collapsed ? -1 : 0}
                                     role={TEXTBOX}>{localState === "START POLL" ? "Incomplete " : "In Progress "}</div>
                            }
                            {(shareMode === POLL_SHARE_MODE.SHARE_ALL || shareMode === POLL_SHARE_MODE.SCHEDULE) &&
                                <div style={{color: "#01BEA3"}} tabIndex={collapsed ? -1 : 0}
                                     role={TEXTBOX}>
                                    {chartData.unattempted}
                                </div>
                            }


                        </div>
                        <div className="slidecontainer">
                            <div id="partsUnasTotal" className="slider">.</div>
                        </div>

                        <div className="engagementText" style={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "25px"
                        }}>
                            {shareMode === POLL_SHARE_MODE.SHARE_EACH && <div tabIndex={collapsed ? -1 : 0}
                                                                              role={TEXTBOX}>{COMPLETION_RATE_TITLE}</div>}
                            {(shareMode === POLL_SHARE_MODE.SHARE_ALL || shareMode === POLL_SHARE_MODE.SCHEDULE) &&
                                <div tabIndex={collapsed ? -1 : 0}
                                     role={TEXTBOX}>{COMPLETION_RATE_TITLE}</div>}
                            <div className="engagementButton" tabIndex={collapsed ? -1 : 0} role={TEXTBOX}>
                                <EngageIcon/>{chartData.enagement}</div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}
