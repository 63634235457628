import React, {useEffect, useState} from "react";
import "../JoinQuizPage/styles.css";
import styles from "../JoinQuizPage/styles.module.css";
import sharePollPageStyles from "./SharePollPage.module.css";
import {getPrefillQuestionResult} from "../../hooks/useClassResultsAnalysisManager";

export default function QuestionBarChart({ chartData, question, showAnswer=false}) {

    const [convertedData, setConvertedData] = useState([]);
    const {optionsImageMap={}} = question;

    useEffect(() => {
        let tempTotalData = 0;
        chartData.forEach((result, index) => {
            tempTotalData += result.people;
        });


        //if total is 0, use question to render in case of option changes in question builder
        if (tempTotalData === 0) {
            const prefillData = getPrefillQuestionResult(question);
            setConvertedData(prefillData);
        } else {
            setConvertedData(chartData);
        }
    }, [chartData, question]);


    useEffect(() => {
        let tempTotalData = 0;
        convertedData.forEach((result, index) => {
            tempTotalData += result.people;
        });

        convertedData.forEach((entry, index) => {
            if(document.getElementById(`option${index}`) != null) {
                document.getElementById(`option${index}`).style.width = `${!!tempTotalData ? (entry.people / tempTotalData) * 100 : 0}%`;
            }
        });

    }, [convertedData]);

    return (
        <div className={styles.chartContainerSharePoll} >
            {!!convertedData && convertedData.map((entry, index) => (
                <div className={styles.optionContainer} key={index}>
                    <div className={styles.option} id={"Option" + index} style={{minHeight: 50}}>
                        {!!entry.indexDisplay &&
                            <div
                                id={"OptionPart1" + index}
                                className={`${sharePollPageStyles.optionsText} ${sharePollPageStyles.optionsIndex}`}
                                style={(!!showAnswer && entry.isCorrect) ? {color: "#268726", fontWeight: "700"} : {}}
                            >
                                {entry.indexDisplay}
                            </div>
                        }

                        {optionsImageMap.hasOwnProperty(entry.key) && !!optionsImageMap[entry.key].imageURL &&
                            <div className={styles.optionImageContainer}>
                                <img
                                    style={{maxWidth: "100%", maxHeight: "100%"}}
                                    src={optionsImageMap[entry.key].imageURL}
                                />
                            </div>
                        }

                        <div
                            id={"OptionPart2" + index}
                            className={sharePollPageStyles.optionsText}
                            style={(!!showAnswer && entry.isCorrect) ? {color: "#268726", fontWeight: "700"} : {}}
                        >
                            {!!entry.ans ? entry.ans : ""}
                        </div>
                        <div id={`option${index}`} className={`${styles.optionCountBarMC}`}/>
                        
                      <div className={sharePollPageStyles.optionCount}>{entry.people}</div>
                    </div>
                </div>
            ))}
        </div>
    );
}
