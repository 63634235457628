import React from "react";
import { Modal } from "react-bootstrap";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import { BUTTON, CLOSE_MODAL, MODAL, MODAL_CONFIRM_TEXT, MODAL_GO_BACK_TEXT, TEXTBOX } from "../../utils/constants";
import YuJaButton from "../standardization/YuJaButton";

export default function YuJaGeneralAlert({config, content, setModalShow, show, AlertType=MODAL_CONFIRM_TEXT, handleclose=null}) {
    // const handleClose = () =>{
    //     setModalShow(false);
    // };


    return (
        <Modal
            id="form-modal"
            show={show}
            onHide={!handleclose ? config.close : handleclose}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop={true}
        >
            <ModalClose tabIndex={0} aria-label={CLOSE_MODAL} role={BUTTON} onClick={handleclose ? handleclose : config.close} className="modal-close" />
            <Modal.Header
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Modal.Title tabIndex={0} role={TEXTBOX} aria-label={config.title + MODAL} className="modal-title" id="contained-modal-title-vcenter">
                    {config.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div tabIndex={0} role={TEXTBOX} className="form-subtext">{content}</div>
            </Modal.Body>
            <Modal.Footer>
                <YuJaButton
                    type="secondary"
                    onClick={config.close}
                    style={config.buttonWidth ? { width: config.buttonWidth } : {}}
                    wrapperStyle={{whiteSpace: "nowrap"}}
                >
                    {config.cancelText ? config.cancelText : MODAL_GO_BACK_TEXT}
                </YuJaButton>
                <YuJaButton
                    onClick={(e) => {
                        e.preventDefault();
                        config.submit();
                    }}
                    type={AlertType=== MODAL_CONFIRM_TEXT ? "primary" : "delete"}
                    style={config.buttonWidth ? { width: config.buttonWidth } : {}}
                >
                    {config.okText}
                </YuJaButton>
            </Modal.Footer>
        </Modal>
    );
}