import React, {useEffect, useState} from "react";
import { useWindowDimensions } from "react-native-web";
import useMobileAccess from "../../hooks/useMobileAccess";
import waitingRoomBackground from "../../images/waitingRoomBackground.svg";
import waitingRoomBackgroundMobile from "../../images/waitingRoomBackgroundMobile.svg";
import {
  DIVISOR_LABEL,
  RELEASE_QUESTION_COUNT_TEXT,
  TEXTBOX,
  WAITING_ROOM_ACTIVITY_TEXT,
  WAITING_ROOM_POLL_TEXT,
} from "../../utils/constants";
import { newPageLog } from "../../utils/helpers";
import "./styles.css";
import PollTitleContainer from "./PollTitleContainer";

export default function WaitingRoom({ bodyText, bodyShareCount, titleText, afterQ, websocketState, loadingDelay }) {

  const windowDimensions = useWindowDimensions();

  const getImageSize = () => {
    let {width, height} = windowDimensions;
    let adjustedWidth = Math.min(width * 0.3, 300);
    let adjustedHeight = Math.min(height * 0.3, 300);

    return Math.min(adjustedWidth, adjustedHeight);
  }

  const isNarrow = useMobileAccess(1200);
  const [imageSize, setImageSize] = useState(() => getImageSize())
  useEffect(() => {
    newPageLog("WaitingRoom.js");
    if (isNarrow && document.getElementById("viewer-navbar-container") !== null) {
      document.getElementById("viewer-navbar-container").style.backgroundColor = "#FCFBFF";
    }
  }, []);

  useEffect(() => {
    setImageSize(getImageSize());
  }, [windowDimensions]);

  return (
    <div
        className="waiting-room-background"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: '100%',
          height: "100%",
          padding: 20,
          gap: 20,
          backgroundImage: window.innerWidth < 480 ? `url(${waitingRoomBackgroundMobile})` : `url(${waitingRoomBackground})`
        }}
    >

      <PollTitleContainer
          pollTitle={loadingDelay ? null : titleText}
          websocketState={websocketState}
      />
      <img
          src={require("../../images/waitingRoom.gif")}
          alt="loading..."
          style={{ height: imageSize, width: imageSize, marginTop: "min(5%, 30px)" }}
      />

          {bodyShareCount &&
            <span className="gradientWrapQuesType" style={{ height: "45px", width: !isNarrow ? "259px" : "200px", marginTop: 20 }}>
              <span className="questionPanelQuestionTypeContainer" style={{ width: "100%" }}>
                <div
                    tabIndex={bodyText ? 0 : -1}
                    role={TEXTBOX}
                    aria-label={bodyShareCount?.replace("/", DIVISOR_LABEL)}
                    className="waiting-room-subtitle"
                    style={{ fontSize: isNarrow ? 14 : 16,  color: "#000", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
                >
                  {RELEASE_QUESTION_COUNT_TEXT} <div style={{ fontWeight: 700, marginLeft: "5px" }}> {bodyShareCount} </div>
                </div>
              </span>
            </span>
          }
          <div tabIndex={bodyText ? 0 : -1} role={TEXTBOX} className="waiting-room-subtitle" >
            {bodyText}
          </div>
      {!afterQ &&
        <div tabIndex={0} role={TEXTBOX} className="waiting-room-bottom" style={{ marginTop: "30px", fontSize: isNarrow ? 18 : undefined }}>
          {(loadingDelay || !titleText) ? WAITING_ROOM_ACTIVITY_TEXT : WAITING_ROOM_POLL_TEXT}
        </div>
      }
    </div>
  );
}
