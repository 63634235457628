import React, {useEffect, useRef, useState} from "react";
import styles from "./YuJaKebabMenu.module.css";
import {ReactComponent as MenuIcon} from "../../images/cebabe_menu_icon.svg"
import {useYuJaEventBus} from "./YuJaEventBusProvider";
import { BUTTON, TEXTBOX } from "../../utils/constants";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export const YuJaKebabMenu = ({children, iconVisible=true, ...props}) => {
    const [isOpen, setIsOpen] = useState(false);
    const iconRef = useRef();
    const [, set] = useState()

    const open = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsOpen(!isOpen);
    }

    const handleKeyDown = (event) => {
        if (event.key === ' ') {
          event.preventDefault();
          open()
        }
    };

    const findKebabMenuParent = (element) => {

        let thisEl = element;

        if (thisEl.id === "root") {
            return false;
        }

        if (thisEl.className.includes("YuJaKebabMenu"))  {
            return true;
        }
        return findKebabMenuParent(thisEl.parentElement);
    }



    const onBlur = (event) => {
        const {relatedTarget} = event;
        if (!!relatedTarget) {
            let result = findKebabMenuParent(relatedTarget);
            if (!result) {
                setIsOpen(false);
            }
        }

    };

    return (
        <div className={styles.container}
             onClick={open}
             onBlur={onBlur}
             tabIndex={0}
             // onMouseDown={e => e.preventDefault()}
             // onKeyDown={handleKeyDown}
        >
            <div ref={iconRef} style={{position: "relative"}}>
                <MenuIcon
                    aria-label="Open Menu"
                    role={BUTTON}
                    className={styles.focusIcon}
                    style={{ opacity: iconVisible ? 1: 0, padding: iconVisible ? 0: 20}}
                />
                {children &&
                    <MenuWrapper isOpen={isOpen} setIsOpen={setIsOpen}>
                       {React.cloneElement(children,
                           {
                               ...children.props,
                               isOpen: isOpen,
                               setIsOpen: setIsOpen,
                               iconRef: iconRef,
                           })
                       }
                    </MenuWrapper>
                }
             </div>

        </div>
    );
}

const MenuWrapper = ({children, isOpen, setIsOpen}) => {
    const onClick = (e) => {
        e.stopPropagation();
        setIsOpen(false);
    }

    return (
        <div
            className={styles.kebabMenuMask}
            onClick={onClick}
            style={{visibility: isOpen ? "visible" : "hidden"}}
        >
            {children}
        </div>
    );

}


function Menu({isOpen, setIsOpen, iconRef, children}) {
    const menuRef = useRef();
    const { addClickEventHandler, removeClickEventHandler} = useYuJaEventBus();
    const windowDimensions = useWindowDimensions();
    const [isTop, setIsTop] = useState(null);


    useEffect(() => {
        const handleGlobalClick = (event) => {
            // Check if the click occurred outside of the dropdown
            if (isOpen && menuRef.current && !iconRef.current.contains(event.target) && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };


        if (isOpen) {
            adaptMenuPosition();
            addClickEventHandler(handleGlobalClick);
            menuRef.current.style.visibility = "visible";
        } else {
            resetMenuPosition();
            menuRef.current.style.visibility = "hidden";
        }


        return () => {
            removeClickEventHandler(handleGlobalClick);
        };
    }, [isOpen]);


    useEffect(() => {
        if (isOpen) {
            //find the first overflow hidden parent ele
            adaptMenuPosition();
        }
    }, [windowDimensions]);


    const resetMenuPosition = () => {
        menuRef.current.style.visibility = "hidden";
        menuRef.current.style.top = `0px`;
        menuRef.current.style.left = `0px`;
    }


    const adaptMenuPosition = () => {
        let windowHeight = document.documentElement.scrollHeight;
        const { height, top, left, width} = iconRef.current.getBoundingClientRect();
        const menuHeight = menuRef.current.children[0].offsetHeight;
        const menuWidth = menuRef.current.children[0].offsetWidth;
        /*
        parentTop
                <parent>             iconTop
                    |   <menuIcon/>  iconHeight
                    |                10
        parentHeight|   <menuList>
                    |   </menuList>  menuHeight
                </parent>
        */

        const isBottomOverflow = top + height + menuHeight + 15 > windowHeight;
        const isTopOverflow = top - menuHeight - 15 < 0;
        if (isBottomOverflow && !isTopOverflow) {
            setIsTop(true);
            menuRef.current.style.top = `${top - menuHeight - 10}px`;
            menuRef.current.style.left = `${left + (width / 2) + 24 - menuWidth}px`;
        } else {
            setIsTop(false);
            menuRef.current.style.top = `${top + height + 10}px`;
            menuRef.current.style.left = `${left + (width / 2) + 24 - menuWidth}px`;
        }
    }

    return (
        <>
            <>
                <div className={`${styles.menuContainer} ${isTop ? styles.top : styles.bottom}`} ref={menuRef}>
                    <div className={`${styles.menuList}`} >
                        {
                            children && Array.isArray(children) &&
                            children.map((item, index) => {
                                    if (!item) {
                                        return null;
                                    }

                                    return  React.cloneElement(
                                            item,
                                            {
                                                ...item.props,
                                                id: index,
                                                key: index,
                                                isOpen: isOpen,
                                                setIsOpen: setIsOpen
                                            });
                            })
                        }


                        {children && !Array.isArray(children) &&
                            React.cloneElement(
                                children,
                                {
                                    ...children.props,
                                    isOpen: isOpen,
                                    setIsOpen: setIsOpen
                                }
                            )

                        }
                    </div>
                </div>
                {/*<div className={styles.backDrop} onClick={() => setIsOpen(false)}/>*/}
            </>

        </>
    );
}


function Item({children, isOpen, label, setIsOpen, ...props}) {
    const nodeRef = useRef();

    useEffect(() => {
        const textNode = nodeRef.current;
        if (textNode && textNode.offsetWidth < textNode.scrollWidth) {
            textNode.title = textNode.innerText;
        }
        // console.log(`isOpen: ${isOpen}`)
    }, []);

    const onClick =(e) => {
        setIsOpen(false);
        if (props.onClick) {
            props.onClick(e);
        }

    }

    return (
        <div className={`${styles.menuItem}`} role={TEXTBOX} onClick={onClick} tabIndex={0} aria-label={label}>
            <span ref={nodeRef} className={styles.menuItemText}>{children}</span>
        </div>
    );
}


YuJaKebabMenu.Menu= Menu;
YuJaKebabMenu.Item= Item;