import { useEffect, useState} from "react";
import useWindowDimensions from "./useWindowDimensions";

export const useCheckMainContainerNarrow = () => {
    let {width} = useWindowDimensions();
    const [isNarrow, setIsNarrow] = useState(false);
    // const isNarrow = windowWidth - (sidebarCollapsed ? COLLAPSED_SIDE_BAR_WIDTH : EXPANDED_SIDE_BAR_WIDTH)  < 1200;
    useEffect(() => {
        setIsNarrow(width < 1200)
    }, [width]);


    return {isNarrow};
}
