import React, { useState } from "react";
import { Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import {
    BROWSER_TYPES,
    NO_PARTICIPANTS_FOUND_TEXT,
    PARTICIPANTS_PLATFORM_TEXT,
    PLATFORM_TYPES,
    TEXTBOX
} from "../../utils/constants";

import { ReactComponent as Blue } from "./images/blue_square.svg";
import { ReactComponent as Green } from "./images/green_square.svg";
import { ReactComponent as Pink } from "./images/pink_square.svg";
import { ReactComponent as Purple } from "./images/purple_square.svg";
import { ReactComponent as Red } from "./images/red_square.svg";
import { ReactComponent as ShiftNext } from "./images/shiftInfo.svg";
import { ReactComponent as ShiftPrev } from "./images/shiftInfoBack.svg";
import { ReactComponent as Yellow } from "./images/yellow_square.svg";
import { ReactComponent as ArrowDown } from "../../images/YuJa_arrow_down_icon.svg";
import { ReactComponent as ArrowUp } from "../../images/YuJa_arrow_up_icon.svg";
import { ReactComponent as EmptyBox } from "../../images/empty-box.svg";
import "./styles.css";
import styles from "./SharePollPage.module.css";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function ViewBrowserInfo({data=[], collapsed}) {
    let {width: windowWidth} = useWindowDimensions();
    const [cardCollapsed, setCardCollapsed] = useState(true);
    const [showBrowser, setShowBrowser] = useState(true);

    const convertDataBrowser = () => {
        let newData = [
            {name: BROWSER_TYPES.Chrome, number: data.chrome, fill: "#00547A"},
            {name: BROWSER_TYPES.Edge, number: data.edge, fill: "#007E6B"},
            {name: BROWSER_TYPES.Safari, number: data.safari, fill: "#594FD4"},
            {name: BROWSER_TYPES.Firefox, number: data.firefox, fill: "#872626"},
            {name: BROWSER_TYPES.MobileApp, number: data.mobileApp, fill: "#994484"},
            {name: BROWSER_TYPES.Other, number: data.otherBrowser, fill: "#B58C3A"}
        ];
        return newData;
    };

    const convertDataPlatform = () => {
        let newData = [
            {name: PLATFORM_TYPES.Apple, number: data.apple, fill: "#00547A"},
            {name: PLATFORM_TYPES.Windows, number: data.windows, fill: "#007E6B"},
            {name: PLATFORM_TYPES.Android, number: data.android, fill: "#594FD4"},
            {name: BROWSER_TYPES.Other, number: data.otherPlatform, fill: "#B58C3A"}
        ];
        return newData;
    };

    const getIcon = (name) => {
        if(name === BROWSER_TYPES.Chrome || name === PLATFORM_TYPES.Apple) {return <Blue style={{marginRight: "5px"}} />}
        else if(name === BROWSER_TYPES.Edge || name === PLATFORM_TYPES.Windows) {return <Green style={{marginRight: "5px"}} />}
        else if(name === BROWSER_TYPES.Safari || name === PLATFORM_TYPES.Android) {return <Purple style={{marginRight: "5px"}} />}
        else if(name === BROWSER_TYPES.Firefox) {return <Red style={{marginRight: "5px"}} />}
        else if(name === BROWSER_TYPES.MobileApp) {return <Pink style={{marginRight: "5px"}} />}
        else if(name === BROWSER_TYPES.Other) {return <Yellow style={{marginRight: "5px"}} />}
    };

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
            <div className="customTooltip">
                <div>{getIcon(`${payload[0].name}`)}{`${payload[0].name} - ${payload[0].value}`}</div>
            </div>
            );
        }

        return null;
    };

    return(
        <div
            className={styles.panelCard}
            style={{
                maxHeight: !cardCollapsed ? 300 : 48,
                overflow: "hidden"
            }}
        >
            <div className={styles.panelCardHeader} onClick={() => setCardCollapsed(!cardCollapsed)}>
                <div className={styles.panelCardHeaderTitle} tabIndex={0} role={TEXTBOX}>{PARTICIPANTS_PLATFORM_TEXT}</div>
                {cardCollapsed ? <ArrowDown/> : <ArrowUp/>}
            </div>
            <div className={styles.panelCardBody}>
                {(!data || data.total === 0) &&
                    <>
                        <EmptyBox style={{maxHeight: 90, flexShrink: 0, alignSelf: "center", marginTop: 15}}/>
                        <div tabIndex={0} role={TEXTBOX} className={styles.questionBuilderDefaultLabel} style={{marginTop: 10, alignSelf: "center"}}>{NO_PARTICIPANTS_FOUND_TEXT}</div>
                    </>
                }
                {(!!data && data.total !== 0) &&
                    <div
                        style={{
                            justifyContent: "flex-start",
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            gap: 10
                        }}
                    >
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <ShiftPrev tabIndex={collapsed ? -1 : 0} aria-label="previous arrow"
                                       style={{marginRight: "10px", cursor: "pointer"}} onClick={() => {
                                showBrowser ? setShowBrowser(false) : setShowBrowser(true)
                            }}/>
                            <div className="userInfoTitle" tabIndex={collapsed ? -1 : 0}
                                 role={TEXTBOX}>{showBrowser ? "By Browser" : "By Platform"}</div>
                            <ShiftNext tabIndex={collapsed ? -1 : 0} aria-label="next arrow"
                                       style={{marginLeft: "10px", cursor: "pointer"}} onClick={() => {
                                showBrowser ? setShowBrowser(false) : setShowBrowser(true)
                            }}/>
                        </div>
                        {showBrowser && <div style={{display: 'flex', alignItems: "center"}}>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                flex: 1,
                                height: 150,
                                gap: 10
                            }}>
                                <div className="browserText" tabIndex={collapsed ? -1 : 0} role={TEXTBOX}><Blue
                                    style={{marginRight: "5px"}}/>{BROWSER_TYPES.Chrome} - {data.chrome}</div>
                                <div className="browserText" tabIndex={collapsed ? -1 : 0} role={TEXTBOX}><Green
                                    style={{marginRight: "5px"}}/>{BROWSER_TYPES.Edge} - {data.edge}</div>
                                <div className="browserText" tabIndex={collapsed ? -1 : 0} role={TEXTBOX}>
                                    <Purple style={{marginRight: "5px"}}/>{BROWSER_TYPES.Safari} - {data.safari}
                                </div>
                                <div className="browserText" tabIndex={collapsed ? -1 : 0} role={TEXTBOX}><Red
                                    style={{marginRight: "5px"}}/>{BROWSER_TYPES.Firefox} - {data.firefox}</div>
                                <div className="browserText" tabIndex={collapsed ? -1 : 0} role={TEXTBOX}><Pink
                                    style={{marginRight: "5px"}}/>{BROWSER_TYPES.MobileApp} - {data.mobileApp}
                                </div>
                                <div className="browserText" tabIndex={collapsed ? -1 : 0} role={TEXTBOX}>
                                    <Yellow
                                        style={{marginRight: "5px"}}/>{BROWSER_TYPES.Other} - {data.otherBrowser}
                                </div>
                            </div>
                            <ResponsiveContainer width={windowWidth > 1300 ? 150 : 120}
                                                 height={windowWidth > 1300 ? 150 : 120}>
                                <PieChart>
                                    <Pie
                                        data={convertDataBrowser()}
                                        innerRadius={windowWidth > 1300 ? 50 : 40}
                                        outerRadius={windowWidth > 1300 ? 63 : 53}
                                        fill="#8884d8"
                                        paddingAngle={2}
                                        dataKey="number"
                                    >
                                    </Pie>
                                    <Tooltip content={<CustomTooltip/>}/>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>}
                        {!showBrowser && <div style={{display: 'flex', alignItems: "center"}}>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                flex: 1,
                                height: 150,
                                gap: 10
                            }}>
                                <div className="browserText" tabIndex={collapsed ? -1 : 0} role={TEXTBOX}><Blue
                                    style={{marginRight: "5px"}}/>{PLATFORM_TYPES.Apple} - {data.apple}</div>
                                <div className="browserText" tabIndex={collapsed ? -1 : 0} role={TEXTBOX}><Green
                                    style={{marginRight: "5px"}}/>{PLATFORM_TYPES.Windows} - {data.windows}
                                </div>
                                <div className="browserText" tabIndex={collapsed ? -1 : 0} role={TEXTBOX}>
                                    <Purple
                                        style={{marginRight: "5px"}}/>{PLATFORM_TYPES.Android} - {data.android}
                                </div>
                                <div className="browserText" tabIndex={collapsed ? -1 : 0} role={TEXTBOX}>
                                    <Yellow
                                        style={{marginRight: "5px"}}/>{BROWSER_TYPES.Other} - {data.otherPlatform}
                                </div>
                            </div>
                            <ResponsiveContainer width={windowWidth > 1300 ? 150 : 120}
                                                 height={windowWidth > 1300 ? 150 : 120}>
                                <PieChart>
                                    <Pie
                                        data={convertDataPlatform()}
                                        innerRadius={windowWidth > 1300 ? 50 : 40}
                                        outerRadius={windowWidth > 1300 ? 63 : 53}
                                        fill="#8884d8"
                                        paddingAngle={2}
                                        dataKey="number"
                                    >
                                    </Pie>
                                    <Tooltip content={<CustomTooltip/>}/>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                        }
                    </div>
                }
            </div>
        </div>
    );
}
