import React, { useState } from "react";
import {
    ACTIVE_PARTICIPANTS_TEXT, ACTIVITY_FEED_TITLE,
    HAS_JOINED_ATTENDANCE,
    HAS_JOINED_POLL, WAITING_PARTICIPANTS_JOIN_TEXT,
    POLL_TYPE,
    TEXTBOX,
    VIEW_ACTIVITY_TEXT
} from "../../utils/constants";
import { ReactComponent as NotificationIcon } from "./images/NOTIFICATION_ICON.svg";
import { ReactComponent as NoActivityFeedIcon } from "./images/NO_ACTIVITY_FEED.svg";
import { ReactComponent as ArrowDown } from "../../images/YuJa_arrow_down_icon.svg";
import { ReactComponent as ArrowUp } from "../../images/YuJa_arrow_up_icon.svg";
import "./styles.css";
import styles from "./SharePollPage.module.css";

export default function ViewActivityFeed({data=[], localState, collapsed, pollType=POLL_TYPE.GRADED_POLL, isPresentation=false}) {
    const [cardCollapsed, setCardCollapsed] = useState(false);

    return(
        <div
            className={styles.panelCard}
            style={{
                maxHeight: !cardCollapsed ? (isPresentation ? "100%" : 300) : 48,
            }}
        >
            <div className={styles.panelCardHeader} onClick={() => setCardCollapsed(!cardCollapsed)}>
                <div className={styles.panelCardHeaderTitle} tabIndex={0} role={TEXTBOX}>{ACTIVITY_FEED_TITLE}</div>
                {cardCollapsed ? <ArrowDown/> : <ArrowUp/>}
            </div>

            <div className={styles.panelCardBody} style={{overflowY: "auto"}}>
                <span className={styles.panelCardBodySubTitle} tabIndex={ 0} role={TEXTBOX}>{VIEW_ACTIVITY_TEXT} </span>
                {isPresentation &&
                    <div style={{marginTop: 12, textAlign: "center"}}> {ACTIVE_PARTICIPANTS_TEXT}{data && data.students ? data.students.length : ""} </div>
                }

                {(!data.students || !data.students.length) &&
                    <>
                        <NoActivityFeedIcon width={100} height={100} style={{alignSelf: 'center', flexShrink: 0}}/>
                        <span tabIndex={collapsed ? -1 : 0} role={TEXTBOX} style={{alignSelf: 'center'}}>{WAITING_PARTICIPANTS_JOIN_TEXT}</span>
                    </>
                }
                {(!!data.students && !!data.students.length) &&
                    <div className="activityFeedBody">
                        {[...new Map(data.students.map(obj => [obj.userId, obj])).values()].reverse().map((element, index) => {
                            return (<div key={index}>
                                {index === 0 &&
                                    <div className="firstStudent" id={"ActivityFeedQ" + index}>
                                        <div className="activityFeedTab"></div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            flex: 2,
                                            marginRight: "5px"
                                        }}>
                                            <p className="activityFeedNameText">{decodeURI(element.userName)}</p>
                                            <p className="activityFeedSmallText"
                                               tabIndex={collapsed ? -1 : 0}
                                               aria-label={decodeURI(element.userName) + pollType === POLL_TYPE.ATTENDANCE ? HAS_JOINED_ATTENDANCE : HAS_JOINED_POLL}>{pollType === POLL_TYPE.ATTENDANCE ? HAS_JOINED_ATTENDANCE : HAS_JOINED_POLL}</p>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            backgroundColor: "#F2F2F2",
                                            width: "40px",
                                            height: "40px",
                                        }}>
                                            <NotificationIcon/>
                                        </div>
                                    </div>
                                }
                                {index !== 0 &&
                                    <div className="outerStudent" id={"ActivityFeedQ" + index}>
                                        <div className="activityFeedTab"></div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            flex: 2,
                                            marginRight: "5px"
                                        }}>
                                            <p className="activityFeedNameText">{decodeURI(element.userName)}</p>
                                            <p className="activityFeedSmallText"
                                               tabIndex={collapsed ? -1 : 0}
                                               aria-label={decodeURI(element.userName) + pollType === POLL_TYPE.ATTENDANCE ? HAS_JOINED_ATTENDANCE : HAS_JOINED_POLL}>{pollType === POLL_TYPE.ATTENDANCE ? HAS_JOINED_ATTENDANCE : HAS_JOINED_POLL}</p>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            backgroundColor: "#F2F2F2",
                                            width: "40px",
                                            height: "40px",
                                        }}>
                                            <NotificationIcon/>
                                        </div>
                                    </div>
                                }
                            </div>);
                        })}

                    </div>
                }
            </div>
        </div>
    );
}
