import {useHistory, useLocation, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import { ReactComponent as ARROW_RIGHT } from "../../images/arrow_right.svg";
import {MonthPicker} from "./MonthPicker";
import {ReactComponent as POLL_ICON}  from "./images/MetricIconPoll.svg";
import {ReactComponent as ATTENDANCE_ICON}  from "./images/MetricIconAttendance.svg";
import {ReactComponent as RESPONSE_ICON}  from "./images/MetricIconResponse.svg";
import {ReactComponent as PARTICIPANT_ICON}  from "./images/MetricIconParticipant.svg";
import {ReactComponent as LINE_CHART_ICON}  from "./images/LineChartCourseIcon.svg";
import {ReactComponent as TABLE_ICON}  from "./images/ChartIcon.svg";

import {ReportApiCalls} from "../../apis/ReportApiCalls";
import {
    POLL_TYPE,
    REPORT_ATTENDANCE_METRIC_TITLE, REPORT_COURSE_LINE_CHART_DESC,
    REPORT_COURSE_LINE_CHART_TITLE,
    REPORT_COURSE_PAGE_TAB_KEY,
    REPORT_COURSE_TABLE_DESC,
    REPORT_COURSE_TABLE_TITLE,
    REPORT_COURSE_TITLE, REPORT_COURSE_TITLE_DESC,
    REPORT_DATE_FILTER_LABEL,
    REPORT_INSTITUTION_LINE_CHART_DIAGRAM_LABEL_ATTENDANCES,
    REPORT_INSTITUTION_LINE_CHART_DIAGRAM_LABEL_OVERALL,
    REPORT_INSTITUTION_LINE_CHART_DIAGRAM_LABEL_POLLS,
    REPORT_PARTICIPANT_METRIC_TITLE,
    REPORT_POLL_METRIC_TITLE,
    REPORT_RESPONSE_METRIC_TITLE, ROLES,
} from "../../utils/constants";
import classes from "./Report.module.css";
import {YuJaCheckbox} from "../../components/standardization/YuJaCheckbox";
import {CartesianGrid, Label, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {
    getFullMonthYear,
    getMetricBGColorByCount,
    getMetricColorByCount,
    getShortMonthYear,
    getTrendByCount, getYearMonthStr, isCountIncreased
} from "./ReportHelper";
import TabActivities from "./TabActivities";
import {CourseApiCalls} from "../../apis/CourseApiCalls";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import {LineChartTooltip} from "./LineChartTooltip";
import {LineChartTick} from "./LineChartTick";
import {useReportMetricCardDivider} from "../../hooks/useReportMetricCardDivider";
import {useLocalStorageNew} from "../../hooks/useLocalStorageNew";
import useWindowDimensions from "../../hooks/useWindowDimensions";


export default function ReportCoursePage(props) {
    const {getCourseWiseReport} = ReportApiCalls();
    const {getCourseById} = CourseApiCalls();
    let history = useHistory();
    const { courseId } = useParams();
    const location = useLocation();
    let {width: windowWidth} = useWindowDimensions();
    const [, getSession] = useLocalStorageNew("session", {});

    const [course, setCourse] = useState({})
    const [reportStartDate, setReportStartDate] = useState(location.state.reportStartDate);
    const [reportEndDate, setReportEndDate] = useState(location.state.reportEndDate);
    const [curDate, ] = useState(() =>{
        let currentDate = new Date();
        currentDate.setDate(1);
        return getYearMonthStr(currentDate);
    });
    const [showOverallLine, setShowOverallLine] = useState(true);
    const [showPollLine, setShowPollLine] = useState(true);
    const [showAttendanceLine, setShowAttendanceLine] = useState(true);
    const [activeTab, setActiveTab] = useState(REPORT_COURSE_PAGE_TAB_KEY.POLLS);
    const [pollData, setPollData] = useState([]);
    const [attendanceData, setAttendanceData] = useState([]);
    const [lastMonthCount, setLastMonthCount] = useState({});
    const [overviewCount, setOverviewCount] = useState({});
    const [lineChartData, setLineChartData] = useState([]);
    const [chartDiagramWidth, setChartDiagramWidth] = useState(45);
    const [dataLoading, setDataLoading] = useState(false);
    const [courseLoading, setCourseLoading] = useState(false);
    const [lastMonthDisplay, setLastMonthDisplay] = useState("");
    const [activeLabel, setActiveLabel] = useState("");
    const containerRef = useRef();
    let {maxCardsPerRow, lineChartWidth} = useReportMetricCardDivider(containerRef, 4, lineChartData);
    const [isCourserOwner, setIsCourserOwner] = useState(false);

    useEffect(() => {
        props.setPageIdentifier(REPORT_COURSE_TITLE);
    }, []);

    useEffect(() => {
        if (!reportStartDate || !reportEndDate) {
            return;
        }

        setDataLoading(true);
        const {role=""} = getSession();
        let isPersonal = role === ROLES.CREATOR.value;
        getCourseWiseReport(courseId, reportStartDate, reportEndDate, isPersonal).then(result => {
            const {
                pollCount=0, attendanceCount=0, questionCount=0, responseCount=0, participantCount=0,
                monthsChartCount={},
                lastMonthCount={},
                activityData=[],
            } = result;
            setOverviewCount({pollCount, attendanceCount, questionCount, responseCount, participantCount, activityCount: pollCount + attendanceCount});
            setLastMonthCount(lastMonthCount);

            let pollArr = [];
            let attendanceArr = [];
            let pollIdx = 1;
            let attendanceIdx = 1;
            for (const activity of activityData) {
                const {pollType, GSI4SK} = activity;
                if (pollType === POLL_TYPE.ATTENDANCE) {
                    activity.index = attendanceIdx++;
                    activity.date = new Date(GSI4SK).toISOString().split('T')[0];
                    attendanceArr.push(activity);
                } else {
                    activity.index = pollIdx++;
                    activity.date = new Date(GSI4SK).toISOString().split('T')[0];
                    pollArr.push(activity);
                }
            }


            setPollData(pollArr);
            setAttendanceData(attendanceArr);
            setLineChartData(convertLineChartData(monthsChartCount));
        }).finally(() => {
            setDataLoading(false);
        });
    }, [courseId, reportStartDate, reportEndDate]);

    useEffect(() => {
        if (!!overviewCount.activityCount) {
            const str = overviewCount.activityCount.toString();
            if (str.length > 2) {
                setChartDiagramWidth(45 + (str.length - 2) * 8);
            } else {
                setChartDiagramWidth(45);
            }
        } else {
            setChartDiagramWidth(45);
        }
    }, [overviewCount]);

    useEffect(() => {
        if (windowWidth < 1400 ) {
            setLastMonthDisplay(` in ${getShortMonthYear(reportEndDate)}`);
        } else {
            setLastMonthDisplay(` in ${getFullMonthYear(reportEndDate)}`);
        }
    }, [reportEndDate, windowWidth]);


    useEffect(() => {
        setCourseLoading(true);
        getCourseById(courseId).then((res) => {
            let course = !!res?.result ? res.result : {};
            const {userId=""} = getSession();
            setCourse(course);
            setIsCourserOwner(userId === course.ownerId);
        }).finally(() => {
            setCourseLoading(false);
        });
    }, [courseId]);


    const convertLineChartData = (monthsChartCount={}) => {
        const lineChartData = []
        for (const yearMonth in monthsChartCount) {
            const {totalCount=0, pollCount=0, attendanceCount=0} = monthsChartCount[yearMonth];
            lineChartData.push({name: getShortMonthYear(yearMonth), Overall: totalCount, Polls: pollCount, Attendances: attendanceCount});
        }
        return lineChartData;
    }

    const viewCourse = () => {
        if (!isCourserOwner) {
            return;
        }
        history.push({
            pathname: `/course/content/${courseId}`,
        });
    }

    return (
        <div className={classes.parentContainer} ref={containerRef}>
            <div className={classes.titleCard} style={windowWidth < 800 ? {flexDirection: "column"} : {}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <span className={classes.title} tabIndex={0}>{REPORT_COURSE_TITLE}</span>
                    <span style={{height: 15}}/>
                    {!courseLoading
                        ?
                        <span
                            onClick={viewCourse}
                            className={isCourserOwner ? classes.clickableCourseInfoLabel : classes.courseInfoLabel}
                            tabIndex={0}
                        >
                            {course.courseName} - {course.courseCode}
                        </span>
                        : <Skeleton borderRadius={10} height={16} inline={true} width={"100%"}
                                    className={classes.cardNumberSkeleton}
                                    containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                    }
                    <span style={{height: 15}}/>
                    <span className={classes.description} tabIndex={0}>{REPORT_COURSE_TITLE_DESC}</span>
                </div>
                <div style={windowWidth < 800 ? {height: 20} : {flex: 1}}/>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <div className={classes.filterController}>
                        <span className={classes.regularLabel} style={{marginRight: 5}}
                              tabIndex={0}>{REPORT_DATE_FILTER_LABEL}</span>
                        <MonthPicker dateStr={reportStartDate} setDateStr={setReportStartDate}
                                     maxDateStr={reportEndDate}/>
                        <ARROW_RIGHT style={{marginLeft: 5, marginRight: 5}}/>
                        <MonthPicker dateStr={reportEndDate} setDateStr={setReportEndDate}
                                     minDateStr={reportStartDate} maxDateStr={curDate}/>
                    </div>
                </div>
            </div>

            {/*part1: metric card*/}
            {!dataLoading &&
                <div
                    className={classes.metricCardsContainer}
                    style={{gridTemplateColumns: new Array(maxCardsPerRow).fill("1fr").join(" ")}}
                >
                    {/*POLL*/}
                    <div className={`${classes.metricCard} ${classes.firstMetricCard}`}>
                        <POLL_ICON/>
                        <div className={classes.metricCardBody}>
                            <div>
                                <div className={classes.regularLabel} tabIndex={0}>{REPORT_POLL_METRIC_TITLE}</div>
                                <div style={{height: 5}}/>
                                <div className={classes.metric}
                                     tabIndex={0}>{!!overviewCount.pollCount ? overviewCount.pollCount : 0}</div>
                            </div>
                            <div style={{flex: 1}}/>
                            {getTrendByCount(lastMonthCount.pollCount)}
                        </div>
                        <div className={classes.metricCardDivideLine}/>
                        <div className={classes.metricCardLastMonth}
                             style={{background: getMetricBGColorByCount(lastMonthCount.pollCount)}} tabIndex={0}>
                        <span className={classes.metricCardLastMonthMetric}
                              style={{color: getMetricColorByCount(lastMonthCount.pollCount)}}>
                            {isCountIncreased(lastMonthCount.pollCount) === false ? "-" : "+"}
                            {!!lastMonthCount.pollCount ? lastMonthCount.pollCount : 0}
                        </span>
                            {lastMonthDisplay}
                        </div>
                    </div>
                    {/*ATTENDANCE*/}
                    <div className={`${classes.metricCard} ${classes.secondMetricCard}`}>
                        <ATTENDANCE_ICON/>
                        <div className={classes.metricCardBody}>
                            <div>
                                <div className={classes.regularLabel}
                                     tabIndex={0}>{REPORT_ATTENDANCE_METRIC_TITLE}</div>
                                <div style={{height: 5}}/>
                                <div className={classes.metric}
                                     tabIndex={0}>{!!overviewCount.attendanceCount ? overviewCount.attendanceCount : 0}</div>
                            </div>
                            <div style={{flex: 1}}/>
                            {getTrendByCount(lastMonthCount.attendanceCount)}
                        </div>

                        <div className={classes.metricCardDivideLine}/>
                        <div className={classes.metricCardLastMonth}
                             style={{background: getMetricBGColorByCount(lastMonthCount.attendanceCount)}} tabIndex={0}>
                        <span className={classes.metricCardLastMonthMetric}
                              style={{color: getMetricColorByCount(lastMonthCount.attendanceCount)}}>
                            {isCountIncreased(lastMonthCount.attendanceCount) === false ? "-" : "+"}
                            {!!lastMonthCount.attendanceCount ? lastMonthCount.attendanceCount : 0}
                        </span>
                            {lastMonthDisplay}
                        </div>
                    </div>

                    {/*RESPONSE*/}
                    <div className={`${classes.metricCard} ${classes.fourthMetricCard}`}>
                        <RESPONSE_ICON/>
                        <div className={classes.metricCardBody}>
                            <div>
                                <div className={classes.regularLabel} tabIndex={0}>{REPORT_RESPONSE_METRIC_TITLE}</div>
                                <div style={{height: 5}}/>
                                <div className={classes.metric}
                                     tabIndex={0}>{!!overviewCount.responseCount ? overviewCount.responseCount : 0}</div>
                            </div>
                            <div style={{flex: 1}}/>
                            {getTrendByCount(lastMonthCount.responseCount)}
                        </div>

                        <div className={classes.metricCardDivideLine}/>
                        <div className={classes.metricCardLastMonth}
                             style={{background: getMetricBGColorByCount(lastMonthCount.responseCount)}} tabIndex={0}>
                        <span className={classes.metricCardLastMonthMetric}
                              style={{color: getMetricColorByCount(lastMonthCount.responseCount)}}>
                            {isCountIncreased(lastMonthCount.responseCount) === false ? "-" : "+"}
                            {!!lastMonthCount.responseCount ? lastMonthCount.responseCount : 0}
                        </span>
                            {lastMonthDisplay}
                        </div>
                    </div>

                    {/*PARTICIPANT*/}
                    <div className={`${classes.metricCard} ${classes.fifthMetricCard}`}>
                        <PARTICIPANT_ICON/>
                        <div className={classes.metricCardBody}>
                            <div>
                                <div className={classes.regularLabel}
                                     tabIndex={0}>{REPORT_PARTICIPANT_METRIC_TITLE}</div>
                                <div style={{height: 5}}/>
                                <div className={classes.metric}
                                     tabIndex={0}>{!!overviewCount.participantCount ? overviewCount.participantCount : 0}</div>
                            </div>
                            <div style={{flex: 1}}/>
                            {getTrendByCount(lastMonthCount.participantCount)}
                        </div>

                        <div className={classes.metricCardDivideLine}/>
                        <div className={classes.metricCardLastMonth}
                             style={{background: getMetricBGColorByCount(lastMonthCount.participantCount)}}
                             tabIndex={0}>
                        <span className={classes.metricCardLastMonthMetric}
                              style={{color: getMetricColorByCount(lastMonthCount.participantCount)}}>
                            {isCountIncreased(lastMonthCount.participantCount) === false ? "-" : "+"}
                            {!!lastMonthCount.participantCount ? lastMonthCount.participantCount : 0}
                        </span>
                            {lastMonthDisplay}
                        </div>
                    </div>
                </div>
            }
            {!!dataLoading &&
                <div
                    className={classes.metricCardsContainer}
                    style={{gridTemplateColumns: new Array(maxCardsPerRow).fill("1fr").join(" ")}}
                >
                    {/*POLL*/}
                    <div className={`${classes.metricCard} ${classes.firstMetricCard}`}>
                        <POLL_ICON/>
                        <Skeleton borderRadius={10} height={40} inline={true} width={"50%"}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                        <div className={classes.metricCardDivideLine}/>
                        <Skeleton borderRadius={10} height={29} inline={true} width={"100%"}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                    </div>
                    {/*ATTENDANCE*/}
                    <div className={`${classes.metricCard} ${classes.secondMetricCard}`}>
                        <ATTENDANCE_ICON/>
                        <Skeleton borderRadius={10} height={40} inline={true} width={"50%"}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                        <div className={classes.metricCardDivideLine}/>
                        <Skeleton borderRadius={10} height={29} inline={true} width={"100%"}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                    </div>

                    {/*RESPONSE*/}
                    <div className={`${classes.metricCard} ${classes.fourthMetricCard}`}>
                        <RESPONSE_ICON/>
                        <Skeleton borderRadius={10} height={40} inline={true} width={"50%"}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                        <div className={classes.metricCardDivideLine}/>
                        <Skeleton borderRadius={10} height={29} inline={true} width={"100%"}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                    </div>

                    {/*PARTICIPANT*/}
                    <div className={`${classes.metricCard} ${classes.fifthMetricCard}`}>
                        <PARTICIPANT_ICON/>
                        <Skeleton borderRadius={10} height={40} inline={true} width={"50%"}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                        <div className={classes.metricCardDivideLine}/>
                        <Skeleton borderRadius={10} height={29} inline={true} width={"100%"}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                    </div>
                </div>
            }

            {/*part2: line chart*/}
            {!dataLoading &&
                <div className={classes.lineChartCardContainer}>
                    <div className={classes.cardContainerHead} tabIndex={0}>
                        <LINE_CHART_ICON/>
                        {REPORT_COURSE_LINE_CHART_TITLE}
                    </div>
                    <div className={classes.cardContainerBody}>
                        <div className={classes.description} tabIndex={0}>{REPORT_COURSE_LINE_CHART_DESC}</div>
                        <div style={ {display: "flex", alignItems: "center", flex: 1, columnGap: 40, rowGap: 20, flexWrap: "wrap"}}>
                            <div className={classes.chartControllerContainer}>
                                <div className={classes.lineChartControllerRow}>
                                    <YuJaCheckbox
                                        ariaLabel={"Overall-checkbox"}
                                        checked={showOverallLine}
                                        onClick={() => setShowOverallLine(!showOverallLine)}
                                        size={17}
                                    />
                                    <div className={classes.overallDiagramDot}/>
                                    <div className={classes.description}
                                         tabIndex={0}>{REPORT_INSTITUTION_LINE_CHART_DIAGRAM_LABEL_OVERALL}</div>
                                    <div style={{flex: 1}}/>
                                    <div className={classes.overallDiagramCountLabel} tabIndex={0}
                                         style={{width: chartDiagramWidth}}>
                                        {overviewCount.activityCount ? overviewCount.activityCount : 0}
                                    </div>
                                </div>

                                <div style={{height: 1, backgroundColor: "#858687"}}/>

                                <div className={classes.lineChartControllerRow}>
                                    <YuJaCheckbox
                                        ariaLabel={"Overall-checkbox"}
                                        checked={showPollLine}
                                        onClick={() => setShowPollLine(!showPollLine)}
                                        size={17}
                                    />
                                    <div className={classes.pollDiagramDot}/>
                                    <div className={classes.description}
                                         tabIndex={0}>{REPORT_INSTITUTION_LINE_CHART_DIAGRAM_LABEL_POLLS}</div>
                                    <div style={{flex: 1}}/>
                                    <div className={classes.pollDiagramCountLabel} tabIndex={0}
                                         style={{width: chartDiagramWidth}}>
                                        {!!overviewCount.pollCount ? overviewCount.pollCount : 0}
                                    </div>
                                </div>

                                <div className={classes.lineChartControllerRow}>
                                    <YuJaCheckbox
                                        ariaLabel={"Overall-checkbox"}
                                        checked={showAttendanceLine}
                                        onClick={() => setShowAttendanceLine(!showAttendanceLine)}
                                        size={17}
                                    />
                                    <div className={classes.attendanceDiagramDot}/>
                                    <div className={classes.description}
                                         tabIndex={0}>{REPORT_INSTITUTION_LINE_CHART_DIAGRAM_LABEL_ATTENDANCES}</div>
                                    <div style={{flex: 1}}/>
                                    <div className={classes.attendanceDiagramCountLabel} tabIndex={0}
                                         style={{width: chartDiagramWidth}}>
                                        {!!overviewCount.attendanceCount ? overviewCount.attendanceCount : 0}
                                    </div>
                                </div>
                            </div>
                            <div className={classes.chartContainer}>
                                <ResponsiveContainer height={windowWidth > 1200 ? 400 : 300}>
                                    <LineChart data={lineChartData} {...{overflow: 'visible'}} >
                                        <CartesianGrid strokeDasharray="6 6" stroke={"#C4C4C4"}/>
                                        <XAxis dataKey="name" axisLine={false} interval={0}
                                               strokeWidth={2} strokeDasharray={6} stroke={"#737373"}
                                               tick={(props) => LineChartTick({...props, activeLabel, windowWidth})}
                                               tickMargin={10}
                                        >
                                            <Label style={{fontSize: 15}} value="Month" position="bottom" offset={35}/>
                                        </XAxis>
                                        <YAxis name="month" style={{fontSize: 15}} axisLine={false} interval={0}
                                               strokeWidth={2} strokeDasharray={6} stroke={"#737373"}
                                               tickMargin={10}
                                        >
                                            <Label angle={270} value="Total Activities" position="left" offset={20}
                                                   style={{fontSize: 15}}/>
                                        </YAxis>
                                        <Tooltip
                                            allowEscapeViewBox={{x: true}}
                                            position={{y: 20}}
                                            content={(props) =>
                                                LineChartTooltip({...props, setActiveLabel, labelArr: lineChartData.map(i => i.name)})
                                            }
                                            cursor={{stroke: "#2C2C2C", strokeWidth: 2, strokeDasharray: 6}}
                                        />

                                        <Line type="monotone" dataKey="Overall" stroke="#4F75CB" strokeWidth={2}
                                              activeDot={{
                                                  stroke: '#4F75CB',
                                                  fill: '#FFFFFF',
                                                  strokeWidth: 3,
                                                  r: 10
                                              }}
                                              hide={!showOverallLine}
                                              dot={false}/>

                                        <Line type="monotone" dataKey="Polls" stroke="#E2726E" strokeWidth={2}
                                              activeDot={{
                                                  stroke: '#E2726E',
                                                  fill: '#FFFFFF',
                                                  strokeWidth: 3,
                                                  r: 10
                                              }}
                                              hide={!showPollLine}
                                              dot={false}/>

                                        <Line type="monotone" dataKey="Attendances" stroke="#6DA186" strokeWidth={2}
                                              activeDot={{
                                                  stroke: '#6DA186',
                                                  fill: '#FFFFFF',
                                                  strokeWidth: 3,
                                                  r: 10
                                              }}
                                              hide={!showAttendanceLine}
                                              dot={false}/>

                                    </LineChart>
                                </ResponsiveContainer>
                            </div>

                        </div>
                    </div>
                </div>
            }
            {!!dataLoading &&
                <div className={classes.lineChartCardContainer}>
                    <div className={classes.cardContainerHead} tabIndex={0}>
                        <LINE_CHART_ICON/>
                        {REPORT_COURSE_LINE_CHART_TITLE}
                    </div>
                    <div className={classes.cardContainerBody}>
                        <div style={{display: "flex", alignItems: "center", flex: 1, gap: 40}}>
                            <div className={classes.chartControllerContainer}>
                                <Skeleton borderRadius={10} height={25} inline={true} width={200}
                                          className={classes.cardNumberSkeleton}
                                          containerClassName={classes.cardNumberSkeletonWrapper}
                                />

                                <div style={{height: 1, backgroundColor: "transparent"}}/>
                                <Skeleton borderRadius={10} height={25} inline={true} width={200}
                                          className={classes.cardNumberSkeleton}
                                          containerClassName={classes.cardNumberSkeletonWrapper}
                                />
                                <Skeleton borderRadius={10} height={25} inline={true} width={200}
                                          className={classes.cardNumberSkeleton}
                                          containerClassName={classes.cardNumberSkeletonWrapper}
                                />
                            </div>
                            <div className={classes.chartContainer}
                                 style={{gap: 30, flexDirection: "column", justifyContent: "center"}}>
                                <Skeleton borderRadius={10} height={25} inline={true} width={400}
                                          className={classes.cardNumberSkeleton}
                                          containerClassName={classes.cardNumberSkeletonWrapper}
                                />
                                <Skeleton borderRadius={10} height={25} inline={true} width={300}
                                          className={classes.cardNumberSkeleton}
                                          containerClassName={classes.cardNumberSkeletonWrapper}
                                />
                                <Skeleton borderRadius={10} height={25} inline={true} width={200}
                                          className={classes.cardNumberSkeleton}
                                          containerClassName={classes.cardNumberSkeletonWrapper}
                                />
                                <Skeleton borderRadius={10} height={25} inline={true} width={300}
                                          className={classes.cardNumberSkeleton}
                                          containerClassName={classes.cardNumberSkeletonWrapper}
                                />
                                <Skeleton borderRadius={10} height={25} inline={true} width={500}
                                          className={classes.cardNumberSkeleton}
                                          containerClassName={classes.cardNumberSkeletonWrapper}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }

            {/* part3: table*/}
            {!dataLoading &&
                <div className={classes.tableCardContainer}>
                    <div className={classes.cardContainerHead}>
                        <TABLE_ICON/>
                        {REPORT_COURSE_TABLE_TITLE}
                    </div>
                    <div className={classes.cardContainerBody}>
                        <div className={classes.description}>{REPORT_COURSE_TABLE_DESC}</div>
                        <div className={classes.tabsContainer}>
                            <div
                                onClick={() => setActiveTab(REPORT_COURSE_PAGE_TAB_KEY.POLLS)}
                                className={activeTab === REPORT_COURSE_PAGE_TAB_KEY.POLLS ? classes.activeTab : classes.tab}
                            >
                                {REPORT_COURSE_PAGE_TAB_KEY.POLLS}
                            </div>
                            <div
                                onClick={() => setActiveTab(REPORT_COURSE_PAGE_TAB_KEY.ATTENDANCES)}
                                className={activeTab === REPORT_COURSE_PAGE_TAB_KEY.ATTENDANCES ? classes.activeTab : classes.tab}
                            >
                                {REPORT_COURSE_PAGE_TAB_KEY.ATTENDANCES}
                            </div>
                        </div>
                        {activeTab === REPORT_COURSE_PAGE_TAB_KEY.POLLS &&
                            <TabActivities data={pollData} setData={setPollData} isPoll={true}/>
                        }

                        {activeTab === REPORT_COURSE_PAGE_TAB_KEY.ATTENDANCES &&
                            <TabActivities data={attendanceData} setData={setAttendanceData} isPoll={false}/>
                        }

                    </div>
                </div>
            }
            {!!dataLoading &&
                <div className={classes.tableCardContainer}>
                    <div className={classes.cardContainerHead}>
                        <TABLE_ICON/>
                        {REPORT_COURSE_TABLE_TITLE}
                    </div>
                    <div className={classes.cardContainerBody}>
                        <Skeleton borderRadius={10} height={30} inline={true} width={350}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                        <Skeleton borderRadius={10} height={30} inline={true} width={300}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                        <Skeleton borderRadius={10} height={30} inline={true} width={"100%"}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                        <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                            <div style = {{display: "grid", gridTemplateColumns: "15% 25% 15% 15% 15% 15%"}}>
                                <Skeleton borderRadius={10} height={30} width={"30%"} style = {{position: "relative"}} />
                                <Skeleton borderRadius={10} height={30} width={"80%"} style = {{position: "relative"}} />
                                <Skeleton borderRadius={10} height={30} width={"30%"} style = {{position: "relative"}} />
                                <Skeleton borderRadius={10} height={30} width={"30%"} style = {{position: "relative"}} />
                                <Skeleton borderRadius={10} height={30} width={"30%"} style = {{position: "relative"}} />
                                <Skeleton borderRadius={10} height={30} width={"30%"} style = {{position: "relative"}} />
                            </div>
                            <div style = {{ display: "grid", gridTemplateColumns: "15% 25% 15% 15% 15% 15%"}}>
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`} />
                                <Skeleton borderRadius={10} height={25} width={`${70 + Math.random() * 10}%`} />
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`} />
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`} />
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`} />
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`} />

                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`} style = {{marginTop: 15}} />
                                <Skeleton borderRadius={10} height={25} width={`${70 + Math.random() * 10}%`} style = {{marginTop: 15}} />
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`} style = {{marginTop: 15}} />
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`} style = {{marginTop: 15}} />
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`} style = {{marginTop: 15}} />
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`} style = {{marginTop: 15}} />

                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`} style = {{marginTop: 15}} />
                                <Skeleton borderRadius={10} height={25} width={`${70 + Math.random() * 10}%`} style = {{marginTop: 15}} />
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`} style = {{marginTop: 15}} />
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`} style = {{marginTop: 15}} />
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`} style = {{marginTop: 15}} />
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`} style = {{marginTop: 15}} />
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
            }
        </div>
    );

}