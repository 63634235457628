import React, { useCallback, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { UserApiCalls } from "../../apis/UserApiCalls";
import YuJaGeneralAlert from "../../components/modals/YuJaGeneralAlert";
import {
    MENU_LIST,
    DATE_CREATED,
    DEFAULT_ROWS_PER_PAGE,
    DELETE_MULTIPLE_USER_CONFIRMATION,
    DELETE_USER,
    DELETE_USER_DATA_MODAL_BODY,
    DELETE_USER_MODAL_BODY,
    EMAIL,
    FIRST_NAME,
    LAST_ACCESSED,
    LAST_NAME,
    LINKAGE_TYPE,
    LINKAGE_TYPES,
    MODAL_CANCEL_TEXT,
    MODAL_DELETE_TEXT,
    ROLE,
    ROLES,
    TOTAL_INSTRUCTORS,
    TOTAL_IT_MANAGERS,
    TOTAL_STUDENTS,
    TOTAL_USERS,
    USERNAME,
    USERS,
    ACTION_HEADER
} from "../../utils/constants";
import { getPriority, notifyError, roleValuetoDisplay } from "../../utils/helpers";
import { MISSING_USER_ERROR } from "../../utils/toast-message-constants";
import styles from "./ManageUserPage.module.css";
import UserModal from "./UserModal";
import UserSearchForm from "./UserSearchForm";
import { UserTableNew } from "./UserTableNew";
import useMobileAccess from "../../hooks/useMobileAccess";

export default function ManageUserPage(props) {
    const {loadUserRoster, saveUser, deleteUser, deleteSelectedUsers, checkUserExist} = UserApiCalls();
    const [data, setData] = useState([]);
    const [roles, setRoles] = useState([]);
    const [linkageTypes, setLinkageTypes] = useState([]);
    const isNarrow = useMobileAccess(1000);
    const [pageIndex, setPageIndex] = useState(0);
    const [filter, setFilter] = useState({});
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    // const [hovered, setHovered] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDeleteButton, setShowDeleteButton] = useState(false);
    const [uid, setUid] = useState(""); // User ID
    const [iid, setIid] = useState(""); // Institution ID
    const [isNew, setIsNew] = useState(true);
    const [dataReady, setDataReady] = useState(false);
    const [showSeleteDeleteModal, setShowSeleteDeleteModal] = useState(false);
    const [userNameSort, setUserNameSort] = useState(1);
    const [firstNameSort, setFirstNameSort] = useState(1);
    const [lastNameSort, setLastNameSort] = useState(1);
    const [roleSort, setRoleSort] = useState(1);
    const [emailSort, setEmailSort] = useState(1);
    const [linkageTypeSort, setLinkageTypeSort] = useState(1);
    const [dateCreatedSort, setDateCreatedSort] = useState(0);
    const [lastAccessedSort, setLastAccessedSort] = useState(0);
    const [user, setUser] = useState({
        userName: "",
        firstName: "",
        lastName: "",
        role: "",
        email: "",
        linkageType: "",
        updatedTime: "",
        lastLoginTime: ""
    });

    useEffect(() => {
        props.setPageIdentifier(MENU_LIST.USERS.name);
    }, []);

    const loadData = async (pageIndex=0) => {
        let allRoles = [];
        setDataReady(false);
        let res = await loadUserRoster();
        res = res.filter(i => i.role !== ROLES.ADMINISTRATOR.value).sort(function (a, b) {
            if (!a.lastLoginDate) return 1;
            if (!b.lastLoginDate) return -1;
            return new Date(Date.parse(b.lastLoginDate)) - new Date(Date.parse(a.lastLoginDate));
        });
        res.forEach(user => {
            // if (!user.linkageType) user.linkageType = NO_LINKAGE;
            const role = roleValuetoDisplay(user.role);
            if (!!role && !allRoles.includes(role)) allRoles.push(role);
        });
        setRoles(allRoles);
        setLinkageTypes(LINKAGE_TYPES);
        setData(res);
        setPageIndex(pageIndex);
        setDataReady(true);
    };

    const submitUserModal = async (userObj, isNew) => {
        const res = await saveUser(userObj, isNew);
        setShowModal(false);
        if (res) {
            await loadData();
        }
    };

    const clickDelete = async (userId, instId) => {
        // let res;
        setUid(userId);
        if (instId) {
            setIid(instId);
            // if (!window.confirm("Are you sure you want to delete the user and everything associated with this user?")) {
            //     return;
            // }
            // res = await deleteUser(userId, instId); // for lms user
        } else {
            setIid(null);
            // if (!window.confirm("Are you sure you want to delete it?")) {
            //     return;
            // }
            // res = await deleteUser(userId);
        }
        setShowDeleteModal(true);
        // if (res) {
        //     await loadData(pageIndex);
        // }
    };

    const handleDelete = async () => {
        let res;
        // console.log(uid, iid);
        if (iid) {
            res = await deleteUser(uid, iid); // for lms user
        } else {
            res = await deleteUser(uid);
        }
        setShowDeleteModal(false);
        if (res) {
            await loadData(pageIndex);
        }
    };

    const openAddUserModal = useCallback(() => {
        setShowModal(true);
        setIsNew(true);
        setUser({
            userName: "",
            password: "",
            passwordConfirm: "",
            role: "",
            firstName: "",
            middleName: "",
            lastName: "",
            email: "",
            phone: ""
        });
    }, []);

    const openUpdateUserModal = useCallback((existedUser, pageIndex) => {
        setShowModal(true);
        setIsNew(false);
        setUser(existedUser);
        setPageIndex(pageIndex);
    }, []);

    const closeUserModal = useCallback(() => {
        setShowModal(false);
    }, []);

    const handleDeleteSelected = async () => {
        if(selectedUserIds.length <= 0) {
            notifyError(MISSING_USER_ERROR);
            return false;
        }

        // if (!window.confirm(DELETE_MULTIPLE_USER_CONFIRMATION)) {
        //     return;
        // }

        const res = await deleteSelectedUsers(selectedUserIds);
        if (res) {
            await loadData(pageIndex);
        }
        setShowSeleteDeleteModal(false); 
    };

    const deleteSelectedUserConfig = {
        title: DELETE_USER,
        okText: MODAL_DELETE_TEXT,
        cancelText: MODAL_CANCEL_TEXT, 
        submit: () => {
            handleDeleteSelected();
        },
        close:  () => {
            setShowSeleteDeleteModal(false); 
        }, 
    }

    const deleteUserConfig = {
        title: DELETE_USER,
        okText: MODAL_DELETE_TEXT,
        cancelText: MODAL_CANCEL_TEXT, 
        submit: () => {
            handleDelete();
        },
        close:  () => {
            setShowDeleteModal(false);
        }, 
    };

    const handSelectUserId = (userIds, pageIndex) => {
        setSelectedUserIds(userIds);
        setPageIndex(pageIndex);
    };

    useEffect(() => {
        loadData();
    }, []);

    const handleSubmit = useCallback(({ ...filterObj }) => {
        setFilter(filterObj);
        setPageIndex(0);
    }, []);

    useEffect(() => {
        console.log(`filter:${JSON.stringify(filter)}`);
    }, [filter]);

    const columns = [
        { Header: USERNAME, accessor: 'userName', width:  120},
        { Header: FIRST_NAME, accessor: 'firstName', width: 120 },
        { Header: LAST_NAME, accessor: 'lastName', width:  120 },
        { Header: ROLE, accessor: 'role', width: 90},
        { Header: EMAIL, accessor: 'email', width: 180 },
        { Header: LINKAGE_TYPE, accessor: 'linkageType', width: 140 },
        { Header: DATE_CREATED, accessor: 'createdDate', width: 140 },
        { Header: LAST_ACCESSED, accessor: 'lastLoginTime', width: 140 },
        { Header: ACTION_HEADER, accessor: 'action', width: 90}
    ];

    const sortUserName = () => {
        if(!!data) {
            setData(data.sort(function (a, b) {
                const nameA = a.userName?.toLowerCase();
                const nameB = b.userName?.toLowerCase();
    
                const priorityA = getPriority(nameA);
                const priorityB = getPriority(nameB);
    
                if (priorityA < priorityB) return -1 * userNameSort;
                if (priorityA > priorityB) return 1 * userNameSort;
    
                if (nameA < nameB) return -1 * userNameSort;
                if (nameA > nameB) return 1 * userNameSort;
                return 0;
            }));
            setUserNameSort(-userNameSort);
        }
    };

    const sortFirstName = () => {
        if(!!data) {
            setData(data.sort(function (a, b) {
                const nameA = a.firstName?.toLowerCase();
                const nameB = b.firstName?.toLowerCase();

                const priorityA = getPriority(nameA);
                const priorityB = getPriority(nameB);

                if (priorityA < priorityB) return -1 * firstNameSort;
                if (priorityA > priorityB) return 1 * firstNameSort;

                if (nameA < nameB) return -1 * firstNameSort;
                if (nameA > nameB) return 1 * firstNameSort;
                return 0;
            }));
            setFirstNameSort(-firstNameSort);
        }
    };

    const sortLastName = () => {
        if(!!data) {
            setData(data.sort(function (a, b) {
                const nameA = a.lastName?.toLowerCase();
                const nameB = b.lastName?.toLowerCase();

                const priorityA = getPriority(nameA);
                const priorityB = getPriority(nameB);

                if (priorityA < priorityB) return -1 * lastNameSort;
                if (priorityA > priorityB) return 1 * lastNameSort;

                if (nameA < nameB) return -1 * lastNameSort;
                if (nameA > nameB) return 1 * lastNameSort;
                return 0;
            }));
            setLastNameSort(-lastNameSort);
        }
    };

    const sortRole = () => {
        if(!!data) {
            setData(data.sort(function (a, b) {
                const nameA = a.role?.toLowerCase();
                const nameB = b.role?.toLowerCase();
          
                if (nameA < nameB) return -1 * roleSort;
                if (nameA > nameB) return 1 * roleSort;
                return 0;
            }));
            setRoleSort(-roleSort);
        }
    };

    const sortEmail = () => {
        if(!!data) {
            setData(data.sort(function (a, b) {
                const nameA = a.email?.toLowerCase();
                const nameB = b.email?.toLowerCase();

                const priorityA = getPriority(nameA);
                const priorityB = getPriority(nameB);

                if (priorityA < priorityB) return -1 * emailSort;
                if (priorityA > priorityB) return 1 * emailSort;

                if (nameA < nameB) return -1 * emailSort;
                if (nameA > nameB) return 1 * emailSort;

                return 0;
            }));
            setEmailSort(-emailSort);
        }
    };

    const sortLinkageType = () => {
        if(!!data) {
            setData(data.sort(function (a, b) {
                const nameA = a.linkageType?.toLowerCase();
                const nameB = b.linkageType?.toLowerCase();

                const priorityA = getPriority(nameA);
                const priorityB = getPriority(nameB);

                if (priorityA < priorityB) return -1 * linkageTypeSort;
                if (priorityA > priorityB) return 1 * linkageTypeSort;

                if (nameA < nameB) return -1 * linkageTypeSort;
                if (nameA > nameB) return 1 * linkageTypeSort;

                return 0;
            }));
            setLinkageTypeSort(-linkageTypeSort);
        }
    };

    const sortDateCreated = () => {
        if(!!data) {
            if (dateCreatedSort === 0) {
                setData(data.sort(function (a, b) {
                    var aa = a.createdDate.split('-').reverse().join(),
                        bb = b.createdDate.split('-').reverse().join();
                    return aa < bb ? -1 : (aa > bb ? 1 : 0);
                }));
                setDateCreatedSort(1);
            } else {
                setData(data.sort(function (a, b) {
                    var bb = a.createdDate.split('-').reverse().join(),
                        aa = b.createdDate.split('-').reverse().join();
                    return aa < bb ? -1 : (aa > bb ? 1 : 0);
                }));
                setDateCreatedSort(0);
            }
        }
    };

    const sortLastAccessed = () => {
        if(!!data) {
            if (lastAccessedSort === 0) {
                setData(data.sort(function (a, b) {
                    var aa = a.lastLoginTime.split('-').reverse().join(),
                        bb = b.lastLoginTime.split('-').reverse().join();
                    return aa < bb ? -1 : (aa > bb ? 1 : 0);
                }));
                setLastAccessedSort(1);
            } else {
                setData(data.sort(function (a, b) {
                    var bb = a.lastLoginTime.split('-').reverse().join(),
                        aa = b.lastLoginTime.split('-').reverse().join();
                    return aa < bb ? -1 : (aa > bb ? 1 : 0);
                }));
                setLastAccessedSort(0);
            }
        }
    };

    return (<>
            <YuJaGeneralAlert
                show={showSeleteDeleteModal}
                setModalShow={setShowSeleteDeleteModal}
                content={selectedUserIds.length === 1 ? DELETE_USER_DATA_MODAL_BODY : DELETE_MULTIPLE_USER_CONFIRMATION}
                config={deleteSelectedUserConfig}
                AlertType={MODAL_DELETE_TEXT}
            />
            <YuJaGeneralAlert
                show={showDeleteModal}
                setModalShow={setShowDeleteModal}
                content={iid ? DELETE_USER_DATA_MODAL_BODY : DELETE_USER_MODAL_BODY}
                config={deleteUserConfig}
                AlertType={MODAL_DELETE_TEXT}
            />
        {/* <Modal
            id="delete-modal"
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop={loading ? "static" : true}
            // dialogClassName="delete-modal"
        >
            <Spin tip="Loading..." size="large" spinning={loading}>
                <ModalClose onClick={() => setShowDeleteModal(false)} className="modal-close" />
                <Modal.Header style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Modal.Title>
                        {DELETE_USER}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {iid ? DELETE_USER_DATA_MODAL_BODY : DELETE_USER_MODAL_BODY}
                </Modal.Body>
                <Modal.Footer>
                    <button className="modal-button-cancel" onClick={() => setShowDeleteModal(false)}>
                        {MODAL_CANCEL_TEXT}
                    </button>
                    <button
                        className="modal-button-submit"
                        onClick={handleDelete}
                    >
                        {MODAL_DELETE_TEXT}
                    </button>
                </Modal.Footer>
            </Spin>
        </Modal> */}
        <div className={styles.container} id="manage-user-page-container">
            <div className={styles.titleBox}>
                <div className={styles.titleBoxIcon} />
                <div tabIndex={0} className={styles.titleBoxTitle}>{USERS}</div>
            </div>
            {/* <div >
                <Button style={{ marginBottom: "16px" }} title={"Create User"} onClick={openAddUserModal}>Create User</Button>&nbsp;
                <Button style={{ marginBottom: "16px" }} title={"Delete User"} variant="danger"  onClick={handleDeleteSelected}>Delete Users</Button>
            </div> */}
            <div className={styles.cardsContainer}>
                <Card className={styles.card}>
                    {dataReady ?
                        <div className={styles.cardNumber}>{data.length}</div> :
                        <Skeleton borderRadius={10} height={30} inline={true} width={`${2 + (Math.random()*3)}vw`} className={styles.cardNumberSkeleton} containerClassName={styles.cardNumberSkeletonWrapper} />
                    }
                    <div className={styles.cardCaption}>{TOTAL_USERS}</div>
                </Card>
                <Card className={styles.card}>
                    {dataReady ?
                        <div className={styles.cardNumber}>{data.filter(e => e.role === ROLES.IT_MANAGER.value).length}</div> :
                        <Skeleton borderRadius={10} height={30} inline={true} width={`${2 + (Math.random()*3)}vw`} className={styles.cardNumberSkeleton} containerClassName={styles.cardNumberSkeletonWrapper} />
                    }
                    <div className={styles.cardCaption}>{TOTAL_IT_MANAGERS}</div>
                </Card>
                <Card className={styles.card}>
                    {dataReady ? 
                        <div className={styles.cardNumber}>{data.filter(e => e.role === ROLES.CREATOR.value).length}</div> :
                        <Skeleton borderRadius={10} height={30} inline={true} width={`${2 + (Math.random()*3)}vw`} className={styles.cardNumberSkeleton} containerClassName={styles.cardNumberSkeletonWrapper} />
                    }
                    <div className={styles.cardCaption}>{TOTAL_INSTRUCTORS}</div>
                </Card>
                <Card className={styles.card}>
                    {dataReady ?
                        <div className={styles.cardNumber}>{data.filter(e => e.role === ROLES.VIEWER.value).length}</div> :
                        <Skeleton borderRadius={10} height={30} inline={true} width={`${2 + (Math.random()*3)}vw`} className={styles.cardNumberSkeleton} containerClassName={styles.cardNumberSkeletonWrapper} />                    
                    }
                    <div className={styles.cardCaption}>{TOTAL_STUDENTS}</div>
                </Card>
            </div>
            <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <UserSearchForm handleSubmit={handleSubmit} handleCreate={openAddUserModal} handleDelete={() => setShowSeleteDeleteModal(true)} showDeleteButton={showDeleteButton} roles={roles} linkageTypes={linkageTypes} disabled={!dataReady} collapsed={props?.collapsed} />
                {/* <YuJaButton onClick={openAddUserModal} style={{marginLeft: 15}}><PlusIcon style={{width: 20, marginRight: 5}}/>{CREATE_USER}</YuJaButton> */}
            </div>
            {dataReady && <UserTableNew data={data} columns={columns} pageIdx={pageIndex} globalFilterObj={filter} handleSelectUserId={handSelectUserId} openUpdateUserModal={openUpdateUserModal} clickDelete={clickDelete} setShowDeleteButton={setShowDeleteButton} sortUserName={sortUserName} sortFirstName={sortFirstName} sortLastName={sortLastName} sortRole={sortRole} sortEmail={sortEmail} sortLinkageType={sortLinkageType} sortDateCreated={sortDateCreated} sortLastAccessed={sortLastAccessed} userNameSort={userNameSort} firstNameSort={firstNameSort} lastNameSort={lastNameSort} roleSort={roleSort} emailSort={emailSort} linkageTypeSort={linkageTypeSort} dateCreatedSort={dateCreatedSort} lastAccessedSort={lastAccessedSort} />}
            {!dataReady && (
                <div className={styles.skeletonTableContainer}>
                    <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                        <div className={styles.skeletonHeaderContainer}>
                            <Skeleton borderRadius={10} height={20} inline={true} width="1vw" containerClassName={styles.skeletonCheckboxCellWrapper} />
                            <Skeleton borderRadius={10} height={20} inline={true} width="5vw" containerClassName={styles.skeletonCheckboxCellWrapper} />
                            <Skeleton borderRadius={10} height={20} inline={true} width="4vw" containerClassName={styles.skeletonCheckboxCellWrapper} />
                            <Skeleton borderRadius={10} height={20} inline={true} width="4vw" containerClassName={styles.skeletonCheckboxCellWrapper} />
                            <Skeleton borderRadius={10} height={20} inline={true} width="2vw" containerClassName={styles.skeletonCheckboxCellWrapper} />
                            <Skeleton borderRadius={10} height={20} inline={true} width="3vw" containerClassName={styles.skeletonCheckboxCellWrapper} />
                            <Skeleton borderRadius={10} height={20} inline={true} width="5vw" containerClassName={styles.skeletonCheckboxCellWrapper} />
                            <Skeleton borderRadius={10} height={20} inline={true} width="5vw" containerClassName={styles.skeletonCheckboxCellWrapper} />
                            <Skeleton borderRadius={10} height={20} inline={true} width="5vw" containerClassName={styles.skeletonCheckboxCellWrapper} />
                            <Skeleton borderRadius={10} height={20} inline={true} width="5vw" containerClassName={styles.skeletonCheckboxCellWrapper} />
                        </div>
                        <div className={styles.skeletonBodyContainer}>
                        {Array.apply(null, Array(DEFAULT_ROWS_PER_PAGE)).map((_, idx) => {
                            return (<div className={styles.skeletonBodyRow}>
                                <Skeleton borderRadius={10} height={20} inline={true} width="1vw" className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                                <Skeleton borderRadius={10} height={20} inline={true} width={`${4 + (Math.random())}vw`}className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                                <Skeleton borderRadius={10} height={20} inline={true} width={`${3 + (Math.random())}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                                <Skeleton borderRadius={10} height={20} inline={true} width={`${3 + (Math.random())}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                                <Skeleton borderRadius={10} height={20} inline={true} width={`${2 + Math.random()}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                                <Skeleton borderRadius={10} height={20} inline={true} width={`${2 + (Math.random()*3)}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                                <Skeleton borderRadius={10} height={20} inline={true} width="2vw" className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                                <Skeleton borderRadius={10} height={20} inline={true} width="2vw" className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                                <Skeleton borderRadius={10} height={20} inline={true} width="2vw" className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                                <Skeleton borderRadius={10} height={20} inline={true} width="2vw" className={styles.skeletonOpBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                            </div>)
                        })}
                        </div>
                    </SkeletonTheme>
                </div>
            )}
            <UserModal user={user} checkExisted={checkUserExist} submit={submitUserModal} close={closeUserModal} isShow={showModal} isNew={isNew} />
        </div>
    </>);
}
