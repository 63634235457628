import React, { useEffect, useRef, useState } from "react";
// import { Tab, Tabs } from "react-bootstrap";
import * as FileSaver from "file-saver";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import XLSX from "sheetjs-style";
import { InstitutionApiCalls } from "../../apis/InstitutionApiCalls";
import { PollApiCalls } from "../../apis/PollApiCalls";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import GradebookWarningModal from "../../components/modals/GradebookWarningModal";
import YuJaButton from "../../components/standardization/YuJaButton";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import { ReactComponent as EmptyAttendance } from "../../images/attendance_empty.svg";
import { ReactComponent as EmptyGradeBook } from "../../images/gradebook_empty.svg";
import { ReactComponent as SearchIcon } from "../../images/search_bar_icon.svg";
import {
  ABSENT_STATUS,
  ABSENT_TEXT,
  ALL_COURSE_LABEL,
  ALL_STATUS_LABEL,
  ALL_STATUS_OPTION,
  ATTENDANCE,
  ATTENDANCE_FILTER_LABEL,
  ATTENDANCE_GRADEBOOK_DEFAULT_MESSAGE,
  ATTENDANCE_GRADEBOOK_DROPDOWN,
  ATTENDANCE_PLACEHOLDER,
  COURSE_FILTER_LABEL,
  COURSE_PLACEHOLDER,
  MENU_LIST,
  DOWNLOAD_RESULTS_TEXT,
  DRAFT_STATUS,
  EXPORT_GRADEBOOK_DROPDOWN,
  EXPORT_GRADEBOOK_TEXT,
  FIXED_ANSWER,
  GET_RESULT_TEXT,
  GRADEBOOK_DEFAULT_MESSAGE,
  GRADEBOOK_DROPDOWN,
  GRADEBOOK_FILTER_LABEL,
  GRADEBOOK_SEARCH_BOX_PLACEHOLDER,
  GRADEBOOK_SEARCH_BOX_PLACEHOLDER_W_ATTENDANCE_FILTER,
  INTEGRATION_TYPES,
  LMS_EXPORT_PREFIX,
  LMS_EXPORT_SUFFIX,
  MERGED_POLL_GRADEBOOK,
  OVERALL_RESULT_HEADER,
  POLL_TEXT,
  POLL_TITLE_DROPDOWN_LENGTH,
  POLL_TITLE_PREFIX,
  POLL_TYPE,
  PRESENT_STATUS,
  PRESENT_TEXT,
  QUESTION_TYPES,
  SEARCH_GRADEBOOK,
  SEARCH_TEXT,
  STATUS_LABEL,
  SURVEY,
  SYNC_ATTEMPT,
  SYNC_GRADEBOOK_TEXT,
  TEXTBOX,
  USER_ID_HEADER,
  GRADEBOOK
} from "../../utils/constants";
import { getLabelFromIntegrationType, hasWeightage, notifyError, prepareAttemptSubrows, prepareAttendanceSubrows, removePrefix, removePrefixNew } from "../../utils/helpers";
import { LOADING_SEARCH_ERROR, MISSING_ATTENDANCE_FILTER_ERROR, MISSING_OPTION_ERROR } from "../../utils/toast-message-constants";
import styles from "./GradeBook.module.css";
import GradeBookTabs from "./GradeBookTabs";
// import QuestionBreakdown from "./QuestionBreakdown";
// import StudentBreakdown from "./StudentBreakdown";
import 'rc-datepicker/lib/style.css';
import { Form } from "react-bootstrap";
import { CourseApiCalls } from "../../apis/CourseApiCalls";
// import { YuJaDropdown } from "../../components/standardization/YuJaDropdown";
import { YuJaDropdown } from "../../components/standardization/YuJaDropdown";
import { YuJaTextBox } from "../../components/standardization/YuJaTextBox";
import useMobileAccess from "../../hooks/useMobileAccess";
import AttendanceGradeBookTabs from "./AttendanceGradeBookTabs";

export default function GradeBookPage({ setPageIdentifier }) {
  const location = useLocation();
  const { getPollsFromUser, getAttendancesFromCourse } = PollApiCalls();
  const { getResultByGradeBookPerspective, getAttendanceGradebookResult } = ResponseApiCalls();
  const { getSetting } = InstitutionApiCalls();
  const { getCourseByUser } = CourseApiCalls();
  const isNarrow = useMobileAccess(1350);

  const [options, setOptions] = useState([]);
  const [updatedResponses, setUpdatedResponses] = useState([]);
  // const [questionBreakDownData, setQuestionBreakDownData] = useState([]);
  // const [studentBreakDownData, setStudentBreakDownData] = useState([]);
  const [gradeBookData, setGradeBookData] = useState({ responses: [], questions: [] });
  const [selectedOption, setSelectedOption] = useState("");
  const [showTable, setShowTable] = useState(true);
  // const [downloadResultsBtn, setDownloadResultsBut] = useState(false);
  // const [exportGradebookBtn, setExportGradebookBtn] = useState(false);
  const [syncGradebookBtn, setSyncGradebookBtn] = useState(false);
  // const [showSyncOptions, setShowSyncOptions] = useState(false);
  const [autoSyncData, setAutoSyncData] = useState(null);
  const [syncMode, setSyncMode] = useState(0);
  // const [showExportOptions, setShowExportOptions] = useState(false);
  const [exportMode, setExportMode] = useState("");
  // const [fileName, setFileName] = useState("");
  const [excelData, setExcelData] = useState([]);
  const [canvasExcelData, setCanvasExcelData] = useState([]);
  const [blackboardExcelData, setBlackboardExcelData] = useState([]);
  const [, getSession] = useLocalStorageNew("session", {});
  const [integrationType, setIntegrationType] = useState(null);
  const [exportOptions, setExportOptions] = useState([]);
  const [exportOptionsLoading, setExportOptionsLoading] = useState(false);
  const pk = useRef("");
  const pc = useRef("");
  const pollTitle = useRef("");
  const pollType = useRef("");
  const gradeEnabled = useRef(true);
  const lmsAttempt = useRef("");
  const courseId = useRef("");
  // const geofence = useRef("");
  const pollShareMode = useRef("");
  const [pollsLoading, setPollsLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [warningModalShow, setWarningModalShow] = useState(false);
  const [filter, setFilter] = useState({});
  // const [pageIndex, setPageIndex] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [statusFilter, setStatusFilter] = useState(ALL_STATUS_OPTION);
  const [courseOptions, setCourseOptions] = useState([]);
  const [courseFilter, setCourseFilter] = useState(null);
  const [courseReady, setCourseReady] = useState(false);
  const [attendanceFilter, setAttendanceFilter] = useState(null);
  const [loadingCourses, setLoadingCourses] = useState(true);

  const getPlatformSetting = () => {
    return getSetting().then((result) => {
      // console.log(result);
      if (!!result && !!result.LMSQuizAttempts) {
        // console.log(result.LMSQuizAttempts);
        return result.LMSQuizAttempts;
      } else {
        return SYNC_ATTEMPT.FIRST.value; // default
      }
    });
  };

  const getCourses = async () => {
    setLoadingCourses(true);
    const session = getSession();
    let res;
    if (session.idpType && session.intType) {
      res = await getCourseByUser(session.idpType.toLowerCase(), session.intType.toLowerCase());
    } else {
      res = await getCourseByUser();
    }
    res.sort(function (a, b) {
      return new Date(Date.parse(b.updateTime)) - new Date(Date.parse(a.updateTime));
    });

    setCourseOptions(res.map(course => ({
      label: `${course.courseCode} - ${course.courseName}`,
      value: course.courseId,
      ...course
    })));
    setLoadingCourses(false);
  };

  useEffect(() => {
    // console.log(courseOptions, options, courseReady, selectedOption?.courseId);
    if (courseOptions.length && options.length && !courseReady && selectedOption?.courseId) {
      const course = courseOptions.find(course => course.value === removePrefix(selectedOption.courseId));
      setCourseFilter(course);
      setCourseReady(true);
    }
  }, [loadingCourses, selectedOption]);

  const chooseCourse = async (course) => {
    setCourseFilter(course);
    setPollsLoading(true);
    setAttendanceFilter("");
    const res = await getAttendancesFromCourse(course.value);
    // const res = JSON.parse(`[{"pollKey":"P#df854112-ddd0-4cf5-8ebd-b71bd1408391","pollSortKey":"PUB#UC#123428","ownerId":"U#18fbae01-2103-416f-a968-013aa9883c86","ownerIdSortKey":"U#18fbae01-2103-416f-a968-013aa9883c86#PUB#123428","institutionId":"I#b6f0e9d1-1844-4c7c-9b7c-9588e3cb6615#PUB#UC","institutionIdSortKey":"UC#123428","courseId":"C#75a2f056-4b51-4d4d-9700-8ed18159727a","courseCode":"cart","pollTitle":"Attendance - February 21, 2024","courseIdSortKey":"U#572ba87c-3186-4adf-86fe-2f7dd483ab2f#6315a816-6b98-4ece-b5fd-58e1c6c9859b","uniqueCodePK":"UC#123428","updatedDatePK":"21-02-2024","updatedTimeSK":"2024-02-21T21:26:11.422Z","createdDate":"21-02-2024","createdTime":"2024-02-21T16:33:34.032Z","pollType":"ATTENDANCE","showAnswerOnViewer":true,"anonymousJoin":false,"isValid":true,"recordAttempt":"all","lmsAttempt":"highest","maxAttempts":-1,"liveQuestionTime":1708550774422,"totalQuestions":1,"pollState":"SHARED","pollShareMode":"shareEach","pollTimeLimit":6000,"syncLms":true,"geofence":{"lat":43.959151804477024,"lng":-79.18924456253062,"rad":100000},"getReadyAllTimeLimit":6}]`);
    const newOptions = res.map(poll => ({
      label: ((poll.pollTitle.length > POLL_TITLE_DROPDOWN_LENGTH) ? (poll.pollTitle.slice(0, POLL_TITLE_DROPDOWN_LENGTH) + '...') : poll.pollTitle) +
        " (" + removePrefixNew(poll.pollSortKey) + ")",
      value: `${removePrefixNew(poll.pollKey)}#${removePrefixNew(poll.pollSortKey)}`,
      pollTitle: `${poll.pollTitle.trim()}`,
      pollType: `${poll.pollType}`,
      courseId: poll?.courseId,
      inCourse: !!poll.courseId,
      inLmsCourse: !!poll.lmsHomeUrl,
      lmsAttempt: poll.lmsAttempt,
      geofence: poll?.geofence,
      syncLms: poll?.syncLms,
      LMSColumnId: poll?.LMSColumnId
    }));
    setOptions(newOptions);
    setPollsLoading(false);
  };

  const chooseAttendance = (option) => {
    setAttendanceFilter(option);
    setSelectedOption(option);
  };

  useEffect(() => {
    const session = getSession();
    // console.log(session, session?.intType, session?.idpType);
    setIntegrationType(session?.intType);
  }, []);

  useEffect(async () => {
    setPageIdentifier(location?.state?.pollType === POLL_TYPE.ATTENDANCE ? `${ATTENDANCE} ${GRADEBOOK}` : `${MERGED_POLL_GRADEBOOK} ${GRADEBOOK}`);
    if (location && location.state && location.state.option) {
      setPollsLoading(true);
      setDataLoading(true);
      let poll = location.state.option;
      const defaultLmsAttempt = await getPlatformSetting();
      let option = {
        label: ((poll.pollTitle.length > POLL_TITLE_DROPDOWN_LENGTH) ? (poll.pollTitle.slice(0, POLL_TITLE_DROPDOWN_LENGTH) + '...') : poll.pollTitle) +
          " (" + removePrefixNew(poll.pollSortKey) + ")",
        value: `${removePrefixNew(poll.pollKey)}#${removePrefixNew(poll.pollSortKey)}`,
        pollTitle: `${poll.pollTitle.trim()}`,
        pollType: `${poll.pollType}`,
        courseId: poll?.courseId,
        inCourse: !!poll.courseId,
        inLmsCourse: !!poll.lmsHomeUrl,
        pollShareMode: poll.pollShareMode, 
        lmsAttempt: poll.lmsAttempt ? poll.lmsAttempt : defaultLmsAttempt,
        geofence: poll?.geofence,
        syncLms: poll?.syncLms,
        LMSColumnId: poll?.LMSColumnId
      };
      setSyncGradebookBtn(option.inLmsCourse && option.syncLms);
      setSelectedOption(option);
      setAttendanceFilter(option);
      await getResult(option);
      if (!!location.state.autoSync) {
        setAutoSyncData(location.state.autoSync);
        location.state.autoSync = null;
      }
      const res = await getPollsFromUser(true);
      // console.log(defaultLmsAttempt);
      // sort res by last updatedtime
      res.sort((poll1, poll2) => {
        let date1 = new Date(poll1.updatedTimeSK);
        let date2 = new Date(poll2.updatedTimeSK);
        return date2 - date1;
      })

      const newOptions = res.filter(poll => poll.pollSortKey !== DRAFT_STATUS)
        .filter(poll => location.state.option.pollType === POLL_TYPE.ATTENDANCE ? poll.pollType === POLL_TYPE.ATTENDANCE : poll.pollType !== POLL_TYPE.ATTENDANCE)
        .map(poll => ({
          label: ((poll.pollTitle.length > POLL_TITLE_DROPDOWN_LENGTH) ? (poll.pollTitle.slice(0, POLL_TITLE_DROPDOWN_LENGTH) + '...') : poll.pollTitle) +
            " (" + removePrefixNew(poll.pollSortKey) + ")",
          value: `${removePrefixNew(poll.pollKey)}#${removePrefixNew(poll.pollSortKey)}`,
          pollTitle: `${poll.pollTitle.trim()}`,
          pollType: `${poll.pollType}`,
          courseId: poll?.courseId,
          inCourse: !!poll.courseId,
          inLmsCourse: !!poll.lmsHomeUrl,
          pollShareMode: poll.pollShareMode, 
          lmsAttempt: poll.lmsAttempt ? poll.lmsAttempt : defaultLmsAttempt,
          geofence: poll?.geofence,
          syncLms: poll?.syncLms,
          LMSColumnId: poll?.LMSColumnId
        }));
      setOptions(newOptions);
      setPollsLoading(false);
    } else {
      if (!location || !location.state || !location.state.pollKey || !location.state.uniqueCode) {
        setOptions([]);
        setSelectedOption("");
        setShowTable(false);
      }
      setPollsLoading(true);
      const res = await getPollsFromUser(true);
      const defaultLmsAttempt = await getPlatformSetting();
      // console.log(defaultLmsAttempt);
      // sort res by last updatedtime
      res.sort((poll1, poll2) => {
        let date1 = new Date(poll1.updatedTimeSK);
        let date2 = new Date(poll2.updatedTimeSK);
        return date2 - date1;
      })

      const newOptions = res.filter(poll => poll.pollSortKey !== DRAFT_STATUS)
        .filter(poll => location?.state?.pollType === POLL_TYPE.ATTENDANCE ? poll.pollType === POLL_TYPE.ATTENDANCE : poll.pollType !== POLL_TYPE.ATTENDANCE)
        .map(poll => ({
          label: ((poll.pollTitle.length > POLL_TITLE_DROPDOWN_LENGTH) ? (poll.pollTitle.slice(0, POLL_TITLE_DROPDOWN_LENGTH) + '...') : poll.pollTitle) +
            " (" + removePrefixNew(poll.pollSortKey) + ")",
          value: `${removePrefixNew(poll.pollKey)}#${removePrefixNew(poll.pollSortKey)}`,
          pollTitle: `${poll.pollTitle.trim()}`,
          pollType: `${poll.pollType}`,
          courseId: poll?.courseId,
          inCourse: !!poll.courseId,
          inLmsCourse: !!poll.lmsHomeUrl,
          pollShareMode: poll.pollShareMode, 
          lmsAttempt: poll.lmsAttempt ? poll.lmsAttempt : defaultLmsAttempt,
          geofence: poll?.geofence,
          syncLms: poll?.syncLms,
          LMSColumnId: poll?.LMSColumnId
        }));
      setOptions(newOptions);
      setPollsLoading(false);

      if (location && location.state && location.state.pollKey && location.state.uniqueCode) {
        const key = `${location.state.pollKey}#${location.state.uniqueCode}`;
        const option = newOptions.find(ele => ele.value === key);
        if (location?.state?.pollType === POLL_TYPE.ATTENDANCE) {
          chooseAttendance(option);
        } else {
          setSelectedOption(option);
        }

        await getResult(option);
        // setSyncGradebookBtn(!!location.state.syncGradebookBtn ? location.state.syncGradebookBtn : false);
        if (!!location.state.autoSync) {
          setAutoSyncData(location.state.autoSync);
          location.state.autoSync = null;
        }
      }
    }
    if (location?.state?.pollType === POLL_TYPE.ATTENDANCE) {
      await getCourses();
    }
  }, [location]);

  const getAttendanceResult = async () => {
    setSyncGradebookBtn(false);
    if (attendanceFilter) {
      getResult(attendanceFilter);
      setSyncGradebookBtn(attendanceFilter.inLmsCourse && attendanceFilter.syncLms);

      return;
    }

    if (!courseFilter) {
      notifyError(MISSING_ATTENDANCE_FILTER_ERROR);
      return;
    }

    setKeyword("");
    setStatusFilter(null);
    setFilter({keyword: ""});
    setShowTable(true);
    setDataLoading(true);
    setExportOptionsLoading(true);

    let promises = [];
    options.map(key => {
      const arr = key.value.split("#");
      const [pollKey, uniqueCode] = arr;
      pk.current = removePrefix(pollKey);
      pc.current = uniqueCode;
      pollTitle.current = key.pollTitle;
      pollType.current = key.pollType.toUpperCase();
      lmsAttempt.current = key.lmsAttempt;
      courseId.current = key.courseId;
      // geofence.current = key.geofence;
      
      const res = getAttendanceGradebookResult(pollKey, uniqueCode);
      promises.push(res);
    });

    promises = await Promise.all(promises);

    // let questions = [];
    let responses = [];
    // let gradeBookBreakdownResult = {};
    promises.map(res => {
      // questions = questions.concat(res?.questions);
      responses = responses.concat(prepareAttendanceSubrows(res?.responses));
      // gradeBookBreakdownResult = {questions: questions, responses: responses};
    });
    // console.log(gradeBookBreakdownResult);
    setUpdatedResponses(responses);
    // setGradeBookData(gradeBookBreakdownResult);
      
    // setSyncGradebookBtn(true);
    setExportOptions([]);
    setExportOptionsLoading(false);
    setDataLoading(false);
  };

  const getResult = async (key) => {
    setKeyword("");
    setFilter({keyword: ""});
    setShowTable(true);
    setDataLoading(true);
    setExportOptionsLoading(true);
    setSelectedOption(key);
    key = !!key ? key : selectedOption;

    if (!key) {
      notifyError(MISSING_OPTION_ERROR);
      return;
    }
    const arr = key.value.split("#");
    const [pollKey, uniqueCode] = arr;
    pk.current = removePrefix(pollKey);
    pc.current = uniqueCode;
    pollTitle.current = key.pollTitle;
    pollType.current = key.pollType.toUpperCase();
    lmsAttempt.current = key.lmsAttempt;
    courseId.current = key.courseId;
    // geofence.current = key.geofence;
    pollShareMode.current = key.pollShareMode;

    if (pollType.current === POLL_TYPE.ATTENDANCE) {
      gradeEnabled.current = false;
      const res = await getAttendanceGradebookResult(pollKey, uniqueCode);
      setUpdatedResponses(prepareAttendanceSubrows(res?.responses));
      setGradeBookData(res);
      setExportOptions([]);
      setExportOptionsLoading(false);
      setDataLoading(false);
      return;
    }
    const res = await getResultByGradeBookPerspective(pollKey, uniqueCode);
    const {
      gradeBookBreakdownResult,
      // studentBreakdownResult,
      // questionBreakdownResult
    } = res;
    gradeBookBreakdownResult.questions.sort((a, b) => { // sort questions by serialNo
      return a.serialNo - b.serialNo;
    });
    gradeEnabled.current = hasWeightage(gradeBookBreakdownResult.questions) && pollType.current !== POLL_TYPE.SURVEY;
    const responsesWithSubrows = prepareAttemptSubrows(gradeBookBreakdownResult.responses);
    setUpdatedResponses(responsesWithSubrows);
    let pollTitleColumnName = POLL_TITLE_PREFIX + key.pollTitle;
    let excelDataFormat = [], canvasExcelDataFormat = [], blackboardExcelDataFormat = [];
    let numQuestions = gradeBookBreakdownResult.questions.length;
    for (let element of responsesWithSubrows) {
      if (!element.attempts || !element.attempts[0]) console.log("Missing Attempt #0 in ", element);
      let attempt = !!element.attempts && element.attempts[0] ? element.attempts[0] : element;
      // console.log(element, attempt);
      let excelEntry = {
        "Student": element.firstName + (element.lastName ? " " + element.lastName : ""),
        "IP Address": element.ipAddress,
        "Responded By": element.browserType,
        "Platform": element.platform
      };
      let canvasExcelEntry = {
        "Student": (element.lastName ? element.lastName + ", " : "") + element.firstName,
        "ID": element.tpUId,
        "SIS User ID": "",
        "SIS Login ID": "",
        "Section": "",
      };
      let blackboardExcelEntry = {
        "Last Name": element.lastName,
        "First Name": element.firstName,
        "Username": element.loginUsername,
        "Availability": "Yes",
      };
      for (let i = 0; i < numQuestions; i++) {
        let questionNum = "Q" + (i + 1);
        let answerNum = "A" + (i + 1);
        if (pollType.current !== POLL_TYPE.ATTENDANCE && (!gradeBookBreakdownResult.questions[i].weightage || pollType.current === POLL_TYPE.SURVEY)) {
          if (!element[questionNum]) {
            excelEntry[questionNum] = "Not Posted";
            continue;
          }
          if (element[questionNum] === "No Response") {
            excelEntry[questionNum] = "No Response";
            continue;
          }
          // console.log(gradeBookBreakdownResult.questions[i]);
          let questionType = gradeBookBreakdownResult.questions[i].questionType;
          // console.log(questionType);
          // console.log(element[answerNum]);
          if (questionType === QUESTION_TYPES.MCSS.name) {
            if (element[answerNum][0] === FIXED_ANSWER.UNANSWERED) {
              excelEntry[questionNum] = "No Response";
              continue;
            }
            let resp = '';
            element[answerNum].sort((a, b) => a.localeCompare(b)).map((key) => { resp += key.toUpperCase() });
            excelEntry[questionNum] = resp;
          } else if (questionType === QUESTION_TYPES.TF.name) {
            excelEntry[questionNum] = element[answerNum];
          } else if (questionType === QUESTION_TYPES.SA.name) {
            excelEntry[questionNum] = element[answerNum][0];
          } else if (questionType === QUESTION_TYPES.FITB.name) {
            let resp = '';
            Object.keys(element[answerNum]).map((key, index) => {
              // console.log(`${index === 0 ? '' : ', '}${key}: ${element[answerNum][key]}`);
              resp += `${index === 0 ? '' : ', '}${key}: ${element[answerNum][key]}`;
            });
            excelEntry[questionNum] = resp;
          } else if (questionType === QUESTION_TYPES.CI.name) {
            if (element[answerNum].selected === 'other') {
              excelEntry[questionNum] = "other";
              continue;
            }
            excelEntry[questionNum] = String.fromCharCode(65 + parseInt(element[answerNum].selected, 10));
          } else if (questionType === QUESTION_TYPES.WC.name) {
            let resp = '';
            Object.values(element[answerNum]).map((value, index) => {
              resp += `${index === 0 ? '' : ', '}${value}`;
            });
            excelEntry[questionNum] = resp;
          } else if (questionType === QUESTION_TYPES.RK.name) {
            let resp = '';
            element[answerNum].map((value, index) => {
              resp += `Rank ${index + 1} : ${value} `;
            })
            excelEntry[questionNum] = resp;
          } else if (questionType === QUESTION_TYPES.OE.name) {
            excelEntry[questionNum] = element[answerNum][0];
          } else {
            excelEntry[questionNum] = "Unknown Q Type" + questionType;
          }
          // console.log(excelEntry[questionNum]);
        } else {
          excelEntry[questionNum] = element[questionNum] ? element[questionNum] : "Not Posted";
        }
      }
      if (gradeEnabled.current) {
        // console.log(element.score);
        const score = attempt.score && attempt.score.includes("%") ? attempt.score.substring(0, attempt.score.length - 1) : attempt.score;
        if (courseId.current === null || courseId.current === undefined) {
          excelEntry[OVERALL_RESULT_HEADER] = score;
        } else {
          blackboardExcelEntry[key.pollTitle] = score;
          excelEntry[USER_ID_HEADER] = element.tpUId;
          excelEntry[pollTitleColumnName] = score;
          canvasExcelEntry[key.pollTitle] = score;
        }
      }
      // console.log(excelEntry);
      excelDataFormat.push(excelEntry);
      canvasExcelDataFormat.push(canvasExcelEntry);
      blackboardExcelDataFormat.push(blackboardExcelEntry);
    }
    setGradeBookData(gradeBookBreakdownResult);
    // setStudentBreakDownData(studentBreakdownResult);
    // setQuestionBreakDownData(questionBreakdownResult);
    setSyncGradebookBtn(gradeEnabled.current && (!!key.inLmsCourse || !!key.LMSColumnId));
    // console.log(excelDataFormat);
    setDataLoading(false);
    setExcelData(excelDataFormat);
    setCanvasExcelData(canvasExcelDataFormat);
    setBlackboardExcelData(blackboardExcelDataFormat);
    // setFileName(selectedOption.pollTitle + " Class Results");
    let options = [];
    options.push({ value: "", label: DOWNLOAD_RESULTS_TEXT });
    if (!gradeEnabled.current || courseId.current === null || courseId.current === undefined) {
      // setDownloadResultsBut(gradeBookBreakdownResult.responses.length > 0);
      // setExportGradebookBtn(false);
    } else {
      // setDownloadResultsBut(false);
      // setExportGradebookBtn(gradeBookBreakdownResult.responses.length > 0);
      if (!!integrationType) {
        options.push({ value: integrationType, label: LMS_EXPORT_PREFIX + getLabelFromIntegrationType(integrationType) + LMS_EXPORT_SUFFIX });
      }
    }
    setExportOptions(options);
    setExportOptionsLoading(false);
  };

  const exportExcel = async (integration = "") => {
    setExportMode(integration);
    // const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    // const fileExtension = ".csv";
    // const wb = { Sheets: {'data':ws }, SheetNames: ['data']};
    // const excelBuffer = XLSX.write(wb, { booktype: "xlsx", type: "array"});
    // const data = new Blob([excelBuffer], { type: fileType});
    // FileSaver.saveAs(data, fileName + fileExtension);
    const key = selectedOption;
    const arr = key.value.split("#");
    const [pollKey, uniqueCode] = arr;
    pk.current = removePrefix(pollKey);
    pc.current = uniqueCode;
    pollTitle.current = key.pollTitle;
    pollType.current = key.pollType.toUpperCase();
    lmsAttempt.current = key.lmsAttempt;
    // console.log(lmsAttempt.current);

    // const pollRecord = await getPollRecordFromUniqueCode(uniqueCode);
    // const courseId = pollRecord && pollRecord.poll ? pollRecord.poll.index2Pk : null;
    if (integration === "" || !gradeEnabled.current || courseId.current === undefined || courseId.current === null) {
      handleExportFromModal();
    } else {
      setWarningModalShow(true);
    }
  };

  const handleExportFromModal = () => {
    const fileType = "application/octet-stream";
    const fileExtension = ".csv";
    const ws = XLSX.utils.json_to_sheet(exportMode === INTEGRATION_TYPES.CANVAS ? canvasExcelData : exportMode === INTEGRATION_TYPES.BLACKBOARD ? blackboardExcelData : excelData);
    const csvOutput = XLSX.utils.sheet_to_csv(ws);
    function s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }

    const data = new Blob([s2ab(csvOutput)], { type: fileType });
    FileSaver.saveAs(data, pollTitle.current + " Class Results" + fileExtension);
    setWarningModalShow(false);
  };

  const clickSearch = ({
    key = keyword,
    // course = courseFilter, attendance = attendanceFilter
  }) => {
    if (pollsLoading) {
      notifyError(LOADING_SEARCH_ERROR);
      return;
    }

    setFilter({
      keyword: key,
      status: statusFilter.value
      // courseId: course,
      // attendanceKey: attendance
    });
    // setPageIndex(0);
  };

  const enterKeyup = (e) => {
    if (e.key === 'Enter') {
      clickSearch({});
    }
  };

  return (
      <>
        {location?.state?.pollType === POLL_TYPE.ATTENDANCE &&
            <div className={styles.mainContainer}>
              <div className={styles.attendanceContainer}>
                <div className={styles.titleBox}>
                  <div className={styles.titleBoxIcon}/>
                  <div tabIndex={0} className={styles.titleBoxTitle}>{ATTENDANCE} {MENU_LIST.GRADEBOOK.name}</div>
                </div>
                <div className={styles.attendanceBody}>
                  <div className={styles.filterRowWrapper}>
                    <div className={styles.filterRow}>
                      <div className={styles.filterContainer}>
                        <div
                            tabIndex={0}
                            role={TEXTBOX}
                            style={
                              isNarrow ? {
                                marginRight: 0,
                                width: 85,
                                textAlign: "right"
                              } : {marginRight: 10}
                            }
                        >{COURSE_FILTER_LABEL}</div>
                        <div className={styles.attendanceSelectWrapper}>
                          <Select
                              menuPlacement="auto"
                              menuPosition="fixed"
                              className={styles.attendanceSelect}
                              isLoading={loadingCourses}
                              options={courseOptions}
                              onChange={option => chooseCourse(option)}
                              value={courseFilter}
                              placeholder={COURSE_PLACEHOLDER}
                              id="gradebookSelectCourse"
                              aria-label={courseFilter + ALL_COURSE_LABEL}
                          />
                        </div>
                      </div>

                      <div className={styles.filterContainer}>
                        <div
                            tabIndex={0}
                            role={TEXTBOX}
                            style={
                              isNarrow ? {
                                marginRight: 0,
                                width: 85,
                                textAlign: "right"
                              } : {marginRight: 10}
                            }
                        >{ATTENDANCE_FILTER_LABEL}</div>

                        <div className={styles.attendanceSelectWrapper}>
                          <Select
                              menuPlacement="auto"
                              menuPosition="fixed"
                              className={styles.attendanceSelect}
                              isLoading={pollsLoading}
                              options={options}
                              onChange={option => chooseAttendance(option)}
                              value={attendanceFilter}
                              placeholder={ATTENDANCE_PLACEHOLDER}
                              id="gradebookSelectPoll"
                              aria-label={ATTENDANCE_GRADEBOOK_DROPDOWN}
                          />
                        </div>
                      </div>
                      <YuJaButton
                          type="primary"
                          onClick={getAttendanceResult}
                          style={{fontSize: 14, fontWeight: 400, marginLeft: 15, minWidth: 90}}
                          disabled={JSON.stringify(courseFilter) === "{}" && JSON.stringify(attendanceFilter) === "{}"}
                      >
                        {GET_RESULT_TEXT}
                      </YuJaButton>
                    </div>
                  </div>

                  <div style={{width: "100%", height: 1, background: "#D4D4DE"}}/>

                  {showTable ?
                      <>
                        <div className={styles.searchBoxAttendance}>
                          <Form className={styles.searchBoxForm} onSubmit={e => e.preventDefault()}>
                            <YuJaTextBox
                                label={SEARCH_GRADEBOOK}
                                containerStyle={{fontSize: 15, fontWeight: 400}}
                                placeholder={attendanceFilter ? GRADEBOOK_SEARCH_BOX_PLACEHOLDER_W_ATTENDANCE_FILTER : GRADEBOOK_SEARCH_BOX_PLACEHOLDER}
                                onChange={e => setKeyword(e.target.value)}
                                onKeyUp={enterKeyup}
                                value={keyword}
                                before={<SearchIcon style={{marginRight: 5, width: 16}}/>}
                            />

                            <YuJaDropdown
                                ariaLabel={statusFilter?.value + ALL_STATUS_LABEL}
                                data={[ALL_STATUS_OPTION, {value: PRESENT_STATUS, label: PRESENT_TEXT}, {
                                  value: ABSENT_STATUS,
                                  label: ABSENT_TEXT
                                }]}
                                onChange={option => setStatusFilter(option)}
                                value={statusFilter?.value}
                                containerStyle={{minWidth: 110}}
                                style={{padding: "8px 15px"}}
                                placeholder={STATUS_LABEL}
                            />

                            <YuJaButton
                                style={{
                                  fontSize: "14px",
                                  display: "flex",
                                  justifyContent: "center",
                                  borderRadius: "4.21px"
                                }}
                                onClick={clickSearch}>{SEARCH_TEXT}</YuJaButton>

                            {!dataLoading && syncGradebookBtn && updatedResponses.length > 0 &&
                                <YuJaButton style={{
                                  minWidth: "130px",
                                  fontSize: "13px",
                                  display: "flex",
                                  justifyContent: "center",
                                  borderRadius: "4.21px"
                                }} onClick={() => setSyncMode(1)}>
                                  {SYNC_GRADEBOOK_TEXT}
                                </YuJaButton>
                            }
                          </Form>
                        </div>

                        <AttendanceGradeBookTabs
                            responses={updatedResponses}
                            setResponses={setUpdatedResponses}
                            // questions={gradeBookData.questions}
                            // pollKey={pk.current}
                            pollCode={pc.current}
                            // pollTitle={pollTitle.current}
                            pollType={POLL_TYPE.ATTENDANCE}
                            // lmsAttempt={lmsAttempt.current}
                            syncLmsEnable={!!attendanceFilter?.syncLms}
                            syncMode={syncMode}
                            setSyncMode={setSyncMode}
                            showCheckbox={syncGradebookBtn}
                            dataLoading={dataLoading}
                            // setDataLoading={setDataLoading}
                            autoSyncData={autoSyncData}
                            setAutoSyncData={setAutoSyncData}
                            globalFilterObj={filter}
                            // pageIdx={pageIndex}
                            // geofence={geofence}
                            attendanceFilter={attendanceFilter}
                        />
                      </>
                      :
                      <div className={styles.mainAttendanceContainer}>
                        <EmptyAttendance style={{marginBottom: 20}}/>
                        <div className={styles.emptyTitle}>{ATTENDANCE_GRADEBOOK_DEFAULT_MESSAGE}</div>
                      </div>
                  }
                </div>

              </div>
            </div>

        }

        {location?.state?.pollType !== POLL_TYPE.ATTENDANCE &&
            <div className={styles.mainContainer}>
              <div className={styles.body}>
                <div className={styles.titleBox}>
                  <div className={styles.titleBoxIcon}/>
                  <div tabIndex={0} className={styles.titleBoxTitle}>{MENU_LIST.GRADEBOOK.name}</div>
                </div>
                <div className={styles.mainDiv}>
                  <div className={styles.searchBox}
                      // style={showExportOptions  ?  {paddingBottom: "150px"} : showSyncOptions ? {paddingBottom: "110px"} :  {}}
                  >
                    <div tabIndex={0} role={TEXTBOX} style={{marginRight: "10px"}}>{GRADEBOOK_FILTER_LABEL}</div>
                    <Select
                        className={styles.select}
                        isLoading={pollsLoading}
                        options={options}
                        onChange={getResult}
                        value={selectedOption}
                        id="gradebookSelectPoll"
                        aria-label={GRADEBOOK_DROPDOWN}
                    />

                    {!!selectedOption && gradeBookData.responses.length > 0 &&
                        <Select
                            isLoading={exportOptionsLoading}
                            options={exportOptions}
                            onChange={(option) => exportExcel(option.value)}
                            isSearchable={false}
                            value={{value: EXPORT_GRADEBOOK_TEXT, label: EXPORT_GRADEBOOK_TEXT}}
                            id="gradebookSelectExport"
                            aria-label={EXPORT_GRADEBOOK_DROPDOWN}
                            className={styles.exportDropdown}
                        />
                    }

                    {syncGradebookBtn && gradeBookData.responses.length > 0 &&
                        <div className={styles.syncGradebookContainer} style={{margin: "0 10px", fontSize: 13}}>
                          <YuJaButton onClick={() => setSyncMode(1)}>
                            {SYNC_GRADEBOOK_TEXT}
                          </YuJaButton>
                        </div>

                    }
                  </div>

                  {!showTable &&
                      <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                      }}>
                        <EmptyGradeBook/>
                        <div className={styles.emptyTitle}>{GRADEBOOK_DEFAULT_MESSAGE}</div>
                      </div>}

                  {/* <Tabs defaultActiveKey="gradeBook" className="mb-3"> */}
                  {/* <Tab eventKey="gradeBook" title="Grade Book"> */}
                  {showTable &&
                      <>
                        <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
                          <div tabIndex={0} role={TEXTBOX}
                               className={styles.pollTitleTitle}>{pollType.current === POLL_TYPE.SURVEY ? SURVEY : POLL_TEXT} Title:
                          </div>
                          <div tabIndex={0} role={TEXTBOX} className={styles.pollTitleText}>{pollTitle.current}</div>
                        </div>
                        <GradeBookTabs
                            responses={updatedResponses}
                            setResponses={setUpdatedResponses}
                            questions={gradeBookData.questions}
                            pollKey={pk.current}
                            pollCode={pc.current}
                            pollTitle={pollTitle.current}
                            pollType={pollType.current}
                            lmsAttempt={lmsAttempt.current}
                            syncMode={syncMode}
                            pollShareMode={pollShareMode.current}
                            setSyncMode={setSyncMode}
                            showCheckbox={syncGradebookBtn}
                            dataLoading={dataLoading}
                            setDataLoading={setDataLoading}
                            autoSyncData={autoSyncData}
                            setAutoSyncData={setAutoSyncData}
                        />
                      </>
                  }
                </div>
                <GradebookWarningModal
                    show={warningModalShow}
                    setModalShow={setWarningModalShow}
                    handleExportFromModal={handleExportFromModal}
                />
              </div>
            </div>
        }
      </>
  );
}