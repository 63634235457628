import styles from "./YuJaToggle.module.css";
import {useEffect, useRef, useState} from "react";


export const YuJaToggle = ({toggled=false, ariaLabel, onClick, label=true, disabled=false, ...props}) => {
    const [inputVal, setInputVal] = useState(toggled);
    const actualInputBoxRef = useRef(null);
    const onClickEvent = (e) => {
        if (disabled) {
            return
        }

        const box = actualInputBoxRef.current;
        if (box) {
            box.click();
        }
    }

    const onChangeFunc = (e) => {
        // setInputVal(e.target.checked);
        if (onClick) {
            onClick(e);
        }
    }

    useEffect(() => {
        setInputVal( toggled);
    }, [toggled]);

    return (
        <div className={styles.container} >
            {!!label &&
                <span className={styles.label}>{!!inputVal ? "Enabled" : "Disabled"}</span>
            }
            <div tabIndex={0} className={styles.toggleContainer} onClick={onClickEvent}>
                <input ref={actualInputBoxRef} name={props.name} className={styles.actualCheckBox} type={"checkbox"} aria-label={ariaLabel}
                       checked={inputVal} onChange={onChangeFunc} disabled={disabled}/>
                <span className={styles.backgroundWrapper}/>
            </div>
        </div>

    )
}