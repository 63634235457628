import {useParams} from "react-router-dom";
import styles from "./SAML.module.css";
import {ReactComponent as YuJaLogo} from "../../images/YuJa_Logo_Login.svg";
import {ReactComponent as Success} from "./images/success.svg";
import React from "react";
import { BsXCircle } from "react-icons/bs";
import { SSO_LOGIN_POWERPOINT_FAILED, SSO_LOGIN_POWERPOINT_SUCCESS, SSO_LOGIN_POWERPOINT_TEXT } from "../../utils/constants";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function MobileSSOLoginPage() {
    const { result, endType } = useParams();
    const window = useWindowDimensions();

    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                {window.width < 1000 ?
                    <YuJaLogo style={{marginRight: "5px", width: 60, height: 60, alignSelf: 'center'}}/>
                    : <YuJaLogo style={{marginRight: "5px", width: 95, height: 95, alignSelf: 'center'}}/>
                }
                <div className={styles.title} style={{fontSize: window.width < 1000 ? 32 : 40}}>YuJa Engage</div>
            </div>
            {result === 'success' &&
                <div className={styles.resultBox} style={{width: window.width < 650 ? '80%' : 600}}>
                    {window.width < 1000 ?
                        <Success style={{width: 60, height: 60}}/>
                        : <Success />
                    }

                    <div className={styles.resultTitle} style={{fontSize: window.width < 1000 ? 23 : 32}}>LOGIN SUCCESSFUL</div>
                    <div className={styles.description} style={{fontSize: window.width < 1000 ? 16 : 24}}>
                        {SSO_LOGIN_POWERPOINT_SUCCESS} <br/>
                        {endType === 'mobile' &&
                            'Please wait while redirecting...'
                        }
                        {endType === 'ppt' &&
                            SSO_LOGIN_POWERPOINT_TEXT
                        }
                    </div>
                </div>

            }

            {result !== 'success' &&
                <div className={styles.resultBox} style={{width: window.width < 650 ? '80%' : 600}}>
                    {window.width < 1000 ?
                        <BsXCircle style={{width: 60, height: 60}}/>
                        : <BsXCircle style={{width: 90, height: 90}}/>
                    }

                    <div className={styles.resultTitle} style={{fontSize: window.width < 1000 ? 23 : 32}}>LOGIN UNSUCCESSFUL</div>
                    <div className={styles.description} style={{fontSize: window.width < 1000 ? 16 : 24}}>
                        {SSO_LOGIN_POWERPOINT_FAILED} <br/>
                        {endType === 'ppt' &&
                            SSO_LOGIN_POWERPOINT_TEXT
                        }
                    </div>
                </div>

            }
        </div>
    );
}