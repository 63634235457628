import React, {useState} from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import YuJaButton from "../../components/standardization/YuJaButton";
import { ReactComponent as PlusIcon } from "../../images/plus.svg";
import {CORRECT_ANY_DESCRIPTION, MCSS_ADD_OPTION_TEXT, QUES_BANK_CHECKBOX} from "../../utils/constants";
import {intToChar, notifyInfo, reorderKeysByDragDrop} from "../../utils/helpers";
import { addOptionToQuestion } from "../../utils/questionUtils";
import { ADD_OPTIONS_INFO } from "../../utils/toast-message-constants";
import "../styles.css";
import MCQuestionOption from "./MCQuestionOption";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {useCheckMainContainerNarrow} from "../../hooks/useCheckMainContainerNarrow";
import {YuJaCheckbox} from "../../components/standardization/YuJaCheckbox";

export default function MCQuestion({
  setQuestions,
  questions,
  selectedQuestion,
  pollKey,
  changePollKey,
  pollType,
  questionBankId,
  dataloading=false
}) {
  const [warningShowed, setWarningShowed] = useState(false);
  const { optionsMap, serialNo} = selectedQuestion;
  const {isNarrow} = useCheckMainContainerNarrow();

  const [showButton, setShowButton] = useState(true);

  const handleDragStart = () => {
    setShowButton(false);
  };

  const handleDragEnd = () => {
    setShowButton(true);
  };


  const handleAnswerOrderChange = (result) => {
    if (!result.destination || result.source.index === result.destination.index)  {
      return;
    }

    let newQuestions = [];
    questions.forEach((q) => {
      if (q.serialNo !== serialNo) {
        newQuestions.push(q);
      } else {
        const questionCopy = JSON.parse(JSON.stringify(q));
        let reorderKeys = reorderKeysByDragDrop(result.source.index, result.destination.index, Object.keys(questionCopy.optionsMap));

        let newOptionsMap = {};
        let newCorrectAnswers = [];
        let newOptionsImageMap = {};
        for (let i = 0; i < reorderKeys.length; i++) {
          const key = intToChar(i);
          newOptionsMap[key] = questionCopy.optionsMap[reorderKeys[i]];
          if (questionCopy.correctAnswers.includes(reorderKeys[i])) {
            newCorrectAnswers.push(key);
          }

          if (!!q.optionsImageMap && !!q.optionsImageMap.hasOwnProperty(reorderKeys[i])) {
            newOptionsImageMap[key] = q.optionsImageMap[reorderKeys[i]];
          }
        }

        questionCopy.optionsMap = newOptionsMap;
        questionCopy.correctAnswers = newCorrectAnswers.sort();
        questionCopy.optionsImageMap = newOptionsImageMap;
        newQuestions.push(questionCopy);
      }
    });
    setQuestions(newQuestions);
  };

  const handleCorrectAnyCheck = () => {
    let newQuestions = [];
    questions.forEach((q) => {
      if (q.serialNo !== serialNo) {
        newQuestions.push(q);
      } else {
        const questionCopy = JSON.parse(JSON.stringify(q));
        questionCopy.correctAnyEnable = !Boolean(questionCopy.correctAnyEnable);
        newQuestions.push(questionCopy);
      }
    });
    setQuestions(newQuestions);
  };




  return (
        <>
          {!questionBankId && !!selectedQuestion.correctAnswers && selectedQuestion.correctAnswers.length > 1 && !!selectedQuestion.weightage &&
                <YuJaCheckbox
                    size={20}
                    style={{ fontSize: isNarrow ? 14 : 16, marginLeft: isNarrow ? 53 : 66, fontWeight: "600"}}
                    checked={!!selectedQuestion.correctAnyEnable}
                    onClick={handleCorrectAnyCheck}
                    label={CORRECT_ANY_DESCRIPTION}
                />
          }

          <DragDropContext
              onDragStart={handleDragStart}
              onDragEnd={(result) => {
                handleDragEnd();
                handleAnswerOrderChange(result);
              }}
          >
            {!dataloading &&
                <Droppable droppableId="droppable-multiple-choice-question">
                  {(provided, snapshot) => (
                      <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          {...provided.dropHandleProps}
                          className="droppable-multiple-choice-question-options"
                          id="mcq-options-container"
                          style={{marginTop: "10px", display: "flex", flexDirection: "column",  gap: isNarrow ? 10 : 20}}
                      >
                        {!!optionsMap && typeof optionsMap === 'object' && Object.entries(optionsMap).map(([choice, optionValue], index) => {
                          return (
                              <MCQuestionOption
                                  pollKey={pollKey}
                                  changePollKey={changePollKey}
                                  optionData={{ choice: choice, optionValue: optionValue }}
                                  selectedQuestion={selectedQuestion}
                                  questions={questions}
                                  setQuestions={setQuestions}
                                  index={index}
                                  key={index}
                                  pollType={pollType}
                                  questionBankId={questionBankId}
                                  maxSize={Object.entries(optionsMap).length}
                              />
                          );
                        })}
                        {showButton ?
                            <div style={{width: "100%", display: "flex", justifyContent: "center", paddingBottom: 5}}>
                              <YuJaButton
                                  title="Add Option"
                                  onClick={() => {
                                    const newQuestions = addOptionToQuestion(
                                        selectedQuestion,
                                        questions
                                    );
                                    if (newQuestions) {
                                      setQuestions(newQuestions);
                                      setTimeout(() => {
                                        const mcqOptionsContainer = document.getElementById("mcq-options-container");
                                        mcqOptionsContainer?.scroll({ top: mcqOptionsContainer.scrollHeight, behavior: "smooth" });
                                      }, 10);
                                    } else if (!warningShowed) {
                                      setWarningShowed(true);
                                      notifyInfo(ADD_OPTIONS_INFO);
                                    }
                                  }}
                              >
                                <PlusIcon style={{width: 15, height: 15, marginRight: 2}}/>  {MCSS_ADD_OPTION_TEXT}
                              </YuJaButton>
                            </div>
                            : null}

                        {provided.placeholder}
                      </div>
                  )}
                </Droppable>
            }
            {
                dataloading &&
                <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                  <Skeleton height={52} borderRadius={10} style={{ position: "relative", marginTop:20 }} />
                  <Skeleton height={52} borderRadius={10} style={{ position: "relative" , marginTop:20}} />
                  <Skeleton height={52} borderRadius={10} style={{ position: "relative", marginTop:20 }} />
                  <Skeleton height={52} borderRadius={10} style={{ position: "relative" , marginTop:20}} />
                </SkeletonTheme>
            }
          </DragDropContext>
        </>
  );
}
