import React, { useEffect } from "react";
import useMobileAccess from "../../hooks/useMobileAccess";
import { CLOSED_GRADED_POLL, REATTEMPT_CLOSED_POLL, SUPPORT_PREFIX, TEXTBOX, YUJA_ENGAGE_SUPPORT } from "../../utils/constants";
import { newPageLog } from "../../utils/helpers";
import { ReactComponent as PollEndedIcon } from "./images/poll_ended_new.svg";
import styles from "./styles.module.css";
import PollTitleContainer from "./PollTitleContainer";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function EndedPollNew({pollTitle, websocketState}) {
    let {width, height} = useWindowDimensions();
    const isNarrow = useMobileAccess(1200); 

    useEffect(() => {
      newPageLog("EndedPollNew.js");
    }, []);

    return (
        <div className={styles.endedPollBackground} style={{justifyContent: "normal"}}>
            <PollTitleContainer pollTitle={pollTitle} websocketState={websocketState}/>
            <PollEndedIcon
                style={{
                    width: Math.min(Math.min(width * 0.4, height * 0.4), 300),
                    height: Math.min(Math.min(width * 0.4, height * 0.4), 300),
                    flexShrink: 0,
                }}
            />
            <div>
                <div
                    className={styles.endedPolllSubtitle}
                    style={{fontSize: isNarrow ? 16 : undefined, marginBottom: 10}}
                >{CLOSED_GRADED_POLL}</div>
                <div className={styles.endedPolllSubtext} style={{fontSize: isNarrow ? 14 : undefined}}>{REATTEMPT_CLOSED_POLL}</div>
            </div>
            <div style={{flex: 1}}/>
            <span className={styles.endedPollText} style={{fontSize: isNarrow ? 14 : undefined}}>
                {SUPPORT_PREFIX} <a href="https://support.yuja.com/hc/en-us" target="_blank">{YUJA_ENGAGE_SUPPORT}</a>
            </span>
        </div>
    );
}
