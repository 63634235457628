import NetworkStatus from "./NetworkStatus";
import React from "react";
import classes from "./styles.module.css";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function PollTitleContainer({pollTitle, websocketState, ...props}) {
    const {width} = useWindowDimensions();
    return (
        <div className={classes.pollTitleContainer} {...props}>
            <div/>
            <span
                className={classes.pollTitleContainerTitle}
                tabIndex={0}
                style={{
                    fontSize: width < 1200 ? 16 : undefined,
                    fontWeight: width < 1200 ? "900" : undefined,
                }}
            >{pollTitle ? pollTitle : <>&nbsp;</>}</span>
            <NetworkStatus websocketState={websocketState}/>
        </div>
    );
}